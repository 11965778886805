import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Event } from 'src/app/models/event.model';
import { EventService } from 'src/app/services/event.service';
import { PhotoService } from 'src/app/services/photo.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-card-event-view',
	templateUrl: './card-event-view.component.html',
	styleUrls: ['../cards.component.css']
})
export class CardEventViewComponent implements OnInit, OnDestroy {
	@Input() event: Event;

	@Output() emitEventAcceptInvite = new EventEmitter<Event>();
	@Output() emitEventDeclineInvite = new EventEmitter<Event>();
	@Output() emitEventDelete = new EventEmitter<Event>();
	@Output() emitEventLeave = new EventEmitter<Event>();

	@ViewChild('card') cardRef: ElementRef;
	@ViewChild('cancelJoinDialog', { static: true }) cancelJoinDialog: TemplateRef<any>;
	@ViewChild('eventDeleteDialog', { static: true }) eventDeleteDialog: TemplateRef<any>;
	@ViewChild('leaveEventDialog', { static: true }) leaveEventDialog: TemplateRef<any>;

	modalRef: BsModalRef;

	loadingEvent = true;
	loadingInvite = false;
	loadingBannerImageUpload = true;

	eventSignedDownloadToken: any;

	constructor(
		private router: Router,
		private photoService: PhotoService,
		public userService: UserService,
		private eventService: EventService,
		private toastr: ToastrService
	) { }

	ngOnInit() {
		//this.loadEventDetails();
	}

	ngOnDestroy(): void {
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	navigate(route: string) {
		this.router.navigate([route]);
	}

	flip() {
		if (this.cardRef.nativeElement.style.transform == "rotateY(180deg)") {
			this.cardRef.nativeElement.style.transform = "rotateY(0deg)";
		}
		else {
			this.cardRef.nativeElement.style.transform = "rotateY(180deg)";
		}
	}

	loadEventDetails() {
		if (this.event) {
			if (this.event.bannerImageExternalId) {
				//this.setBannerImageSafeUrl(this.event);
				this.retrieveEventSignedDownloadToken(this.event.id);
			} else {
				this.loadingBannerImageUpload = false;
			}

			this.loadingEvent = false;
		} else {
			this.eventService.getEvent(this.event.id).subscribe(
				response => {
					if (response.status === 200) {
						this.event = response.body;

						if (this.event.bannerImageExternalId) {
							//this.setBannerImageSafeUrl(this.event);
							this.retrieveEventSignedDownloadToken(this.event.id);
						} else {
							this.loadingBannerImageUpload = false;
						}

						this.loadingEvent = false;
					} else {
						this.loadingEvent = false;
					}
				},
				err => {
					// this.error = err.error.message;
					this.loadingEvent = false;
				}
			);
		}

	}

	retrieveEventSignedDownloadToken(eventId) {
		// TODO: Get this from the cache
		let token;
		for (let signedDownloadToken of this.photoService.signedDownloadTokens) {
			if (signedDownloadToken.eventId == eventId) {
				token = signedDownloadToken;
			}
		}
		if (token) {
			this.eventSignedDownloadToken = token;
			this.setBannerImageSafeUrl(this.event);
		} else {
			this.photoService.getSignedDownloadToken(eventId, 'event').subscribe(
				response => {
					let responseToken = response.body;
					responseToken.eventId = eventId;

					// Ensure the token doesn't exist before adding to cache
					let token2;
					for (let signedDownloadToken of this.photoService.signedDownloadTokens) {
						if (signedDownloadToken.eventId == eventId) {
							token2 = signedDownloadToken;
						}
					}

					if (!token2) {
						this.photoService.signedDownloadTokens.push(responseToken);
						this.eventSignedDownloadToken = responseToken;
					} else {
						this.eventSignedDownloadToken = token2;
					}

					this.setBannerImageSafeUrl(this.event);
				}
			);
		}
	}

	setBannerImageSafeUrl(event) {
		let url = 'https://f003.backblazeb2.com/file/photonomy-prod/' + event.bannerImageExternalUrl + "?Authorization=" + this.eventSignedDownloadToken.token;
		event.bannerImageSafeUrl = url;

		this.event = event;
		this.loadingBannerImageUpload = false;
	}

	/**
	 * PENDING MEMBER BUTTON FUNCTIONS
	 */
	acceptEventInvite() {
		this.loadingInvite = true;

		this.eventService.acceptEventInvite(this.event.id).subscribe(
			response => {
				if (response.status == 200) {
					this.event.status = 'active';

					this.emitEventAcceptInvite.emit(this.event);

					this.loadingInvite = false;
				} else {
					this.loadingInvite = false;
				}
			},
			err => {
				this.loadingInvite = false;
			}
		);
	}

	declineEventInvite() {
		this.loadingInvite = true;
		this.eventService.declineEventInvite(this.event.id).subscribe(
			response => {
				if (response.status == 200) {
					this.emitEventDeclineInvite.emit(this.event);

					this.loadingInvite = false;
				} else {
					this.loadingInvite = false;
				}
			},
			err => {
				this.loadingInvite = false;
			}
		);
	}

	/**
	 * OWNER / ACTIVE MEMBER BUTTON FUNCTIONS
	 */
	explorePhotos() {
		const navigationExtras: NavigationExtras = {
			queryParams: {
				eventId: this.event.id
			}
		};
		this.router.navigate(['/explore'], navigationExtras);
	}

	viewEvent() {
		// NOTE: There is a user issue unless I open this in a new tab.
		// this.router.navigate(['/event/' + this.event.shareCode1 + '/' + this.event.shareCode2]);

		let url = '/#/event/' + this.event.shareCode1 + '/' + this.event.shareCode2;
		window.open(url, '_blank');
	}

	copyShareLinkToClipboard() {
		let item = 'https://www.photonomy.com/#/event/' + this.event.shareCode1 + '/' + this.event.shareCode2;

		document.addEventListener('copy', (e: ClipboardEvent) => {
			e.clipboardData.setData('text/plain', (item));
			e.preventDefault();
			document.removeEventListener('copy', null);
		});
		document.execCommand('copy');
		this.toastr.success('Successfully copied to clipboard');
	}
}
