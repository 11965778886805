import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Folder } from '../models/folder.model';
import { UserService } from './user.service';

@Injectable()
export class FolderService {

    constructor(
        private http: HttpClient,
        private userService: UserService
    ) { }

    getFolders(parentFolderId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/folders/' + parentFolderId;

        return this.http.get<Array<Folder>>(requestUrl, { headers: headers, observe: 'response' });
    }

    getFoldersBasic(parentFolderId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/folders/' + parentFolderId + '/basic';

        return this.http.get<Array<Folder>>(requestUrl, { headers: headers, observe: 'response' });
    }

    getFolderPhotosCount(folderId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/folders/' + folderId + '/count';

        return this.http.get<number>(requestUrl, { headers: headers, observe: 'response' });
    }

    createFolder(folder: Folder) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        folder.userId = this.userService.getLocalUserId(0);

        const requestUrl = '/api/folder';

        return this.http.post<Folder>(requestUrl, folder, { headers: headers, observe: 'response' });
    }

    updateFolder(folder: Folder) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        folder.userId = this.userService.getLocalUserId(0);

        const requestUrl = '/api/folder';

        return this.http.put<Folder>(requestUrl, folder, { headers: headers, observe: 'response' });
    }

    moveFolderPhotos(folderId: number, newFolderId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/folder/' + folderId + '/photos-move/' + newFolderId;

        return this.http.put<boolean>(requestUrl, null, { headers: headers, observe: 'response' });
    }

    deleteFolder(folderId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/folder/' + folderId;

        return this.http.delete<boolean>(requestUrl, { headers: headers, observe: 'response' });
    }

    deleteFolderPhotos(folderId: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');

        const requestUrl = '/api/folder/' + folderId + '/photos';

        return this.http.delete<boolean>(requestUrl, { headers: headers, observe: 'response' });
    }

}