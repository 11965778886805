<div class="action-container">
	<div *ngIf="createFolderParentId !== 0" class="create-folder-container">
		<i class="fas fa-folder-plus"></i>
		<span class="folder-name"><a href="javascript:void(0)" (click)="showCreateRootFolder()">Create folder</a></span>
	</div>
</div>
<div class="outer-container">
	<!-- No folder -->
	<div class="folder-row">
		<div>
			<i class="fas fa-ban"></i>
			<span class="folder-name"><a href="javascript:void(0)" (click)="exploreFolderPhotos(0)">No folder</a></span>
		</div>
		<div class="right">
			<span *ngIf="!loadingNoFoldersCount">{{ noFoldersCount }} photos</span>
			<span *ngIf="loadingNoFoldersCount"><i class="fas fa-spinner fa-spin"></i></span>
		</div>
	</div>

	<!-- Create root folder -->
	<div *ngIf="createFolderParentId == 0" class="folder-row">
		<div>
			<i class="fas fa-folder create"></i>
			<input #newRootFolderInput (keyup)="setTempCreateRootFolderName($event)" placeholder="Enter folder name" />
			<span class="folder-action-icons">
				<i class="fas fa-times" (click)="cancelCreateRootFolder()"></i>
				<i class="fas fa-check" *ngIf="createRootFolderName.length > 0" (click)="createRootFolder()"></i>
			</span>
			<!-- <div class="icon-spacer" *ngIf="!formRoot.value.createRootFolderName || formRoot.value.createRootFolderName?.length == 0"></div> -->
		</div>
	</div>

	<!-- List folders -->
	<ng-container *ngFor="let folder of rootFolders">
		<!-- Root folder -->
		<div class="folder-row">
			<div>
				<!-- Name in non-edit mode -->
				<ng-container *ngIf="updateRootFolderId !== folder.id">
					<i class="fas fa-angle-right" *ngIf="expandedRootFolderId !== folder.id" [ngClass]="{'disabled': !folder.folders || folder.folders?.length == 0}" (click)="expandFolder(folder)"></i>
					<i class="fas fa-angle-down" *ngIf="expandedRootFolderId == folder.id" (click)="collapseFolder(folder.id)"></i>
					<i class="fas fa-folder"></i>
					<span class="folder-name"><a href="javascript:void(0)" (click)="exploreFolderPhotos(folder.id)">{{ folder.name }}</a></span>
				</ng-container>

				<!-- Name in edit mode -->
				<ng-container *ngIf="updateRootFolderId == folder.id">
					<i class="fas fa-folder create"></i>
					<input #editRootFolderInput [value]="updateRootFolderName" (keyup)="setTempUpdateRootFolderName($event)" />
					<span class="folder-action-icons">
						<i class="fas fa-times" (click)="cancelUpdateRootFolder()"></i>
						<i class="fas fa-check" *ngIf="updateRootFolderName.length > 0" (click)="updateRootFolder()"></i>
					</span>
				</ng-container>

				<!-- Action icons -->
				<ng-container *ngIf="updateRootFolderId !== folder.id && createSubFolderParentId == -1 && deleteFolderId == -1">
					<i *ngIf="showActionIconsFolderId == -1" class="fas fa-ellipsis-h" (click)="showActionIconsFolderId = folder.id"></i>
					<span *ngIf="showActionIconsFolderId == folder.id" class="folder-action-icons">
						<i class="fas fa-angle-double-left" (click)="showActionIconsFolderId = -1" (mouseover)="folderActionText = 'Close menu'" (mouseout)="folderActionText = ''"></i>
						<i class="fas fa-folder-plus" (click)="showCreateSubfolder(folder.id)" (mouseover)="folderActionText = 'Create subfolder'" (mouseout)="folderActionText = ''"></i>
						<i class="fas fa-pen" (click)="showUpdateRootFolder(folder)" (mouseover)="folderActionText = 'Edit folder name'" (mouseout)="folderActionText = ''"></i>
						<i *ngIf="folder.folders.length == 0" class="fas fa-arrows-alt" (click)="showMoveFolderModal(folder)" (mouseover)="folderActionText = 'Move folder'" (mouseout)="folderActionText = ''"></i>
						<i *ngIf="folder.folders.length > 0" class="fas fa-arrows-alt disabled" (mouseover)="folderActionText = 'Move folder'" (mouseout)="folderActionText = ''" [tooltip]="cannotMoveTemplate" placement="bottom"></i>
						<ng-template #cannotMoveTemplate>
							You cannot move a folder with subfolders
						</ng-template>
						<i *ngIf="folder.folders.length == 0 && folder.photosCount == 0" class="fas fa-trash" (click)="deleteFolder(folder)" (mouseover)="folderActionText = 'Delete folder'" (mouseout)="folderActionText = ''"></i>
						<i *ngIf="folder.folders.length > 0 && folder.photosCount == 0" class="fas fa-trash disabled" (mouseover)="folderActionText = 'Delete folder'" (mouseout)="folderActionText = ''" [tooltip]="cannotDeleteFolderTemplate" placement="bottom"></i>
						<ng-template #cannotDeleteFolderTemplate>
							You cannot delete a folder with subfolders
						</ng-template>
						<i *ngIf="folder.folders.length == 0 && folder.photosCount > 0" class="fas fa-trash disabled" (mouseover)="folderActionText = 'Delete folder'" (mouseout)="folderActionText = ''" [tooltip]="cannotDeletePhotoTemplate" placement="bottom"></i>
						<ng-template #cannotDeletePhotoTemplate>
							You cannot delete a folder that contains photos
						</ng-template>
						<i *ngIf="folder.folders.length > 0 && folder.photosCount > 0" class="fas fa-trash disabled" (mouseover)="folderActionText = 'Delete folder'" (mouseout)="folderActionText = ''" [tooltip]="cannotDeleteBothTemplate" placement="bottom"></i>
						<ng-template #cannotDeleteBothTemplate>
							You cannot delete a folder that contains folders or photos
						</ng-template>
						<i *ngIf="folder.photosCount > 0" class="fas fa-file-export" (click)="showRemovePhotosDialog(folder)" (mouseover)="folderActionText = 'Remove photos from folder'" (mouseout)="folderActionText = ''"></i>
					</span>
					<span *ngIf="showActionIconsFolderId == folder.id" class="folder-action-text">{{ folderActionText }}</span>
				</ng-container>
			</div>
			<div class="right">{{ folder.photosCount || 0 }} photos</div>
		</div>

		<!-- Create sub-folder -->
		<div *ngIf="createSubFolderParentId == folder.id" class="new-sub-folder">
			<i class="fas fa-folder"></i>
			<input formControlName="newSubFolderName" placeholder="Enter folder name" (keyup)="setTempCreateSubFolderName($event)" />
			<i class="fas fa-times" (click)="cancelShowCreateSubfolder(folder.id)"></i>
			<i class="fas fa-check" *ngIf="createSubFolderName.length > 0" (click)="createSubFolder(folder.id)"></i>
		</div>

		<ng-container *ngIf="expandedRootFolderId == folder.id">
			<ng-container *ngFor="let subfolder of folder.folders">
				<div class="folder-row subfolder">
					<div>
						<!-- Name in non-edit mode -->
						<ng-container *ngIf="updateSubFolderId !== subfolder.id">
							<i class="fas fa-folder"></i>
							<span class="folder-name"><a href="javascript:void(0)" (click)="exploreFolderPhotos(subfolder.id)">{{ subfolder.name }}</a></span>
						</ng-container>

						<!-- Name in edit mode -->
						<ng-container *ngIf="updateSubFolderId == subfolder.id">
							<i class="fas fa-folder"></i>
							<input #editSubFolderInput [value]="updateSubFolderName" (keyup)="setTempUpdateSubFolderName($event)" />
							<span class="folder-action-icons">
								<i class="fas fa-times" (click)="cancelUpdateSubFolder()"></i>
								<i class="fas fa-check" *ngIf="updateSubFolderName.length > 0" (click)="updateSubFolder(folder.id)"></i>
							</span>
						</ng-container>

						<!-- Action icons -->
						<ng-container *ngIf="updateSubFolderId !== subfolder.id">
							<i *ngIf="showActionIconsFolderId == -1" class="fas fa-ellipsis-h" (click)="showActionIconsFolderId = subfolder.id"></i>
							<span *ngIf="showActionIconsFolderId == subfolder.id" class="folder-action-icons">
								<i class="fas fa-angle-double-left" (click)="showActionIconsFolderId = -1" (mouseover)="folderActionText = 'Close menu'" (mouseout)="folderActionText = ''"></i>
								<i class="fas fa-pen" (click)="showUpdateSubFolder(subfolder)" (mouseover)="folderActionText = 'Edit folder name'" (mouseout)="folderActionText = ''"></i>
								<i class="fas fa-arrows-alt" (click)="showMoveFolderModal(subfolder)" (mouseover)="folderActionText = 'Move folder'" (mouseout)="folderActionText = ''"></i>
								<i *ngIf="subfolder.photosCount == 0" class="fas fa-trash" (click)="deleteFolder(subfolder)" (mouseover)="folderActionText = 'Delete folder'" (mouseout)="folderActionText = ''"></i>
								<i *ngIf="subfolder.photosCount > 0" class="fas fa-trash disabled" (mouseover)="folderActionText = 'Delete folder'" (mouseout)="folderActionText = ''" [tooltip]="cannotDeleteSubPhotoTemplate" placement="bottom"></i>
								<ng-template #cannotDeleteSubPhotoTemplate>
									You cannot delete a folder that contains photos
								</ng-template>
								<i *ngIf="subfolder.photosCount > 0" class="fas fa-file-export" (click)="showRemovePhotosDialog(subfolder)" (mouseover)="folderActionText = 'Remove photos from folder'" (mouseout)="folderActionText = ''"></i>
							</span>
							<span *ngIf="showActionIconsFolderId == subfolder.id" class="folder-action-text">{{ folderActionText }}</span>
						</ng-container>
					</div>
					<div class="right">{{ subfolder.photosCount || 0 }} photos</div>
				</div>
			</ng-container>
		</ng-container>
	</ng-container>
</div>


<ng-template #moveFolderDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-folder"></em> Move {{ moveFolderName }}</h5>
	</div>
	<div class="modal-body" [ngClass]="{'loading in': loading }">
		<div class="move-message">Select a new parent folder...</div>
		<div class="modal-folder-container" (click)="moveFolder(0)"><i class="fas fa-long-arrow-alt-up"></i>Move to top level</div>
		<ng-container *ngFor="let folder of rootFolders">
			<div *ngIf="folder.id !== moveFolderId" class="modal-folder-container" (click)="moveFolder(folder.id)"><i class="fas fa-folder"></i>{{ folder.name }}</div>
			<!-- 
			<ng-container *ngIf="expandedRootFolderId == folder.id">
				<ng-container *ngFor="let subfolder of folder.folders">
					<div class="modal-folder-container sub">{{ subfolder.name }}</div>
				</ng-container>
			</ng-container>
			-->
		</ng-container>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="cancelFolderMove()">Cancel</button>
	</div>
</ng-template>


<ng-template #removePhotosDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-file-export"></em> Remove photos from {{ removePhotosFolderName }}</h5>
	</div>
	<div class="modal-body" [ngClass]="{'loading in': loadingRemovePhotos }">
		<ng-container *ngIf="!showRemovePhotosFolderSelector && !showRemovePhotosDeleteConfirm">
			<div class="remove-photos-message">What would you like to do with the photos?</div>
			<div class="remove-photos-buttons"><button class="btn btn-primary btn-md" (click)="moveFolderPhotos()">Move photos</button> <button class="btn btn-primary btn-md" (click)="deleteFolderPhotos()">Delete photos</button></div>
		</ng-container>
		<ng-container *ngIf="showRemovePhotosFolderSelector">
			<div class="move-message">Select a new location...</div>
			<div class="modal-folder-container" (click)="moveFolderPhotosConfirm(0)"><i class="fas fa-ban"></i>No folder</div>
			<ng-container *ngFor="let folder of rootFolders">
				<div class="modal-folder-container" (click)="moveFolderPhotosConfirm(folder.id)">
					<i class="fas fa-angle-right" *ngIf="removePhotosFolderSelectorExpandedFolderId !== folder.id && folder.folders?.length > 0" (click)="expandRemovePhotosFolder($event, folder)"></i>
					<i class="fas fa-angle-right disabled" *ngIf="removePhotosFolderSelectorExpandedFolderId !== folder.id && folder.folders?.length == 0"></i>
					<i class="fas fa-angle-down" *ngIf="removePhotosFolderSelectorExpandedFolderId == folder.id" (click)="collapseRemovePhotosFolder($event, folder.id)"></i>
					<i class="fas fa-folder"></i>{{ folder.name }}
				</div>
				<ng-container *ngIf="removePhotosFolderSelectorExpandedFolderId == folder.id">
					<ng-container *ngFor="let subfolder of folder.folders">
						<div class="modal-folder-container sub" (click)="moveFolderPhotosConfirm(subfolder.id)"><i class="fas fa-folder"></i>{{ subfolder.name }}</div>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="showRemovePhotosDeleteConfirm">
			<div class="remove-photos-message">Confirm deleting all photos from the folder...</div>
		</ng-container>
	</div>
	<div class="modal-footer no-bg">
		<button *ngIf="!showRemovePhotosFolderSelector && !showRemovePhotosDeleteConfirm" type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="cancelRemovePhotos()" [disabled]="loadingRemovePhotos">Cancel</button>
		<button *ngIf="showRemovePhotosFolderSelector" type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="moveFolderPhotosCancel()" [disabled]="loadingRemovePhotos">Cancel</button>
		<button *ngIf="showRemovePhotosFolderSelector" type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="moveFolderPhotosConfirm()" [disabled]="loadingRemovePhotos">Confirm</button>
		<button *ngIf="showRemovePhotosDeleteConfirm" type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="deleteFolderPhotosCancel()" [disabled]="loadingRemovePhotos">Cancel</button>
		<button *ngIf="showRemovePhotosDeleteConfirm" type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="deleteFolderPhotosConfirm()" [disabled]="loadingRemovePhotos">Confirm</button>
	</div>
</ng-template>