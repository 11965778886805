import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { EventService } from 'src/app/services/event.service';
import { PaymentService } from 'src/app/services/payment.service';
import { Event } from '../../../models/event.model';
import { AuthService } from '../../../services/auth.service';
import { UserConnectionService } from '../../../services/user-connection.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-events',
	templateUrl: './events.component.html',
	styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
	loadedEvents = false; // Used to ensure the events are only loaded once.

	loadingOwnerEvents = true;
	loadingMemberEvents = true;
	loadingEvents = true;
	loadingUserPaymentMethods = true;

	error = false;

	paymentMethodsExist = false;
	paymentMethods: any[] = [];
	user: User;
	userChangedSubscription: Subscription;

	eventsAll: Event[] = [];
	eventsPast: Event[] = [];
	eventsFuture: Event[] = [];
	eventsOwner: Event[] = [];
	eventsMember: Event[] = [];

	availableFilters = [];
	selectedFilter = '';

	constructor(
		public authService: AuthService,
		public userService: UserService,
		public connectionService: UserConnectionService,
		public router: Router,
		private paymentService: PaymentService,
		private eventService: EventService
	) { }

	ngOnInit() {
		this.subscribeToUserChanged();

		if (this.userService.users[0]) {
			this.user = this.userService.users[0];

			this.checkUserPaymentMethod();

			this.retrieveEvents();
		}
	}

	ngOnDestroy() { }

	subscribeToUserChanged() {
		this.userChangedSubscription = this.userService.userChanged.subscribe(
			user => {
				if (user.id == this.userService.users[0].id && !this.loadedEvents) {
					this.user = user;

					this.checkUserPaymentMethod();
					this.retrieveEvents();

				}
			}
		);
	}

	retrieveEvents() {
		this.loadedEvents = true;

		this.loadingMemberEvents = true;
		this.loadingOwnerEvents = true;

		this.eventsAll = [];
		this.eventsPast = [];
		this.eventsFuture = [];
		this.eventsOwner = [];
		this.eventsMember = [];

		this.availableFilters = [];
		this.selectedFilter = '';

		if (this.eventService.eventsAsOwner.length > 0) {
			this.setEventArrays(this.eventService.eventsAsOwner);
		} else {
			this.eventService.getEventsAsOwner().subscribe(
				response => {
					if (response.status === 200) {
						this.eventService.eventsAsOwner = response.body;
						this.setEventArrays(response.body);
					} else {
						this.error = true;
						this.loadingOwnerEvents = false;
						if (this.loadingOwnerEvents && this.loadingMemberEvents) {
							this.loadingEvents = false;
						}
					}
				},
				err => {
					this.error = true;
					this.loadingOwnerEvents = false;
					if (this.loadingOwnerEvents && this.loadingMemberEvents) {
						this.loadingEvents = false;
					}
				}
			);
		}

		if (this.eventService.eventsAsMember.length > 0) {
			this.setEventMemberArrays(this.eventService.eventsAsMember);
		} else {
			this.eventService.getEventsAsMember().subscribe(
				response => {
					if (response.status === 200) {
						this.eventService.eventsAsMember = response.body;
						this.setEventMemberArrays(response.body);
					} else {
						this.error = true;
						this.loadingMemberEvents = false;
						if (this.loadingOwnerEvents && this.loadingMemberEvents) {
							this.loadingEvents = false;
						}
					}
				},
				err => {
					this.error = true;
					this.loadingMemberEvents = false;
					if (this.loadingOwnerEvents && this.loadingMemberEvents) {
						this.loadingEvents = false;
					}
				}
			);
		}
	}

	checkUserPaymentMethod() {
		if (this.user.paymentMethodId) {
			let now = new Date(); // 5/13/22
			let expireDate = new Date(this.user.paymentMethodCcExpirationYear, this.user.paymentMethodCcExpirationMonth, 0);

			if (expireDate > now) {
				this.paymentMethodsExist = true;
			}

			this.loadingUserPaymentMethods = false;
		} else {
			this.loadingUserPaymentMethods = false;
		}
	}

	setEventArrays(events) {
		let currentDate = new Date();
		for (let event of events) {
			this.eventsAll.push(event);
			let eventDate = new Date(event.startDateTime);

			if (eventDate > currentDate) {
				this.eventsFuture.push(event);
			} else {
				this.eventsPast.push(event);
			}
		}

		this.loadingOwnerEvents = false;

		if (!this.loadingOwnerEvents && !this.loadingMemberEvents) {
			this.setAvailableFilters();
		}

		this.eventsAll.sort((a, b) => -a.startDateTime.toString().localeCompare(b.startDateTime.toString()))
	}

	setEventMemberArrays(eventUsers) {
		let currentDate = new Date();
		for (let eventUser of eventUsers) {
			eventUser.event.status = eventUser.status;

			this.eventsAll.push(eventUser.event);
			let eventDate = new Date(eventUser.event.startDateTime);

			if (eventDate > currentDate) {
				this.eventsFuture.push(eventUser.event);
			} else {
				this.eventsPast.push(eventUser.event);
			}

			this.eventsMember.push(eventUser.event);
		}

		this.loadingMemberEvents = false;

		if (!this.loadingOwnerEvents && !this.loadingMemberEvents) {
			this.setAvailableFilters();
		}

		this.eventsAll.sort((a, b) => -a.startDateTime.toString().localeCompare(b.startDateTime.toString()))
	}

	setAvailableFilters() {
		this.availableFilters.push({ id: 'add', name: 'Add an Event' });
		if (this.eventsAll.length > 0) {
			this.availableFilters.push({ id: 'all', name: 'All' });
		}
		if (this.eventsFuture.length > 0) {
			this.availableFilters.push({ id: 'future', name: 'Future' });
		}
		if (this.eventsPast.length > 0) {
			this.availableFilters.push({ id: 'past', name: 'Past' });
		}
		if (this.eventsOwner.length > 0) {
			this.availableFilters.push({ id: 'owner', name: 'Owner' });
		}
		if (this.eventsMember.length > 0) {
			this.availableFilters.push({ id: 'member', name: 'Member' });
		}

		this.setSelectedFilter();
	}

	setSelectedFilter() {
		if (this.eventsAll.length > 0) {
			this.selectedFilter = 'all';
		} else {
			this.selectedFilter = 'add'
		}

		this.loadingEvents = false;
	}

	/**
	 * Emit Handlers
	 */
	handleFilterChanged(filter) {
		this.selectedFilter = filter;
	}

	// Occurs after the user presses the accept button.
	acceptEventInvite(event) {
		// All
		const itemAll = this.eventsAll.find(d => d.id === event.id);
		itemAll.status = 'active';

		// Past
		const itemPast = this.eventsPast.find(d => d.id === event.id);
		if (itemPast) {
			itemPast.status = 'active';
		}

		// Future
		const itemFuture = this.eventsFuture.find(d => d.id === event.id);
		if (itemFuture) {
			itemFuture.status = 'active';
		}

		// Member
		const itemMember = this.eventsMember.find(d => d.id === event.id);
		if (itemMember) {
			itemMember.status = 'active';
		}

		// Pending
		// const itemPending = this.eventsPending.find(d => d.id === event.id);
		// if (itemPending) {
		//	 itemPending.status = 'active';
		// }
	}

	// Occurs after the user presses the decline button.
	declineEventInvite(event) {
		// All -1
		const itemAll = this.eventsAll.find(d => d.id === event.id);
		this.eventsAll.splice(this.eventsAll.indexOf(itemAll), 1);

		// Past -1
		const itemPast = this.eventsPast.find(d => d.id === event.id);
		if (itemPast) {
			this.eventsPast.splice(this.eventsPast.indexOf(itemPast), 1);
		}

		// Future -1
		const itemFuture = this.eventsFuture.find(d => d.id === event.id);
		if (itemFuture) {
			this.eventsFuture.splice(this.eventsFuture.indexOf(itemFuture), 1);
		}

		// Owner -1
		const itemOwner = this.eventsOwner.find(d => d.id === event.id);
		if (itemOwner) {
			this.eventsOwner.splice(this.eventsOwner.indexOf(itemOwner), 1);
		}

		// Member -1
		const itemMember = this.eventsMember.find(d => d.id === event.id);
		if (itemMember) {
			this.eventsMember.splice(this.eventsMember.indexOf(itemMember), 1);
		}

		// Pending -1
		// const itemPending = this.eventsPending.find(d => d.id === event.id);
		// if (itemPending) {
		//	 this.eventsPending.splice(this.eventsPending.indexOf(itemPending), 1);
		// }
	}

	deleteEvent(event) {
		// All -1
		const itemAll = this.eventsAll.find(d => d.id === event.id);
		this.eventsAll.splice(this.eventsAll.indexOf(itemAll), 1);

		let currentDate = new Date();
		if (event.startDateTime > currentDate) {
			// Future -1
			const itemFuture = this.eventsFuture.find(d => d.id === event.id);
			this.eventsFuture.splice(this.eventsFuture.indexOf(itemFuture), 1);
		} else {
			// Past -1
			const itemPast = this.eventsPast.find(d => d.id === event.id);
			this.eventsPast.splice(this.eventsPast.indexOf(itemPast), 1);
		}

		// If this is the last event, display the Add Events panel again
		if (this.eventsAll.length == 0) {
			this.selectedFilter = 'add'
		}
	}

	// leaveEvent(event) {

	// }

	navigateToPaymentMethods() {
		this.router.navigate(['profile/'], { queryParams: { tab: 'payment' } });
	}


}
