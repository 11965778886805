<div class="header" id="top">
	<div class="cell-logo"><img src="assets/images/logo-white.png" class="logo" (click)="navigate('')" /></div>
	<!--<div class="cell-1">Privacy Policy</div>-->
	<!--<div class="cell-2">Terms of Service</div>-->
	<!--<div class="cell-3">Style Guide</div>-->
</div>

<div class="content">
	<div class="toc">
		<div class="last-updated">Last updated<br />July 23, 2024</div>
		<div class="red"><a href="#top" (click)="navigate('blog/01')">1. Introductions</a></div>
		<div class="orange"><a href="#top" (click)="navigate('blog/02')">2. From the beginning</a></div>
		<div class="yellow"><a href="#top" (click)="navigate('blog/03')">3. The first year</a></div>
		<div class="lightgreen"><a href="#top" (click)="navigate('blog/04')">4. Initial cloud hosting</a></div>
		<div class="green"><a href="#top" (click)="navigate('blog/05')">5. UI/UX</a></div>
		<div class="cyan"><a href="#top" (click)="navigate('blog/06')">6. Performance tuning</a></div>
		<!-- <div class="blue"><a href="#top" (click)="navigate('blog/07')">7. Mental health</a></div>
		<div class="darkblue active"><a href="#top" (click)="navigate('blog/08')">8. Funding vs bootstrapping</a></div>
		<div class="violet"><a href="#top" (click)="navigate('blog/09')">9. What's next</a></div> -->
	</div>
	<div>
		<div class="banner darkblue">
			<div class="title">Photonomy thoughts and insights:<br /><span>Funding vs bootstrapping</span></div>
		</div>
		<div class="text-container">
			<p>...</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
		</div>
	</div>
</div>
<app-footer></app-footer>