import { SafeUrl } from "@angular/platform-browser";
import { EventUser } from "./event-user.model";

export class Event {
	public id: number;
	public owner: number;
	public name: string;
	public details: string;
	public startDateTime: Date;
	public startDateIncludeTime: boolean;
	public endDateTime: Date;
	public endDateIncludeTime: boolean;
	public canMembersContribute: boolean;
	public canMembersDiscuss: boolean;
	public canMembersShare: boolean;
	public canConnectionsView: boolean;
	public canConnectionsContribute: boolean;
	public canConnectionsDiscuss: boolean;
	public canConnectionsShare: boolean;
	public canConnectionsJoin: boolean;
	public canPublicView: boolean;
	public canPublicContribute: boolean;
	public canPublicShare: boolean;
	public addToOwnerTimeline: boolean;
	public shareCode1: string;
	public shareCode2: string;
	public bannerImageExternalId: string;
	public bannerImageExternalUrl: string;
	public bannerImageSafeUrl: SafeUrl;
	public bannerSubtitle: string;
	public bannerSubtitleDisplay: string;
	public bannerSize: string;
	public lockDiscussion: boolean;
	public location: string;
	public locationShowMap: boolean;
	public primaryColor;
	public secondaryColor;
	public photosOrder;

	public photosCount: number;
	public eventUsers: Array<EventUser>;
	public status: string;					// active/pending - Set on the events page if the user is a member
}
