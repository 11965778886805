import { Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxMasonryComponent } from 'ngx-masonry';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { Folder } from 'src/app/models/folder.model';
import { Gallery } from 'src/app/models/gallery.model';
import { Photo } from 'src/app/models/photo.model';
import { SignedDownloadToken } from 'src/app/models/signed-download-token.model';
import { User } from 'src/app/models/user.model';
import { EventService } from 'src/app/services/event.service';
import { FolderService } from 'src/app/services/folder.service';
import { GalleryService } from 'src/app/services/gallery.service';
import { LayoutService } from 'src/app/services/layout.service';
import { PhotoService } from 'src/app/services/photo.service';
import { UserConnectionService } from 'src/app/services/user-connection.service';
import { AuthService } from '../../../../services/auth.service';
import { UserService } from '../../../../services/user.service';

@Component({
	selector: 'app-photo-search',
	templateUrl: './explore.component.html',
	styleUrls: ['./explore.component.css']
})
export class PhotoExploreComponent implements OnInit, OnDestroy {
	@ViewChild('photoDetailsDialog', { static: true }) photoDetailsDialog: TemplateRef<any>;
	@ViewChild('imageDeleteDialog', { static: true }) imageDeleteDialog: TemplateRef<any>;
	@ViewChild('requireUserApprovalDisableDialog', { static: true }) requireUserApprovalDisableDialog: TemplateRef<any>;
	@ViewChild('requirePhotoApprovalDisableDialog', { static: true }) requirePhotoApprovalDisableDialog: TemplateRef<any>;
	@ViewChild('removeUserFromVaultDialog', { static: true }) removeUserFromVaultDialog: TemplateRef<any>;

	@ViewChild('sortBySelect') sortBySelect: NgSelectComponent;
	@ViewChild('vaultsSelect') vaultsSelect: NgSelectComponent;
	@ViewChild('peopleSelect') peopleSelect: NgSelectComponent;
	@ViewChild('keywordsSelect') keywordsSelect: NgSelectComponent;
	@ViewChild('eventSelect') eventSelect: NgSelectComponent;
	@ViewChild('locationsSelect') locationsSelect: NgSelectComponent;

	@ViewChild('showOnlyUncategorizedPhotosSwitch') showOnlyUncategorizedPhotosSwitch: ElementRef;

	@ViewChild('vaultActionsSelect') vaultActionsSelect: NgSelectComponent;
	@ViewChild('vaultActionsSelectPrivate') vaultActionsSelectPrivate: NgSelectComponent;

	@ViewChild('vaultInviteEmailsSelect') vaultInviteEmailsSelect: NgSelectComponent;
	@ViewChild('vaultInviteUsersSelect') vaultInviteUsersSelect: NgSelectComponent;
	@ViewChild('vaultUserTypesSelect') vaultUserTypesSelect: NgSelectComponent;

	@ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;

	modalRef: BsModalRef;

	loading = true;
	loadingSearch = true;
	loadingFilter = false;
	loadingSave = false;
	loadingUserPublicTags = false;

	userPeopleLoaded = false;
	userKeywordsLoaded = false;
	userLocationsLoaded = false;
	userEventsLoaded = false;
	userFoldersLoaded = false;
	userPublicTagsLoaded = false;

	showHelp = false;
	token: any;

	modalClosedSubscription: Subscription;
	photoDeletedSubscription: Subscription;
	photoCategorizedSubscription: Subscription;

	photoLeftClickedSubscription: Subscription;
	photoRightClickedSubscription: Subscription;

	error = '';
	iconText = '';
	actionText = '';

	showExploreTypeSelector;

	searchType = ''; // tag/filter
	searchTypeSwitchValue = false;

	// Filter querystring inputs
	qsConnectionIds: number[] = [];
	qsLocation = '';
	qsKeyword = '';
	qsPerson = '';
	qsEvent = '';
	qsFolder = '';
	qsLocations = '[]';
	qsKeywords = '[]';
	qsPeople = '[]';
	qsUncategorized = false;

	// Filter form fields
	gallery: Gallery;
	galleryForm: UntypedFormGroup;
	sortByForm: UntypedFormGroup;
	filterForm: UntypedFormGroup;
	defaultTypeForm: UntypedFormGroup;
	people: any[] = [];
	capturedDate: Date;
	locations: any[] = [];
	keywords: any[] = [];
	events: any[] = [];
	emails: any[] = [];

	peopleSelected: any[] = [];
	locationsSelected: any[] = [];
	capturedDateSelected: any;
	keywordsSelected: any[] = [];
	eventSelected: any = null;
	showOnlyUncategorizedPhotos = false;

	folders: Folder[] = [];
	selectedFolderId: number = -1;
	selectedFolderName: string = '';
	folderSelectorCollapsed = true;
	expandedRootId = -1;

	images: any[] = [];
	maxDate: Date;

	// Connections
	currentUser: User;
	connectionUsers: any[] = [];

	loadingPaymentMethods = false;
	paymentMethods: any[] = [];
	paymentMethodsLoadedSubscription: Subscription;

	externalPhotosPending: any[] = [];
	externalPhotos: any[] = [];
	externalPhotosPointer = 0;
	lastStartPosition = 0;
	selectedPhotoPointer = 0;
	selectedPhoto: Photo;
	showImageLightbox = false;
	showPhotoDetailsModal = false;

	approveUserQueue = false;
	approvePhotoQueue = false;

	displayReactionSelector = false;

	leftContainerExpanded = false;
	displayFiltersSidebar = false;
	displayTagsSidebar = false;
	displayGallerySidebar = false;
	displayUpdateSidebar = false;

	tempInviteEmail = '';

	photoIconHoverMessage = '';

	activePhotosWidthMine = 0;
	activePhotosWidthTheirs = 0;
	activePhotosSecondsMine = 0;
	activePhotosSecondsTheirs = 0;

	activeUsersWidthMembers = 0;
	activeUsersWidthModerators = 0;
	activeUsersSecondsMembers = 0;
	activeUsersSecondsModerators = 0;

	muteSearch = false;

	userChangedSubscription: Subscription;

	displayAllConnections = false;
	defaultPhotoOwnerUserIdsArray;
	connectionUsersSelected: any[] = [];
	connectionUsersUnselected: any[] = [];

	modalTab = 'discussTab';

	// Tags
	tagsForm: UntypedFormGroup;
	tagsSelectorCollapsed = true;
	userTags: any[] = [];
	filteredUserTags: any[] = [];
	filteredPublicTags: any[] = [];
	tempTag = '';
	selectedTags: any[] = [];

	private tagSearchSubject = new Subject<string>();
	private readonly debounceTimeMs = 500; // Set the debounce time (in milliseconds)

	// Thread is used for if someone selects a filter before the previous search has fully loaded,
	// we create a new thread which causes photos from the previous search to stop loading.
	thread: number = 0;

	constructor(
		public authService: AuthService,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: UntypedFormBuilder,
		private modalService: BsModalService,
		private connectionService: UserConnectionService,
		private galleryService: GalleryService,
		private layoutService: LayoutService,
		private eventService: EventService,
		private folderService: FolderService,
		private photoService: PhotoService,
		private toastr: ToastrService,
		public userService: UserService,
	) {
		this.maxDate = new Date();
	}

	// Allow the escape key to close the photo modal
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
		this.showPhotoDetailsModal = false;
		this.showImageLightbox = false;
	}

	/**
	 * 1. Initialize forms
	 * 2. Subscribe to events
	 * 3. Load dropdown data
	 * 		- Each dropdown function contains logic to load the user data after all dropdown data has been loaded
	 */
	ngOnInit() {
		// Init Forms
		this.initFilterForm();
		this.initGalleryForm();
		this.initSortForm();
		this.initTagsForm();
		this.initDefaultTypeForm();

		// Subscriptions
		this.subscribeToTagInputChanged();
		this.subscribeToUserChanged();
		this.subscribeToPhotoDeleted();
		this.subscribeToPhotoCategorized();
		this.subscribeToModalClosed();
		this.subscribeToPhotoLeftClicked();
		this.subscribeToPhotoRightClicked();

		// Load dropdown data
		this.loadPeople();
		this.loadUserLocations();
		this.loadUserKeywords();
		this.loadEvents();
		this.loadFolders();
		this.loadUserPublicTags();
	}

	ngOnDestroy(): void {
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	loadPageParams() {
		this.route.queryParams.subscribe(params => {
			// Load query parameters. These all trigger their own search when selecting the dropdown via the (change) event.
			// Only one of these can load at a time, in the following order:
			// 1. Keyword
			// 2. Location
			// 3. Person
			// 4. Show only my uncategorized photos
			// 5. Gallery
			// 6. Event
			// 7. Folder
			// 8. TODO: Public Tags
			if (params['keyword']) {
				this.qsKeyword = params['keyword'];
				this.selectKeywordsDropdownValues();
			} else if (params['location']) {
				this.qsLocation = params['location'];
				this.selectLocationsDropdownValues();
			} else if (params['person']) {
				this.qsPerson = params['person'];
				this.selectPeopleDropdownValues();
			} else if (params['uncategorized']) {
				this.showOnlyUncategorizedPhotos = true;
				this.filterForm?.patchValue({ 'showOnlyUncategorizedPhotos': true });
				this.filterChanged();
			} else if (params['galleryId']) {
				const galleryId = params['galleryId'];
				this.retrieveGallery(galleryId);
				this.showGallerySidebar();
			} else if (params['eventId']) {
				this.qsEvent = params['eventId'];
				this.selectEventsDropdownValues();
			} else if (params['folderId']) {
				this.qsFolder = params['folderId'];
				this.selectFolderValue();
			} else if (params['tags']) {
				// Notes: Tags are formatted as ?tags=Rossville High School,2002 Senior Class Trip

				// TODO: Parse the tags
				let aryTags = new Array();
				aryTags = params['tags'].split(",");

				for (let tag of aryTags) {
					this.selectedTags.push({ name: tag });
					this.selectedTags.sort((a, b) => a.name.localeCompare(b.name));
					this.selectedTags = this.selectedTags.slice();
				}

				// TODO: Perform the search
				let newThread = this.getNewThread();
				this.thread = newThread;
				this.retrieveImagesByTags(newThread);
			}

			// Perform a search only if none of the above applies
			if (!params['galleryId'] && !params['keyword'] && !params['location'] && !params['person'] && !params['uncategorized'] && !params['tags']) {
				this.determineExploreType();
				// let newThread = this.getNewThread();
				// this.thread = newThread;
				// this.retrieveImages(newThread);
			}
		});
	}


	/** 
	 * INITIALIZE FORMS
	 **/
	initGalleryForm() {
		this.galleryForm = this.formBuilder.group({
			'name': new UntypedFormControl(null, [Validators.required])
		});
	}

	initSortForm() {
		this.sortByForm = this.formBuilder.group({
			'sortBy': new UntypedFormControl('addedDesc', [])
		});
	}

	initFilterForm() {
		this.filterForm = this.formBuilder.group({
			// 'ownership': new FormControl(null, []),
			'people': new UntypedFormControl(null, []),
			'capturedDate': new UntypedFormControl(null, []),
			'locations': new UntypedFormControl(null, []),
			'keywords': new UntypedFormControl(null, []),
			'events': new UntypedFormControl(null, []),
			'showOnlyUncategorizedPhotos': new UntypedFormControl(null, [])
		});
	}

	initTagsForm() {
		this.tagsForm = this.formBuilder.group({
			'tags': new UntypedFormControl(),
		});
	}

	initDefaultTypeForm() {
		this.defaultTypeForm = this.formBuilder.group({
			'setDefault': new UntypedFormControl(null, []),
		});
	}

	/**
	 * SUBSCRIPTIONS
	 **/
	subscribeToTagInputChanged() {
		this.tagSearchSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe(() => {
			this.tagsKeyupSearch();
		}
		);
	}

	subscribeToUserChanged() {
		this.userChangedSubscription = this.userService.userChanged
			.subscribe((user: User) => {
				// The current user is retrieved here is the site is refreshed on the explore page.
				if (user.id == this.userService.getLocalUserId(0)) {
					user.name = user.firstName + " " + user.lastName;

					this.currentUser = user;

					this.people.push(user);

					this.defaultPhotoOwnerUserIdsArray = JSON.parse(user.defaultPhotoOwnerUserIds);
				}
			});
	}

	subscribeToPhotoDeleted() {
		this.photoDeletedSubscription = this.photoService.photoDeleted
			.subscribe((photoId) => {
				const item = this.externalPhotos.find(d => d.id === photoId);

				if (item) {
					item.status = 'deleted';
				}
			});
	}

	subscribeToPhotoCategorized() {
		this.photoCategorizedSubscription = this.photoService.photoCategorized
			.subscribe((photo: Photo) => {
				const item = this.externalPhotos.find(d => d.id === photo.id);

				// If the "Only show uncategorized photos" filter is selected, remove the item.
				// If not, item.touched = true
				if (this.filterForm?.value.showOnlyUncategorizedPhotos != null && this.filterForm?.value.showOnlyUncategorizedPhotos) {
					this.externalPhotos.splice(this.externalPhotos.indexOf(item), 1);
				} else {
					item.touched = true;
				}
			});
	}

	subscribeToPhotoLeftClicked() {
		this.photoLeftClickedSubscription = this.layoutService.photoLeftArrowClicked
			.subscribe(() => {
				let prevPointer = this.selectedPhotoPointer;

				if (prevPointer == 0) {
					prevPointer = this.externalPhotos.length - 1;
				} else {
					prevPointer--;
				}

				this.selectedPhotoPointer = prevPointer;
				this.photoService.setActivePhoto(this.externalPhotos[prevPointer]);

				this.selectedPhoto = this.externalPhotos[prevPointer];
			});
	}

	subscribeToPhotoRightClicked() {
		this.photoRightClickedSubscription = this.layoutService.photoRightArrowClicked
			.subscribe(() => {
				let nextPointer = this.selectedPhotoPointer;

				if (nextPointer == (this.externalPhotos.length - 1)) {
					nextPointer = 0;
				} else {
					nextPointer++;
				}

				this.selectedPhotoPointer = nextPointer;
				this.photoService.setActivePhoto(this.externalPhotos[nextPointer]);

				this.selectedPhoto = this.externalPhotos[nextPointer];
			});
	}

	subscribeToModalClosed() {
		this.modalClosedSubscription = this.photoService.modalClosed
			.subscribe(() => {
				this.showPhotoDetailsModal = false;
			});
	}

	/**
	 * CHOOSE WHICH EXPLORE TYPE FUNCTIONS
	 */
	chooseExploreType(type) {
		if (this.searchTypeSwitchValue) {
			let user = new User();
			user.id = this.currentUser.id;
			user.defaultExploreType = type;

			this.userService.updateCurrentUser(user).subscribe(
				response => {
					this.userService.updateLocalUser(response.body);
				});
		}

		this.showExploreTypeSelector = false;

		let newThread = this.getNewThread();
		this.thread = newThread;

		if (type == 'public') {
			// Public
			this.retrieveImagesByTags(newThread);
		} else {
			// My Network
			this.retrieveImages(newThread);
		}
	}

	/**
	 * SIDEBAR FUNCTIONS
	 */
	collapseSidebar() {
		this.leftContainerExpanded = false;
		this.displayFiltersSidebar = false;
		this.displayTagsSidebar = false;
		this.displayGallerySidebar = false;
		this.displayUpdateSidebar = false;
	}

	showFiltersSidebar() {
		if (this.displayFiltersSidebar) {
			this.leftContainerExpanded = false;
			this.displayFiltersSidebar = false;
		} else {
			this.leftContainerExpanded = true;
			this.displayFiltersSidebar = true;
		}

		this.displayTagsSidebar = false;
		this.displayGallerySidebar = false;
		this.displayUpdateSidebar = false;
	}

	showTagsSidebar() {
		if (this.displayTagsSidebar) {
			this.leftContainerExpanded = false;
			this.displayTagsSidebar = false;
		} else {
			this.leftContainerExpanded = true;
			this.displayTagsSidebar = true;
		}

		this.displayFiltersSidebar = false;
		this.displayGallerySidebar = false;
		this.displayUpdateSidebar = false;
	}

	showUpdateSidebar() {
		if (this.displayUpdateSidebar) {
			this.leftContainerExpanded = false;
			this.displayUpdateSidebar = false;
		} else {
			this.leftContainerExpanded = true;
			this.displayUpdateSidebar = true;
		}

		this.displayFiltersSidebar = false;
		this.displayTagsSidebar = false;
		this.displayGallerySidebar = false;
	}

	showGallerySidebar() {
		if (this.displayGallerySidebar) {
			this.leftContainerExpanded = false;
			this.displayGallerySidebar = false;
		} else {
			this.leftContainerExpanded = true;
			this.displayGallerySidebar = true;
		}

		this.displayFiltersSidebar = false;
		this.displayTagsSidebar = false;
		this.displayUpdateSidebar = false;
	}

	/**
	 * LOAD DROPDOWN VALUES
	 */

	loadPeople() {
		// The current user is retrieved from here if the user loaded the site on another page and navigated here.
		let currentUser = this.userService.getLocalUser(0);
		if (currentUser) {
			currentUser.name = currentUser.firstName + " " + currentUser.lastName;

			this.currentUser = currentUser;

			this.people.push(currentUser);

			this.defaultPhotoOwnerUserIdsArray = JSON.parse(currentUser.defaultPhotoOwnerUserIds);
		}

		this.connectionService.getUserConnectionsActive().subscribe(
			response => {
				for (const user of response.body) {
					user.name = user.firstName + " " + user.lastName;

					// 
					const item = this.defaultPhotoOwnerUserIdsArray.find(d => d === user.id);
					if (item) {
						user.isSearchDefaultOwner = true;
						this.connectionUsersSelected.push(user);
					} else {
						user.isSearchDefaultOwner = false;
						this.connectionUsersUnselected.push(user);
					}

					this.people.push(user);
					this.connectionUsers.push(user);
				}
				this.people.sort((a, b) => a.name.localeCompare(b.name));
				this.people = this.people.slice();

				this.loadUserPeople();
			}
		);
	}

	loadUserPeople() {
		this.userService.getUserPeople().subscribe(
			response => {
				for (const person of response) {
					const item = this.people.find(d => d.name === person);
					if (!item) {
						this.people.push({ name: person });
					}
				}
				this.people.sort((a, b) => a.name.localeCompare(b.name));
				this.people = this.people.slice();

				this.userPeopleLoaded = true;

				if (this.userKeywordsLoaded && this.userLocationsLoaded && this.userPeopleLoaded && this.userEventsLoaded && this.userFoldersLoaded && this.userPublicTagsLoaded) {
					this.loadPageParams();
				}
			}
		);
	}

	loadUserLocations() {
		// TODO: Check cache before retrieving from api.
		this.userService.getUserLocations().subscribe(
			response => {
				for (const location of response) {
					this.locations.push({ id: location, name: location });
				}
				this.locations.sort((a, b) => a.name.localeCompare(b.name));
				this.locations = this.locations.slice();

				this.userLocationsLoaded = true;

				if (this.userKeywordsLoaded && this.userLocationsLoaded && this.userPeopleLoaded && this.userEventsLoaded && this.userFoldersLoaded && this.userPublicTagsLoaded) {
					this.loadPageParams();
				}
			}
		);
	}

	loadUserKeywords() {
		// TODO: Check cache before retrieving from api.
		this.userService.getUserKeywords().subscribe(
			response => {
				for (const keyword of response) {
					this.keywords.push({ id: keyword, name: keyword });
				}
				this.keywords.sort((a, b) => a.name.localeCompare(b.name));
				this.keywords = this.keywords.slice();

				this.userKeywordsLoaded = true;

				if (this.userKeywordsLoaded && this.userLocationsLoaded && this.userPeopleLoaded && this.userEventsLoaded && this.userFoldersLoaded && this.userPublicTagsLoaded) {
					this.loadPageParams();
				}
			}
		);
	}

	// TODO: LOAD MEMBER EVENTS TOO
	loadEvents() {
		// TODO: Check cache before retrieving from api.
		this.eventService.getEventsAsOwner().subscribe(
			response => {
				for (const event of response.body) {
					this.events.push({ id: event.id, name: event.name });
				}

				this.events.sort((a, b) => a.name.localeCompare(b.name));
				this.events = this.events.slice();

				this.userEventsLoaded = true;

				if (this.userKeywordsLoaded && this.userLocationsLoaded && this.userPeopleLoaded && this.userEventsLoaded && this.userFoldersLoaded && this.userPublicTagsLoaded) {
					this.loadPageParams();
				}
			}
		);
	}

	loadFolders() {
		this.folderService.getFolders(0).subscribe(
			response => {
				this.folders = response.body;

				this.folders.sort((a, b) => a.name.toString().localeCompare(b.name));
				this.folders = this.folders.slice();

				this.userFoldersLoaded = true;

				if (this.userKeywordsLoaded && this.userLocationsLoaded && this.userPeopleLoaded && this.userEventsLoaded && this.userFoldersLoaded && this.userPublicTagsLoaded) {
					this.loadPageParams();
				}
			}
		);
	}

	loadUserPublicTags() {
		this.userService.getUserPublicTags().subscribe(
			response => {
				for (const tag of response) {
					this.userTags.push({ name: tag });
				}
				this.userTags.sort((a, b) => a.name.localeCompare(b.name));
				this.userTags = this.userTags.slice();

				this.userPublicTagsLoaded = true;

				if (this.userKeywordsLoaded && this.userLocationsLoaded && this.userPeopleLoaded && this.userEventsLoaded && this.userFoldersLoaded && this.userPublicTagsLoaded) {
					this.loadPageParams();
				}
			}
		);
	}

	/**
	 * DETERMINE EXPLORE TYPE
	 */
	determineExploreType() {
		// Check if the user has a default option
		if (this.currentUser.defaultExploreType == 'network') {
			this.showExploreTypeSelector = false;

			let newThread = this.getNewThread();
			this.thread = newThread;
			this.retrieveImages(newThread);
		} else if (this.currentUser.defaultExploreType == 'public') {
			this.showExploreTypeSelector = false;

			let newThread = this.getNewThread();
			this.thread = newThread;
			this.retrieveImagesByTags(newThread);
		} else {
			this.showExploreTypeSelector = true;
		}
	}




























	/**
	 * SELECT DROPDOWN VALUES
	 */

	// NOTE: Set this.qsPerson or this.qsPeople before calling this to select a value.
	selectPeopleDropdownValues() {
		if (this.qsPerson) {
			// TODO: There has to be a better way than a setTimeout for this.
			const _this = this;
			setTimeout(function () {
				let item = _this.peopleSelect.itemsList.findByLabel(_this.qsPerson);
				if (item) {
					_this.peopleSelect.select(item);
				}
			}, 100);
		}
		if (this.qsPeople) {
			// TODO: There has to be a better way than a setTimeout for this.
			const _this = this;
			setTimeout(function () {
				let people = JSON.parse(_this.qsPeople);
				if (people.length > 0) {
					for (let person of people) {
						let item = _this.peopleSelect.itemsList.findByLabel(person);
						if (item) {
							_this.peopleSelect.select(item);
						} else {
							// console.log('no item');
						}
					}
				}
			}, 100);
		}
	}

	// NOTE: Set this.qsLocation or this.qsLocations before calling this to select a value.
	selectLocationsDropdownValues() {
		if (this.qsLocation) {
			// TODO: There has to be a better way than a setTimeout for this.
			const _this = this;
			setTimeout(function () {
				let item = _this.locationsSelect.itemsList.findByLabel(_this.qsLocation);
				if (item) {
					_this.locationsSelect.select(item);
				}
			}, 100);
		}
		if (this.qsLocations) {
			// TODO: There has to be a better way than a setTimeout for this.
			const _this = this;
			setTimeout(function () {
				let locations = JSON.parse(_this.qsLocations);
				if (locations.length > 0) {
					for (let location of locations) {
						let item = _this.locationsSelect.itemsList.findByLabel(location);
						if (item) {
							_this.locationsSelect.select(item);
						} else {
							// console.log('no item');
						}
					}
				}
			}, 100);
		}
	}

	// NOTE: Set this.qsKeyword or this.qsKeywords before calling this to select a value.
	selectKeywordsDropdownValues() {
		if (this.qsKeyword) {
			// TODO: There has to be a better way than a setTimeout for this.
			const _this = this;
			setTimeout(function () {
				let item = _this.keywordsSelect.itemsList.findByLabel(_this.qsKeyword);
				if (item) {
					_this.keywordsSelect.select(item);
				} else {
					// console.log('no item');
				}
			}, 100);
		}
		if (this.qsKeywords) {
			// TODO: There has to be a better way than a setTimeout for this.
			const _this = this;
			setTimeout(function () {
				let keywords = JSON.parse(_this.qsKeywords);
				if (keywords.length > 0) {
					for (let keyword of keywords) {
						let item = _this.keywordsSelect.itemsList.findByLabel(keyword);
						if (item) {
							_this.keywordsSelect.select(item);
						} else {
							// console.log('no item');
						}
					}
				}
			}, 100);
		}
	}

	selectEventsDropdownValues() {
		if (this.qsEvent) {
			// TODO: There has to be a better way than a setTimeout for this.
			const _this = this;
			setTimeout(function () {
				const event = _this.events.find(d => d.id == _this.qsEvent);
				let item = _this.eventSelect.itemsList.findByLabel(event.name);
				if (item) {
					_this.eventSelect.select(item);
				}
			}, 100);
		}
	}

	selectFolderValue() {
		if (parseInt(this.qsFolder) > 0) {
			const folder = this.folders.find(d => d.id.toString() == this.qsFolder);
			if (folder) {
				this.selectedFolderId = folder.id;
				this.selectedFolderName = folder.name;
			} else {
				// The folder is a subfolder, find it
				for (let folder of this.folders) {
					//console.log(folder);
					for (let subfolder of folder.folders) {
						if (subfolder.id.toString() == this.qsFolder) {
							this.selectedFolderId = subfolder.id;
							this.selectedFolderName = subfolder.name;
							break;
						}
					}
				}

			}
		} else {
			// No folders
			this.selectNoFolder();
		}
	}

	/**
	 * FOLDER FUNCTIONS
	 */

	expandFolderSelector() {
		this.folderSelectorCollapsed = false;
	}

	collapseFolderSelector() {
		this.folderSelectorCollapsed = true;
	}

	expandFolder(event, folder) {
		event.stopPropagation();

		if (folder.folders && folder.folders.length > 0) {
			this.expandedRootId = folder.id;
		}
	}

	collapseFolder(event, folderId) {
		event.stopPropagation();

		this.expandedRootId = -1;
	}

	selectFolder(folder) {
		this.selectedFolderId = folder.id;
		this.selectedFolderName = folder.name;
		this.folderSelectorCollapsed = true;

		this.filterChanged();
	}

	selectNoFolder() {
		this.selectedFolderId = 0;
		this.selectedFolderName = 'Photos with no folder';
		this.expandedRootId = -1;
		this.folderSelectorCollapsed = true;

		this.filterChanged();
	}

	unselectFolder(event) {
		event.stopPropagation();

		this.selectedFolderId = -1;
		this.selectedFolderName = '';
		this.expandedRootId = -1;
		this.folderSelectorCollapsed = true;

		this.filterChanged();
	}

	/**
	 * EDIT PHOTO FUNCTIONS
	 */
	setSelectedPhotoPointer(selectedPhotoPointer: number) {
		this.selectedPhotoPointer = selectedPhotoPointer;
	}

	openPhotoDetailsModalClassify(photo: Photo) {
		this.closeImageLightbox();

		this.showPhotoDetailsModal = true;

		this.selectedPhoto = photo;
		this.photoService.setActivePhoto(photo);

		this.modalTab = 'classifyTab';

		this.modalRef = this.modalService.show(
			this.photoDetailsDialog,
			Object.assign({}, { class: 'modal-lg' }, { backdrop: true, ignoreBackdropClick: true, animated: true })
		);
	}

	openPhotoDetailsModalDiscuss(photo: Photo) {
		this.closeImageLightbox();

		this.showPhotoDetailsModal = true;

		this.selectedPhoto = photo;
		this.photoService.setActivePhoto(photo);

		this.modalTab = 'discussTab';

		this.modalRef = this.modalService.show(
			this.photoDetailsDialog,
			Object.assign({}, { class: 'modal-lg' }, { backdrop: true, ignoreBackdropClick: true, animated: true })
		);
	}

	updateIconText(text: string) {
		this.iconText = text;
	}

	updateActionText(text: string) {
		this.actionText = text;
	}

	closePhotoDetailsModal() {
		this.showPhotoDetailsModal = false;
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	/**
	 * SLIDESHOW FUNCTIONS
	 */
	openImageLightbox(photo: Photo, selectedPhotoPointer: number) {
		this.closePhotoDetailsModal();

		this.selectedPhotoPointer = selectedPhotoPointer;

		this.selectedPhoto = photo;
		this.photoService.setActivePhoto(photo);

		this.showImageLightbox = true;
	}

	closeImageLightbox() {
		this.selectedPhoto = null;
		this.showImageLightbox = false;
	}

	navigatePreviousPhoto(event: Event) {
		event.stopPropagation();
		let prevPointer = this.selectedPhotoPointer;

		if (prevPointer == 0) {
			prevPointer = this.externalPhotos.length - 1;
		} else {
			prevPointer--;
		}

		this.selectedPhotoPointer = prevPointer;
		this.photoService.setActivePhoto(this.externalPhotos[prevPointer]);

		this.selectedPhoto = this.externalPhotos[prevPointer];
	}

	navigateNextPhoto(event: Event) {
		event.stopPropagation();
		let nextPointer = this.selectedPhotoPointer;

		if (nextPointer == (this.externalPhotos.length - 1)) {
			nextPointer = 0;
		} else {
			nextPointer++;
		}

		this.selectedPhotoPointer = nextPointer;
		this.photoService.setActivePhoto(this.externalPhotos[nextPointer]);

		this.selectedPhoto = this.externalPhotos[nextPointer];
	}


	/**
	 * SEARCH / FILTER FUNCTIONS
	 */
	selectUser(user) {
		// Update the connectionUsersSelected and connectionUsersUnselected arrays
		user.isSearchDefaultOwner = true;
		this.connectionUsersSelected.push(user);

		const item = this.connectionUsersUnselected.find(d => d.id === user.id);
		this.connectionUsersUnselected.splice(this.connectionUsersUnselected.indexOf(item), 1);

		this.defaultPhotoOwnerUserIdsArray.push(user.id);

		// Re-trigger the search
		this.filterChanged();
	}

	unselectUser(user) {
		// Update the connectionUsersSelected and connectionUsersUnselected arrays
		user.isSearchDefaultOwner = false;
		this.connectionUsersUnselected.push(user);

		const item = this.connectionUsersSelected.find(d => d.id === user.id);
		this.connectionUsersSelected.splice(this.connectionUsersSelected.indexOf(item), 1);

		const itemId = this.defaultPhotoOwnerUserIdsArray.find(d => d === user.id);
		this.defaultPhotoOwnerUserIdsArray.splice(this.defaultPhotoOwnerUserIdsArray.indexOf(itemId), 1);

		// Re-trigger the search
		this.filterChanged();
	}

	filterChanged() {
		this.loadingSearch = true;
		this.setFilterSelectedValues();

		if (this.externalPhotos.length > 0) {
			this.externalPhotos = [];
			this.externalPhotosPending = []; // TODO: This might need re-done after integrating search.
			this.externalPhotosPointer = 0;
			this.selectedPhotoPointer = 0;

			let newThread = this.getNewThread();
			this.thread = newThread;
			this.retrieveImages(newThread);
		} else {
			let newThread = this.getNewThread();
			this.thread = newThread;
			this.retrieveImages(newThread);
		}
	}

	setFilterSelectedValues() {
		if (this.filterForm?.value.people) {
			this.peopleSelected = this.filterForm.value.people;
		} else {
			this.peopleSelected = [];
		}
		if (this.filterForm?.value.locations) {
			this.locationsSelected = [];
			this.locationsSelected.push(this.filterForm.value.locations);
		} else {
			this.locationsSelected = [];
		}
		if (this.filterForm?.value.capturedDate) {
			this.capturedDateSelected = this.filterForm.value.capturedDate;
		} else {
			this.capturedDateSelected = null;
		}
		if (this.filterForm?.value.keywords && this.filterForm?.value.keywords != undefined) {
			this.keywordsSelected = this.filterForm.value.keywords;
		} else {
			this.keywordsSelected = [];
		}
		if (this.filterForm?.value.events && this.filterForm?.value.events != undefined) {
			const event = this.events.find(d => d.id === this.filterForm.value.events);
			this.eventSelected = event;
		} else {
			this.eventSelected = null;
		}

		if (this.filterForm?.value.showOnlyUncategorizedPhotos != null && this.filterForm?.value.showOnlyUncategorizedPhotos) {
			this.showOnlyUncategorizedPhotos = true;
		} else {
			this.showOnlyUncategorizedPhotos = false;
		}
	}

	removeFilter(filterType, filterName) {
		if (filterType == 'vault') {
			let item = this.vaultsSelect.itemsList.findByLabel(filterName);
			this.vaultsSelect.unselect(item);
		} else if (filterType == 'people') {
			let item = this.peopleSelect.itemsList.findByLabel(filterName);
			this.peopleSelect.unselect(item);
		} else if (filterType == 'location') {
			let item = this.locationsSelect.itemsList.findByLabel(filterName);
			this.locationsSelect.unselect(item);
		} else if (filterType == 'capturedDate') {
			let value = { 'capturedDate': '' };
			this.filterForm.patchValue(value);
			this.capturedDateSelected = null;
		} else if (filterType == 'keyword') {
			let item = this.keywordsSelect.itemsList.findByLabel(filterName);
			this.keywordsSelect.unselect(item);
		} else if (filterType == 'event') {
			let item = this.eventSelect.itemsList.findByLabel(filterName);
			this.eventSelect.unselect(item);
		} else if (filterType == 'showOnlyUncategorizedPhotos') {
			this.showOnlyUncategorizedPhotosSwitch.nativeElement.click();
		}
	}

	removeAllFilters() {
		this.selectedFolderId = -1;
		this.selectedFolderName = '';
		this.expandedRootId = -1;
		this.folderSelectorCollapsed = true;

		if (this.filterForm.controls['people']) {
			this.filterForm.controls['people'].setValue(null);
		}
		if (this.filterForm.controls['locations']) {
			this.filterForm.controls['locations'].setValue(null);
		}
		if (this.filterForm.controls['capturedDate']) {
			this.filterForm.controls['capturedDate'].setValue('');
		}
		if (this.filterForm.controls['keywords']) {
			this.filterForm.controls['keywords'].setValue(null);
		}
		if (this.filterForm.controls['events']) {
			this.filterForm.controls['events'].setValue(null);
		}
		this.filterForm.controls['showOnlyUncategorizedPhotos'].setValue(false);

		//this.filterChanged();
	}

	retrieveImages(thread) {
		this.searchType = 'filter';

		//let sortBy = "random";
		let sortBy = "addedDesc";
		if (this.sortByForm?.value.sortBy) {
			sortBy = this.sortByForm.value.sortBy;
		}

		let ownerUserIdsArray = [];
		for (let user of this.connectionUsersSelected) {
			ownerUserIdsArray.push(user.id);
		}
		let ownerUserIds = JSON.stringify(ownerUserIdsArray);

		let people = "";
		if (this.filterForm?.value.people) {
			people = this.filterForm.value.people;
		}

		let capturedDate = "";
		if (this.filterForm?.value.capturedDate) {
			capturedDate = this.filterForm.value.capturedDate;
		}

		let locations = "";
		if (this.filterForm?.value.locations) {
			locations = this.filterForm.value.locations;
		}

		let keywords = "";
		if (this.filterForm?.value.keywords && this.filterForm?.value.keywords != undefined) {
			keywords = this.filterForm.value.keywords;
		}

		let event = "";
		if (this.filterForm?.value.events && this.filterForm?.value.events != undefined) {
			event = this.filterForm.value.events;
		}

		let folder = "";
		if (this.selectedFolderId > -1) {
			folder = this.selectedFolderId.toString();
		}

		let showOnlyUncategorizedPhotos = false;
		if (this.filterForm?.value.showOnlyUncategorizedPhotos != null && this.filterForm?.value.showOnlyUncategorizedPhotos) {
			showOnlyUncategorizedPhotos = this.filterForm.value.showOnlyUncategorizedPhotos;
		}

		this.lastStartPosition = this.externalPhotosPointer;

		this.photoService.getPhotos(this.externalPhotosPointer, sortBy, ownerUserIds, people, capturedDate, locations, keywords, folder, event, showOnlyUncategorizedPhotos).subscribe(
			response => {
				if (response.status === 200 && response.body.length > 0) {
					let index = 0;
					let photos = response.body;
					let _this = this;

					(function repeat() {
						setTimeout(function () {
							// The thread stops loading photos if a new search is performed.
							if (thread == _this.thread) {
								let photo = photos[index];

								photo.publicTagsArray = JSON.parse(photo.publicTags);

								if (!photo.imageSafeUrl) {
									_this.setImageSafeUrl(photo, thread);
								}

								_this.externalPhotosPointer++;

								// First loop stop the page load indicator, last loop stop the uncategorized load indicator
								index++;
								if (index < photos.length) {
									repeat();
								}
							}
						}, 200);
					})();
				} else {
					this.loading = false;
					this.loadingSearch = false;
				}
			},
			err => {
				this.loadingSearch = false;
			}
		);
	}

	retrieveImagesByTags(thread) {
		this.loadingSearch = true;

		this.searchType = 'tag';

		//let sortBy = "random";
		let sortBy = "addedDesc";
		if (this.sortByForm?.value.sortBy) {
			sortBy = this.sortByForm.value.sortBy;
		}

		let ownerUserIdsArray = [];
		// for (let user of this.connectionUsersSelected) {
		// 	ownerUserIdsArray.push(user.id);
		// }
		let ownerUserIds = JSON.stringify(ownerUserIdsArray);

		let people = "";
		// if (this.filterForm?.value.people) {
		// 	people = this.filterForm.value.people;
		// }

		let tags = "";
		if (this.selectedTags.length > 0) {
			for (let tag of this.selectedTags) {
				tags += tag.name + ',';
			}
			tags = tags.substring(0, tags.length - 1);
		}

		this.lastStartPosition = this.externalPhotosPointer;

		this.photoService.getPhotosByTags(this.externalPhotosPointer, sortBy, tags, ownerUserIds, people).subscribe(
			response => {
				if (response.status === 200 && response.body.length > 0) {
					let index = 0;
					let photos = response.body;
					let _this = this;

					(function repeat() {
						setTimeout(function () {
							// The thread stops loading photos if a new search is performed.
							if (thread == _this.thread) {
								let photo = photos[index];

								photo.publicTagsArray = JSON.parse(photo.publicTags);

								if (!photo.imageSafeUrl) {
									_this.setImageSafeUrl(photo, thread);
								}

								_this.externalPhotosPointer++;

								// First loop stop the page load indicator, last loop stop the uncategorized load indicator
								index++;
								if (index < photos.length) {
									repeat();
								}
							}
						}, 200);
					})();
				} else {
					this.loading = false;
					this.loadingSearch = false;
				}
			},
			err => {
				this.loadingSearch = false;
			}
		);
	}

	setImageSafeUrl(photo, thread) {
		// console.log('retrieveUploadedImage');

		// The threading stops loading the previous searches photos if a new search is performed.
		if (thread == this.thread) {
			// TODO: This call happens multiple times on the explore page because the token hasn't been
			//       returned yet. Need to determine if this is an issue or not.


			let photoUserId = photo.userId;

			let token: SignedDownloadToken = null;


			/**********************************************/

			// Types of tokens (do we need to be this granular?  I don't think so.):
			//		- /user_uuid
			// 		- /user_uuid/folder
			//		- /user_uuid_private
			// 		- /user_uuid_private/folder


			// Loop the cached tokens
			for (let signedDownloadToken of this.photoService.signedDownloadTokens) {
				// Investigate the ones who match the photo's userId
				if (signedDownloadToken.userId == photoUserId) {
					//console.log(photo.connectionsCanView);
					if (photo.connectionsCanView && signedDownloadToken.type == 'userPublic') {
						//console.log('found public token');
						token = signedDownloadToken;
					} else if (photo.connectionsCanView == false && signedDownloadToken.type == 'userPrivate') {
						// TODO: Does this work if its my photo and I have connectionsCanView == false? 
						//       Shouldn't I add logic to determine if its my photo or not?
						token = signedDownloadToken;
						//console.log('found private token');
					}
				}
			}

			if (token) {
				// Found a valid token, use it
				let url = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + token.token;
				photo.imageSafeUrl = url;
				this.loading = false;

				if (thread == this.thread) {
					this.externalPhotos.push(photo);
					this.loadingSearch = false;
				}
			} else {
				// No valid token for scope, pull new token
				let scope = photo.userId;
				let type = 'userPublic';
				if (this.selectedTags.length > 0 && photo.publicTags) {
					scope = photo.id;
					type = "tag";
				}
				//console.log(photo.connectionsCanView);
				if (photo.connectionsCanView == false) {
					type = 'userPrivate';
				}

				this.photoService.getSignedDownloadToken(scope, type).subscribe(
					response => {
						let responseToken = response.body;
						responseToken.userId = photoUserId;

						// Make sure token doesn't already exist.
						// I have to do this a second time because another photo could be adding this at
						// the same time.
						let token2;
						for (let signedDownloadToken of this.photoService.signedDownloadTokens) {
							if (signedDownloadToken.userId == photoUserId) {

								if (photo.connectionsCanView && signedDownloadToken.type == 'userPublic') {
									token2 = signedDownloadToken;
								} else if (photo.connectionsCanView == false && signedDownloadToken.type == 'userPrivate') {
									token2 = signedDownloadToken;
								}
							}
						}
						if (token2) {
							photo.imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + token2.token;
							this.loading = false;
						} else {
							photo.imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + responseToken.token;
							this.loading = false;

							this.photoService.signedDownloadTokens.push(responseToken);
						}

						if (thread == this.thread) {
							this.externalPhotos.push(photo);
							this.loadingSearch = false;
						}
					}
				);
			}

		}
	}

	onScroll() {
		// This keeps duplicate results from loading.  Wait until all results are loaded before we load the next bunch.
		const nextPositionPointer = this.lastStartPosition + 15;
		if (this.externalPhotos.length > 14 && nextPositionPointer == this.externalPhotosPointer) {
			this.retrieveImages(this.thread);
		}
	}

	/**
	 * GALLERY FUNCTIONS
	 */
	retrieveGallery(galleryId) {
		this.galleryService.retrieveGallery(galleryId).subscribe(
			response => {
				this.gallery = response.body;

				// Set gallery name
				this.galleryForm.setValue({ name: this.gallery.name });

				// This makes it so the search doesn't trigger while the values are being selected. If this wasn't
				// in place, the search would trigger 4 times due to the (change) event
				this.muteSearch = true;

				// Set dropdown values
				if (this.gallery.keywords) {
					this.qsKeywords = this.gallery.keywords;
					this.selectKeywordsDropdownValues();
				}
				if (this.gallery.locations) {
					this.qsLocations = this.gallery.locations;
					this.selectLocationsDropdownValues();
				}
				if (this.gallery.people) {
					this.qsPeople = this.gallery.people;
					this.selectPeopleDropdownValues();
				}

				// TODO: This has to finish after the select dropdown values above.  
				//       Would rather use promises then timeouts.
				const _this = this;
				setTimeout(function () {
					_this.muteSearch = false;
					_this.filterChanged();
				}, 300);
			});
	}

	cancelSaveGallery() {
		this.initGalleryForm();
		this.gallery = new Gallery;
	}

	saveGallery() {
		this.loadingSave = true;

		let gallery = new Gallery();
		gallery.name = this.galleryForm.value.name;

		if (this.filterForm.value.people) {
			gallery.people = JSON.stringify(this.filterForm.value.people);
		}
		if (this.filterForm.value.locations) {
			gallery.locations = JSON.stringify(this.filterForm.value.locations);
		}
		if (this.filterForm.value.keywords) {
			gallery.keywords = JSON.stringify(this.filterForm.value.keywords);
		}

		gallery.showOnlyUncategorizedPhotos = false;

		this.galleryService.createGallery(gallery).subscribe(
			response => {
				if (response) {
					// Clear the form
					this.initGalleryForm();

					this.gallery = gallery;

					this.toastr.success("Gallery successfully created");
					this.loadingSave = false;
				} else {
					this.toastr.error("An error occurred");
					this.loadingSave = false;
				}
			},
			err => {
				this.toastr.error("An error occurred");
				this.loadingSave = false;
			}
		);
	}

	updateGallery() {
		this.loadingSave = true;

		let gallery = new Gallery();
		gallery.id = this.gallery.id;
		gallery.name = this.galleryForm.value.name;

		if (this.filterForm.value.people) {
			gallery.people = JSON.stringify(this.filterForm.value.people);
		}
		if (this.filterForm.value.locations) {
			gallery.locations = JSON.stringify(this.filterForm.value.locations);
		}
		if (this.filterForm.value.keywords) {
			gallery.keywords = JSON.stringify(this.filterForm.value.keywords);
		}

		gallery.showOnlyUncategorizedPhotos = false;

		this.galleryService.updateGallery(gallery).subscribe(
			response => {
				if (response) {
					// Clear the form
					this.initGalleryForm();

					this.gallery = gallery;

					this.toastr.success("Gallery successfully updated");
					this.loadingSave = false;
				} else {
					this.toastr.error("An error occurred");
					this.loadingSave = false;
				}
			},
			err => {
				this.toastr.error("An error occurred");
				this.loadingSave = false;
			}
		);
	}


	/** PUBLIC TAGS DROPDOWN FUNCTIONS **/

	// Happens when the user presses the arrow down icon in the tag input
	showAllUserTags() {
		this.tagsSelectorCollapsed = !this.tagsSelectorCollapsed;
	}

	tagsKeyup() {
		this.tagSearchSubject.next(null);
	}

	// Happens when the user releases any key when in the tags input
	tagsKeyupSearch() {
		let value = this.tagsForm.controls['tags'].value;
		if (value.length > 1) {
			this.tagsSelectorCollapsed = false;
			this.tempTag = value;

			this.filteredUserTags = this.userTags.filter(d => String(d.name).toLowerCase().includes(this.tempTag.toLowerCase()));

			this.findPublicTags(this.tempTag.toLowerCase());
		} else {
			this.tagsSelectorCollapsed = true;
			this.tempTag = '';

			this.filteredUserTags = [];
			this.filteredPublicTags = [];
		}
	}

	// Happens when the user releases the enter key when in the tags input
	// This should add the typed value as a new tag
	tagsKeyupEnter() {
		let item = this.selectedTags.find(d => d.name === this.tempTag);
		if (!item && this.tempTag.length > 1) {
			this.selectedTags.push({ name: this.tempTag, type: 'new' });
			this.selectedTags.sort((a, b) => a.name.localeCompare(b.name));
			this.selectedTags = this.selectedTags.slice();
		}

		this.filteredPublicTags = [];
		this.filteredUserTags = [];
		this.tagsSelectorCollapsed = true;

		this.tagsForm.controls['tags'].setValue('');

		this.tempTag = '';
	}

	// Happens when the user clicks on a tag
	selectTag(tag) {
		let item = this.selectedTags.find(d => d.name === tag.name);
		if (!item) {
			this.selectedTags.push(tag);
			this.selectedTags.sort((a, b) => a.name.localeCompare(b.name));
			this.selectedTags = this.selectedTags.slice();
		}

		this.filteredPublicTags = [];
		this.filteredUserTags = [];
		this.tagsSelectorCollapsed = true;

		this.tagsForm.controls['tags'].setValue('');

		this.tempTag = '';

		// Trigger a tag search

		// NOTE: DO I REALLY NEED TO CLEAR THE ARRAYS HERE, OR IN A SEPARATE FUNCTION FIRST?
		this.externalPhotos = [];
		this.externalPhotosPending = []; // TODO: This might need re-done after integrating search.
		this.externalPhotosPointer = 0;
		this.selectedPhotoPointer = 0;

		let newThread = this.getNewThread();
		this.thread = newThread;
		this.retrieveImagesByTags(newThread);
	}

	// Happens when the user presses the X icon on the tag
	removeTag(tag) {
		let item = this.selectedTags.find(d => d.name === tag.name);
		if (item) {
			this.selectedTags.splice(this.selectedTags.indexOf(item), 1);
		}

		// Re-trigger the search
		this.loadingSearch = true;

		this.externalPhotos = [];
		this.externalPhotosPending = []; // TODO: This might need re-done after integrating search.
		this.externalPhotosPointer = 0;
		this.selectedPhotoPointer = 0;

		let newThread = this.getNewThread();
		this.thread = newThread;

		// TODO: If this is the last tag, run a non-tag search instead
		if (this.selectedTags.length == 0) {
			this.retrieveImages(newThread);
		} else {
			this.retrieveImagesByTags(newThread);
		}
	}

	cancelAddTag() {
		this.tagsSelectorCollapsed = true;
		this.tagsForm.controls['tags'].setValue('');
		this.tempTag = '';
	}

	clearTags() {
		this.selectedTags = [];
		this.filteredUserTags = [];
		this.filteredPublicTags = [];
		this.tagsSelectorCollapsed = true;

		this.filterChanged();
	}

	findPublicTags(keyword) {
		this.loadingUserPublicTags = true;
		this.filteredPublicTags = [];
		this.userService.findPublicTagsByKeyword(keyword).subscribe(
			response => {
				for (const tag of response) {
					let item = this.filteredUserTags.find(d => d.name === tag);
					if (!item) {
						this.filteredPublicTags.push({ name: tag, type: 'new' });
					}
				}
				this.filteredPublicTags.sort((a, b) => a.name.localeCompare(b.name));
				this.filteredPublicTags = this.filteredPublicTags.slice();

				this.loadingUserPublicTags = false;
			}
		);
	}

	setDefaultSwitchChanged(e) {
		this.searchTypeSwitchValue = e.srcElement.checked;
	}

	/**
	 * HELPER FUNCTIONS
	 */

	imageExists(url, callback) {
		var img = new Image();
		img.onload = function () { callback(true); };
		img.onerror = function () { callback(false); };
		img.src = url;
	}

	navigateToManagePayments() {
		this.router.navigate(['profile/'], { queryParams: { tab: 'payment' } });
	}

	uploadPhotos() {
		this.router.navigate(['/photo/add/upload']);
	}

	navigate(path) {
		this.router.navigate([path]);
	}

	getNewThread() {
		let newThread = Math.floor(Math.random() * 10000);

		// One chance to get a unique thread
		if (newThread == this.thread) {
			newThread = Math.floor(Math.random() * 10000);
		}

		return newThread;
	}
}
