import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgImageSliderComponent } from 'ng-image-slider';
import { Gallery } from 'src/app/models/gallery.model';
import { PhotoService } from 'src/app/services/photo.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-pinned-photo-gallery',
	templateUrl: './pinned.component.html',
	styleUrls: ['./pinned.component.css']
})
export class PinnedPhotoGalleryComponent implements OnInit {
	@Input() gallery: Gallery;
	@ViewChild('slider') slider: NgImageSliderComponent;

	loadingFirst = true;
	loadingLast = true;

	images: Object[] = [];

	constructor(
		private router: Router,
		private photoService: PhotoService,
		private userService: UserService
	) { }

	ngOnInit() {
		this.retrieveGalleryImages(this.gallery)
	}

	retrieveGalleryImages(gallery: Gallery) {

		let ownerUserIdsArray = [];
		ownerUserIdsArray.push(this.userService.users[0].id);
		let ownerUserIds = JSON.stringify(ownerUserIdsArray);

		let people = "";
		if (gallery && gallery.people) {
			people = JSON.parse(gallery.people);
		}

		// TODO: Add date ability
		let capturedDate = "";
		//if (gallery && gallery.capturedDate) {
		//  capturedDate = gallery.capturedDate;
		//}

		let locations = "";
		if (gallery && gallery.locations) {
			locations = JSON.parse(gallery.locations);
		}

		let keywords = "";
		if (gallery && gallery.keywords && gallery.keywords != undefined) {
			keywords = JSON.parse(gallery.keywords);
		}

		let event = "";
		if (gallery && gallery.event && gallery.event != undefined) {
			event = JSON.parse(gallery.event);
		}

		let folder = "";
		if (gallery && gallery.folder && gallery.folder != undefined) {
			folder = JSON.parse(gallery.folder);
		}

		let showOnlyUncategorizedPhotos = false;
		if (gallery && gallery.showOnlyUncategorizedPhotos) {
			showOnlyUncategorizedPhotos = gallery.showOnlyUncategorizedPhotos;
		}

		this.photoService.getPhotos(0, "random", people, ownerUserIds, capturedDate, locations, keywords, folder, event, showOnlyUncategorizedPhotos).subscribe(
			response => {
				let index = 0;
				let pinnedPhotos = response.body;
				let _this = this;

				if (pinnedPhotos.length > 0) {
					(function repeat() {
						setTimeout(function () {
							let photo = pinnedPhotos[index];

							let token = _this.photoService.signedDownloadTokens.find(d => d.userId === photo.userId.toString());
							if (token) {
								if (!photo.imageSafeUrl) {
									let imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + token.token;

									let image = { image: imageSafeUrl, thumbImage: imageSafeUrl };
									_this.images.push(image);
								} else {
									let image = { image: photo.imageSafeUrl, thumbImage: photo.imageSafeUrl };
									_this.images.push(image);
								}
							} else {
								// TODO: Should I allow public photos?
								let type = 'userPublic';
								if (photo.connectionsCanView == false) {
									type = 'userPrivate';
								}
								_this.photoService.getSignedDownloadToken(photo.userId.toString(), type).subscribe(
									response => {
										if (!photo.imageSafeUrl) {
											let imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + response.body.token;

											let image = { image: imageSafeUrl, thumbImage: imageSafeUrl };
											_this.images.push(image);
										} else {
											let image = { image: photo.imageSafeUrl, thumbImage: photo.imageSafeUrl };
											_this.images.push(image);
										}
									}
								);
							}

							index++;
							_this.loadingFirst = false;
							if (index == pinnedPhotos.length) {
								_this.loadingLast = false;
							} else {
								repeat();
							}
							/** LEGACY FACEBOOK CODE
							// TODO: Check if the image size from the URL is the same before loading the image from facebook or database.
							if (photo.imageUrl) {
								// Check if the imageUrl is still valid or if the cached url has expired.
								_this.imageExists(photo.imageUrl, function (exists) {
									if (exists) {
										let image = { image: photo.imageUrl, thumbImage: photo.imageUrl };
										_this.photos.push(image);

										// TODO: For whatever reason ng-image-slider does not display 0 index photos.
										//       Remove whenever this is fixed.
										if (index == 0) {
											_this.photos.push(image);
										}

										index++;
										_this.loadingFirst = false;
										if (index == pinnedPhotos.length) {
											_this.loadingLast = false;
										} else {
											repeat();
										}
									} else if (photo.type == 'facebook') {
										// Photo URL is no longer valid, retrieve the new URL.
										// TODO: Move the authentication into the service?
										_this.facebookService.authenticate(function (model) {
											_this.facebookService.setToken(model.authResponse.accessToken);
											_this.facebookService.setUserId(model.authResponse.userID);

											_this.facebookService.retrievePhoto(photo.externalId, function (response2) {
												// TODO: Check to see if this photo status is pending?
												let image = { image: response2.images[0].source, thumbImage: response2.images[0].source };
												_this.photos.push(image);

												// TODO: For whatever reason ng-image-slider does not display 0 index photos.
												//       Remove whenever this is fixed.
												if (index == 0) {
													_this.photos.push(image);
												}

												// Update the URL in the database.
												photo.imageUrl = response2.images[0].source;
												_this.photoService.updatePhoto(photo).subscribe(
													response3 => {
														// Do nothing.  The photo has been updated.
													});

												index++;
												_this.loadingFirst = false;
												if (index == pinnedPhotos.length) {
													_this.loadingLast = false;
												} else {
													repeat();
												}
											});
										});
									}
								});
							} else if (photo.type == 'facebook') {
								_this.facebookService.authenticate(function (model) {
									_this.facebookService.setToken(model.authResponse.accessToken);
									_this.facebookService.setUserId(model.authResponse.userID);

									_this.facebookService.retrievePhoto(photo.externalId, function (response2) {
										let image = { image: response2.images[0].source, thumbImage: response2.images[0].source };
										_this.photos.push(image);

										// TODO: For whatever reason ng-image-slider does not display 0 index photos.
										//       Remove whenever this is fixed.
										if (index == 0) {
											_this.photos.push(image);
										}

										// Update the URL in the database.
										photo.imageUrl = response2.images[0].source;
										_this.photoService.updatePhoto(photo).subscribe(
											response3 => {
												// Do nothing.  The photo has been updated.
											});

										index++;
										_this.loadingFirst = false;
										if (index == pinnedPhotos.length) {
											_this.loadingLast = false;
										} else {
											repeat();
										}
									});
								});
							} else if (photo.type == 'upload') {

								// TODO: Retrieve photo
								_this.photoService.getPhotoImage(photo.externalIdMed).subscribe(
									response => {
										var reader = new FileReader();
										reader.readAsDataURL(response.body);

										reader.onloadend = function () {
											var base64data = reader.result;
											let image = { image: base64data, thumbImage: base64data };

											console.log(typeof base64data);
											console.log(base64data);
											_this.photos.push(image);

											// TODO: For whatever reason ng-image-slider does not display 0 index photos.
											//       Remove whenever this is fixed.
											if (index == 0) {
												_this.photos.push(image);
											}

											index++;
											_this.loadingFirst = false;
											if (index == pinnedPhotos.length) {
												_this.loadingLast = false;
											} else {
												repeat();
											}
										};
									},
									error => {
										// TODO: Handle errors
										console.log('error');
										console.log(error);
									});
							}**/
						}, 10);
					})();
				} else { // No Photos
					this.loadingFirst = false;
					this.loadingLast = false;
				}
			},
			err => {
				// TODO: Handle error.
				this.loadingFirst = false;
				this.loadingLast = false;
			}
		);
	}

	imageExists(url, callback) {
		var img = new Image();
		img.onload = function () { callback(true); };
		img.onerror = function () { callback(false); };
		img.src = url;
	}

	prevImageClick(event) {
		event.stopPropagation();
		this.slider.prev();
	}

	nextImageClick(event) {
		event.stopPropagation();
		this.slider.next();
	}

	navigate(path) {
		this.router.navigate([path]);
	}
}
