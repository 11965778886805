import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridsterComponent, GridsterConfig } from 'angular-gridster2';
import { UUID } from 'angular2-uuid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { PhotoCount } from 'src/app/models/photo-count.model';
import { User } from 'src/app/models/user.model';
import { Widget } from 'src/app/models/widget.model';
import { PaymentService } from 'src/app/services/payment.service';
import { LayoutService } from '../../../services/layout.service';
import { PhotoService } from '../../../services/photo.service';
import { UserConnectionService } from '../../../services/user-connection.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
	@ViewChild('termsDialog', { static: true }) termsDialog: TemplateRef<any>;
	@ViewChild('privacyDialog', { static: true }) privacyDialog: TemplateRef<any>;
	@ViewChild('expiringPaymentMethodDialog', { static: true }) expiringPaymentMethodDialog: TemplateRef<any>;
	@ViewChild('gridster') gridster: GridsterComponent;

	// Subscriptions
	userChangedSubscription: Subscription;				// Used to retrieve the invited user as well as the current user.
	lockDashboardClickedSubscription: Subscription;		// Used to communicate to the header when the lock dashboard icon is clicked.
	unlockDashboardClickedSubscription: Subscription; 	// Used to communicate to the header when the unlock dashboard icon is clicked.
	addWidgetClickedSubscription: Subscription;			// Used to communicate to the header when the add widget link is clicked.
	resetWidgetsClickedSubscription: Subscription;		// Used to communicate to the header when the reset dashboard icon is clicked.

	// Current User
	currentUser: User;									// Store the current user details.

	// Terms of Service / Privacy Policies
	hasRequestedPolicyData: boolean = false;			// Set to true once the initial request to verify policy data is started. Ensures we don't attempt to start sequence twice.
	policiesVerified: boolean = false;					// Set to true after terms, privacy, and expiring payments methods are all verified.

	// Expiring Payment Methods
	paymentMethodExpiring = false;						// Used to display the expiring payment methods within the modal.	
	paymentMethodDaysToExpiration: number;				// Used within the expiring message.

	// Welcome Message
	userConnectionsCount: number;						// Used when determining if the welcome message should be displayed.
	userPhotosCount: PhotoCount;						// This is a json array of various counts.				
	showUserWelcomeMessage: boolean;					// If true, the welcome message will be displayed.
	userWasInvited: boolean;							// If true, an extra dialog will be displayed on the welcome message to accept invite.
	invitedUser: User;									// Used to load details about the user that invited the current user.
	welcomeMessageVerified: boolean = false;			// Set to true after it is determined if the welcome message should be displayed and if the user was invited.

	// Dashboard										// Default options for the gridster area.
	gridsterOptions: GridsterConfig = {
		draggable: { enabled: false },
		pushItems: true,
		resizable: { enabled: false },
		displayGrid: 'onDrag&Resize',
		gridType: 'fit',
		margin: 7,
		setGridSize: true,
		minCols: 18,
		maxCols: 18
	};
	dashboardAction: string;							// Used to determine if the gridster should be in edit mode.

	// Helper Vars
	modalRef: BsModalRef;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private connectionService: UserConnectionService,
		private layoutService: LayoutService,
		private modalService: BsModalService,
		private paymentService: PaymentService,
		private photoService: PhotoService,
		private userService: UserService,
		private cd: ChangeDetectorRef
	) { }

	/**
	 * INIT LOADING SEQUENCE
	 * 
	 * 	 1. Subscriptions
	 *   2. Set gridster options based on page params
	 * 	 3. If the current users data is available, set it, otherwise wait for the user subscription for the current user to start the sequence
	 *   4. Handle dashboard widget string conversion
	 *   5. Determine if policies have been verified in this session
	 *   6. If no, 
	 * 	 6a.	Verify terms of service
	 * 	 6b.	Verify privacy policy
	 * 	 6c.	Check if the user has expiring payment methods
	 *   7. Retrieve user connections count
	 *   8. Retrieve user photos count
	 *   9. Determine if the user should see the welcome message
	 *   10. If yes,
	 *   10a. 	Determine if the user was invited
	 *   11. Once we have all the data, modify the variables so the correct data is displayed (welcome message / dashboard)
	 * 
	 */
	ngOnInit() {
		// 1
		this.subscribeToUserChanged();
		this.subscribeToLockDashboardClicked();
		this.subscribeToUnlockDashboardClicked();
		this.subscribeToAddWidgetClicked();
		this.subscribeToResetWidgetsClicked();

		// 2
		this.route.queryParams.subscribe(params => {
			if (params['action']) {
				this.dashboardAction = params['action'];

				if (this.dashboardAction === 'edit') {
					if (this.gridster?.options) {
						this.gridster.options.draggable.enabled = true;
						this.gridster.options.resizable.enabled = true;
						this.gridster.optionsChanged();
					}
				} else {
					if (this.gridster?.options) {
						this.gridster.options.draggable.enabled = false;
						this.gridster.options.resizable.enabled = false;
						this.gridster.optionsChanged();
					}
				}
			}
		});

		// 3
		// User is found if
		//     The user logs in and is redirected to this page
		//     Dashboard is navigated to from another page
		// User is not found if
		//     The site is refreshed on this page
		if (this.userService.users[0]) {
			this.currentUser = this.userService.users[0];

			// 4
			let tempWidgets = [];
			if (typeof this.currentUser.widgets !== 'string') {
				tempWidgets = this.currentUser.widgets;
			} else {
				tempWidgets = JSON.parse(this.currentUser.widgets);
			}
			this.currentUser.widgets = tempWidgets;

			// 5
			// If the user wasn't found, the following actions will be handled in the user subsription for the current user.
			this.hasRequestedPolicyData = true;

			if (this.userService.policiesVerified == false) {
				// 5a
				this.verifyTermsOfService();
			} else {
				// 5b
				this.policiesVerified = true;

				// TODO: Should I be checking the welcome message every time or cacheing this as well?
				this.retrieveUserConnectionsCount();
			}
		}
	}

	ngOnDestroy() {
		// Unsubscribe
		if (this.userChangedSubscription) {
			this.userChangedSubscription.unsubscribe;
		}
		if (this.lockDashboardClickedSubscription) {
			this.lockDashboardClickedSubscription.unsubscribe;
		}
		if (this.unlockDashboardClickedSubscription) {
			this.unlockDashboardClickedSubscription.unsubscribe;
		}
		if (this.addWidgetClickedSubscription) {
			this.addWidgetClickedSubscription.unsubscribe;
		}
		if (this.resetWidgetsClickedSubscription) {
			this.resetWidgetsClickedSubscription.unsubscribe;
		}

		// Close modal if open
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}


	/**
	 * SUBSCRIPTIONS
	 */

	subscribeToUserChanged() {
		this.userChangedSubscription = this.userService.userChanged
			.subscribe((user: User) => {
				// Handle invited user
				if (this.invitedUser && this.invitedUser.id == user.id) {
					this.invitedUser = user;
					this.invitedUser.initials = user.firstName.charAt(0) + user.lastName.charAt(0);
					if (user.imageSafeUrl) {
						this.invitedUser.imageSafeUrl = user.imageSafeUrl;
					}
				}

				// Handle current User
				// Fires if 
				//   The site is refreshed on this page
				//   The user logs in and is redirected to this page
				// Does not fire if
				//   Dashboard is navigated to from another page
				if (user.id == this.userService.getLocalUserId(0)) {
					this.currentUser = user;

					let tempWidgets = [];
					if (typeof user.widgets !== 'string') {
						tempWidgets = user.widgets;
					} else {
						tempWidgets = JSON.parse(user.widgets);
					}
					this.currentUser.widgets = tempWidgets;

					// 5
					// This should only happen once
					if (!this.hasRequestedPolicyData) {
						this.hasRequestedPolicyData = true;

						if (this.userService.policiesVerified == false) {
							// 5a
							this.verifyTermsOfService();
						} else {
							// 5b
							this.policiesVerified = true;

							// TODO: Should I be checking the welcome message every time or cacheing this as well?
							this.retrieveUserConnectionsCount();
						}
					}
				}
			});
	}

	subscribeToUnlockDashboardClicked() {
		this.unlockDashboardClickedSubscription = this.layoutService.unlockDashboardClicked
			.subscribe(() => {
				this.unlockDashboard();
			});
	}

	subscribeToLockDashboardClicked() {
		this.lockDashboardClickedSubscription = this.layoutService.lockDashboardClicked
			.subscribe(() => {
				this.lockDashboard();
			});
	}

	subscribeToAddWidgetClicked() {
		this.addWidgetClickedSubscription = this.layoutService.addWidgetClicked
			.subscribe((value: any) => {
				this.addWidget(value);
			});
	}

	subscribeToResetWidgetsClicked() {
		this.resetWidgetsClickedSubscription = this.layoutService.resetWidgetsClicked
			.subscribe(() => {
				this.resetWidgets();
			});
	}


	/**
	 * PRIVACY POLICY / TERMS OF SERVICE
	 * 
	 * 1. Check if the user has already agreed to the latest terms of service
	 * 1a. If no, open the verify modal
	 * 1b. If yes, check privacy policy
	 * 2. Check if the user has already agreed to the latest privacy policy
	 * 2a. If no, open the verify modal
	 * 2b. If yes, check payment methods
	 * 3. Check if the user has expiring payment methods
	 * 
	 */

	verifyTermsOfService() {
		this.userService.verifyTermsOfService().subscribe(
			response => {
				if (response.status === 200) {
					if (response.body == true) {
						this.verifyPrivacyPolicy();
					} else {
						this.openModal(this.termsDialog, 'lg');
					}
				} else {
					this.verifyPrivacyPolicy();
				}
			},
			err => {
				this.verifyPrivacyPolicy();
			}
		);
	}

	acknowledgeTermsOfService() {
		this.userService.acknowledgeTermsOfService().subscribe(
			response => {
				if (response == true) {
					this.closeModal();
					this.verifyPrivacyPolicy();
				} else {
					this.closeModal();
					this.verifyPrivacyPolicy();
				}
			},
			err => {
				this.closeModal();
				this.verifyPrivacyPolicy();
			}
		);
	}

	bypassTermsOfService() {
		this.closeModal();
		this.verifyPrivacyPolicy();
	}

	verifyPrivacyPolicy() {
		this.userService.verifyPrivacyPolicy().subscribe(
			response => {
				if (response.status === 200) {
					if (response.body == true) {
						this.checkExpiringPaymentMethods();
					} else {
						this.openModal(this.privacyDialog, 'lg');
					}
				} else {
					this.checkExpiringPaymentMethods();
				}
			},
			err => {
				this.checkExpiringPaymentMethods();
			}
		);
	}

	acknowledgePrivacyPolicy() {
		this.userService.acknowledgePrivacyPolicy().subscribe(
			response => {
				if (response == true) {
					this.closeModal();
					this.checkExpiringPaymentMethods();
				} else {
					this.closeModal();
					this.checkExpiringPaymentMethods();
				}
			},
			err => {
				this.closeModal();
				this.checkExpiringPaymentMethods();
			}
		);
	}

	bypassPrivacyPolicy() {
		this.closeModal();
		this.checkExpiringPaymentMethods();
	}

	checkExpiringPaymentMethods() {
		if (this.userService.users[0].paymentMethodId) {
			// Payment methods exist, check for expiring within 60 days
			let now = new Date(); // 5/13/22
			let expireDate = new Date(this.userService.users[0].paymentMethodCcExpirationYear, this.userService.users[0].paymentMethodCcExpirationMonth, 0);

			this.paymentMethodDaysToExpiration = this.numDaysBetween(now, expireDate);
			if (this.paymentMethodDaysToExpiration < 60) {
				this.paymentMethodExpiring = true;
				this.checkExpiringPaymentMethodFinish();
			} else {
				this.checkExpiringPaymentMethodFinish();
			}
		} else {
			// No payment methods
			this.checkExpiringPaymentMethodFinish();
		}
	}

	checkExpiringPaymentMethodFinish() {
		if (this.paymentMethodExpiring) {
			this.openModal(this.expiringPaymentMethodDialog, 'md payment-methods');
		} else {
			this.policiesVerified = true;
			this.userService.policiesVerified = true;

			this.retrieveUserConnectionsCount();

		}
	}

	bypassExpiringPaymentMethod() {
		this.closeModal();

		this.policiesVerified = true;
		this.userService.policiesVerified = true;

		this.retrieveUserConnectionsCount();

	}


	/**
	 * USER WELCOME MESSAGE
	 */

	retrieveUserConnectionsCount() {
		if (this.connectionService.connectionsCount == undefined) {
			this.connectionService.getUserConnectionCount().subscribe(
				response => {
					if (response.status === 200) {
						this.userConnectionsCount = response.body;
						this.connectionService.setAndAnnounceUserConnectionCount(response.body);

						this.retrieveUserPhotosCount();
					}
				}
			);
		} else {
			this.userConnectionsCount = this.connectionService.connectionsCount;

			this.retrieveUserPhotosCount();
		}
	}

	retrieveUserPhotosCount() {
		if (this.photoService.userPhotoCount == undefined) {
			this.photoService.getTotalPhotoCount().subscribe(
				response => {
					if (response.status === 200) {
						this.userPhotosCount = response.body;
						this.photoService.setAndAnnounceUserPhotoCount(response.body);

						this.verifyWelcomeMessage();
					}
				}
			);
		} else {
			this.userPhotosCount = this.photoService.userPhotoCount;

			this.verifyWelcomeMessage();
		}
	}

	// Show the welcome message if the user has no photos or connections
	verifyWelcomeMessage() {
		if (this.userPhotosCount.myPhotos == 0 && this.userConnectionsCount == 0) {
			// No photos or connections, user was not invited.
			// Show the welcome message.
			this.showUserWelcomeMessage = true;
			this.userWasInvited = false;
			this.layoutService.announceShowUserWelcomeMessage(true);

			// Show Welcome Message without Invite
			this.welcomeMessageVerified = true;
		} else if (this.userPhotosCount.myPhotos == 0 && this.userConnectionsCount == 1) {
			// No photos but 1 connection. User was potentially invited.
			this.userService.checkIfInvited().subscribe(
				response => {
					if (response.body !== 0) {
						// Invited
						this.showUserWelcomeMessage = true;
						this.userWasInvited = true;
						this.layoutService.announceShowUserWelcomeMessage(true);

						// Retrieve invited user, which will trigger the user subscription to set the user locally.
						this.invitedUser = new User;
						this.invitedUser.id = response.body;

						this.userService.getUserByUserId(response.body, null);

						// Show Welcome Message with Invite
						this.welcomeMessageVerified = true;
					} else {
						// Not invited
						this.showUserWelcomeMessage = false;
						this.userWasInvited = false;
						this.layoutService.announceShowUserWelcomeMessage(false);

						// Show Dashboard
						this.welcomeMessageVerified = true;
					}
				}
			);
		} else {
			// Has photos or connections
			this.showUserWelcomeMessage = false;
			this.userWasInvited = false;
			this.layoutService.announceShowUserWelcomeMessage(false);

			// Show Dashboard
			this.welcomeMessageVerified = true;
		}
	}


	/**
	 * DASHBOARD
	 */

	lockDashboard() {
		this.userService.updateDashboardWidgets(this.currentUser.widgets).subscribe(
			(pageResponse: any) => {
				this.router.navigate(['/dashboard/'], { queryParams: { action: 'view' } });
			},
			(err) => {
				console.log(err);
			});
	}

	unlockDashboard() {
		this.router.navigate(['/dashboard/'], { queryParams: { action: 'edit' } });
	}

	addWidget(type: string) {
		const layoutItemId = UUID.UUID();
		this.currentUser.widgets.push({
			title: 'Widget Title',
			showTitle: true,
			type: type,
			data: '',
			settings: { horizontalWidgetPadding: 5, verticalWidgetPadding: 5 },
			cols: 5,
			id: layoutItemId,
			rows: 5,
			x: 0,
			y: 0
		});
	}

	deleteWidget(widget: Widget) {
		const item = this.currentUser.widgets.find(d => d.id === widget.id);
		this.currentUser.widgets.splice(this.currentUser.widgets.indexOf(item), 1);
	}

	resetWidgets() {
		this.userService.resetDashboardWidgets().subscribe((response) => {
			this.currentUser.widgets = response;
			this.userService.users[0].widgets = this.currentUser.widgets;
		});
	}


	/**
	 * USER INVITATIONS
	 */

	acceptInvitation() {
		// Approve connection request
		this.connectionService.approveUserConnection(this.invitedUser.uuid).subscribe(
			response => {
				// Show the dashboard
				this.showUserWelcomeMessage = false;
			}
		);
	}

	rejectInvitation() {
		// Decline connection request
		this.connectionService.rejectUserConnection(this.invitedUser.uuid).subscribe(
			response => {
				// Show the two column layout
				this.showUserWelcomeMessage = true;
				this.userWasInvited = false;

				this.connectionService.connectionsCount = this.connectionService.connectionsCount - 1;
			}
		);
	}


	/**
	 * HELPER UTILS
	 */

	navigate(route: string) {
		this.router.navigate([route]);
	}

	navigateToManagePaymentMethod() {
		if (this.modalRef) {
			this.closeModal();
		}

		this.policiesVerified = true;
		this.userService.policiesVerified = true;

		this.router.navigate(['profile/'], { queryParams: { tab: 'payment' } });
	}

	openModal(template: TemplateRef<any>, size: string) {
		this.modalRef = this.modalService.show(
			template,
			{ class: 'modal-' + size, backdrop: 'static' }
		);
	}

	closeModal() {
		this.modalRef.hide();
	}

	// Used for checking credit card expirations.
	numDaysBetween(d1, d2) {
		var diff = Math.abs(d1.getTime() - d2.getTime());
		return diff / (1000 * 60 * 60 * 24);
	}
}
