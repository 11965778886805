<div *ngIf="!showLogin" class="main-container" [ngClass]="{'private-mode': privateModeEnabled}">
	<app-header></app-header>
	<div class="content-region">
		<div class="container">
			<ng-container *ngTemplateOutlet="routerTemplate"></ng-container>
		</div>
	</div>
</div>

<div *ngIf="showLogin" [@fadeAnimation]="prepareRoute(outlet)" class="scroll-container">
	<ng-container *ngTemplateOutlet="routerTemplate"></ng-container>
</div>

<ng-template #routerTemplate>
	<router-outlet #outlet="outlet" (deactivate)="onDeactivate()"></router-outlet>
</ng-template>

<div class="dialog-container">
	<!-- Photo - Upload Dialog -->
	<div *ngIf="photoUploads.length > 0" class="event-photos-add-container" [ngClass]="{'expanded': uploadPhotosExpanded}">
		<div *ngIf="!uploadPhotosExpanded" class="expand-container" (click)="uploadPhotosExpanded=!uploadPhotosExpanded">
			Expand
		</div>
		<div *ngIf="uploadPhotosExpanded" class="expand-container" (click)="uploadPhotosExpanded=!uploadPhotosExpanded">
			Collapse
		</div>
		<div class="photos-container">
			<app-upload-photo-progress *ngFor="let photoUpload of photoUploads" [photoUpload]="photoUpload" (finished)="handleUploadPhotoFinished($event)"></app-upload-photo-progress>
		</div>
		<div class="photos-overview-container" [ngClass]="{'success': processedUploadQueueNumber == photoUploads.length}">
			<span *ngIf="processedUploadQueueNumber < photoUploads.length">Uploading {{ processedUploadQueueNumber }} of {{ photoUploads.length }} photos...</span>
			<span *ngIf="processedUploadQueueNumber == photoUploads.length && photoUploads.length > 1">Finished uploading {{ photoUploads.length }} photos</span>
			<span *ngIf="processedUploadQueueNumber == photoUploads.length && photoUploads.length == 1">Finished uploading {{ photoUploads.length }} photo</span>
			<i *ngIf="uploadsProcessing > 0" class="fas fa-circle-notch fa-spin"></i>
			<i *ngIf="assignedUploadQueueNumber > 0 && assignedUploadQueueNumber == processedUploadQueueNumber && uploadsProcessing == 0" class="fas fa-check" (click)="acknowledgeUploads()"></i>
		</div>
	</div>

	<!-- Event - Add Existing Photos to Event Dialog -->
	<div *ngIf="addEventPhotos.length > 0" class="event-photos-add-container" [ngClass]="{'expanded': addEventPhotosExpanded}">
		<div *ngIf="!addEventPhotosExpanded" class="expand-container" (click)="addEventPhotosExpanded=!addEventPhotosExpanded">
			Expand
		</div>
		<div *ngIf="addEventPhotosExpanded" class="expand-container" (click)="addEventPhotosExpanded=!addEventPhotosExpanded">
			Collapse
		</div>
		<div class="photos-container">
			<app-add-photo-to-event-progress *ngFor="let eventPhotoAdd of addEventPhotos" [eventPhotoAdd]="eventPhotoAdd" (finished)="handleEventPhotoAddFinished($event)"></app-add-photo-to-event-progress>
		</div>
		<div class="photos-overview-container" [ngClass]="{'success': processedAddEventPhotosQueueNumber == addEventPhotos.length}">
			<span *ngIf="processedAddEventPhotosQueueNumber < addEventPhotos.length">Adding {{ processedAddEventPhotosQueueNumber }} of {{ addEventPhotos.length }} photos...</span>
			<span *ngIf="processedAddEventPhotosQueueNumber == addEventPhotos.length && addEventPhotos.length > 1">Finished adding {{ addEventPhotos.length }} photos to event</span>
			<span *ngIf="processedAddEventPhotosQueueNumber == addEventPhotos.length && addEventPhotos.length == 1">Finished adding {{ addEventPhotos.length }} photo to event</span>
			<i *ngIf="addEventPhotosProcessing > 0" class="fas fa-circle-notch fa-spin"></i>
			<i *ngIf="assignedAddEventPhotosQueueNumber > 0 && assignedAddEventPhotosQueueNumber == processedAddEventPhotosQueueNumber && addEventPhotosProcessing == 0" class="fas fa-check" (click)="acknowledgeEventPhotosAdd()"></i>
		</div>
	</div>

	<!-- Event - Remove Existing Photos from Event Dialog -->
	<div *ngIf="removeEventPhotos.length > 0" class="event-photos-add-container" [ngClass]="{'expanded': removeEventPhotosExpanded}">
		<div *ngIf="!removeEventPhotosExpanded" class="expand-container" (click)="removeEventPhotosExpanded=!removeEventPhotosExpanded">
			Expand
		</div>
		<div *ngIf="removeEventPhotosExpanded" class="expand-container" (click)="removeEventPhotosExpanded=!removeEventPhotosExpanded">
			Collapse
		</div>
		<div class="photos-container">
			<app-remove-photo-from-event-progress *ngFor="let eventPhotoRemove of removeEventPhotos" [eventPhotoRemove]="eventPhotoRemove" (finished)="handleEventPhotoRemoveFinished($event)"></app-remove-photo-from-event-progress>
		</div>
		<div class="photos-overview-container" [ngClass]="{'success': processedRemoveEventPhotosQueueNumber == removeEventPhotos.length}">
			<span *ngIf="processedRemoveEventPhotosQueueNumber < removeEventPhotos.length">Removing {{ processedRemoveEventPhotosQueueNumber }} of {{ removeEventPhotos.length }} photos...</span>
			<span *ngIf="processedRemoveEventPhotosQueueNumber == removeEventPhotos.length">Finished removing {{ removeEventPhotos.length }} photos</span>
			<i *ngIf="removeEventPhotosProcessing > 0" class="fas fa-circle-notch fa-spin"></i>
			<i *ngIf="assignedRemoveEventPhotosQueueNumber > 0 && assignedRemoveEventPhotosQueueNumber == processedRemoveEventPhotosQueueNumber && removeEventPhotosProcessing == 0" class="fas fa-check" (click)="acknowledgeEventPhotosRemove()"></i>
		</div>
	</div>
</div>