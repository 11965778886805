<div class="header" id="top">
	<div class="cell-logo"><img src="assets/images/logo-white.png" class="logo" (click)="navigate('')" /></div>
	<!--<div class="cell-1">Privacy Policy</div>-->
	<!--<div class="cell-2">Terms of Service</div>-->
	<!--<div class="cell-3">Style Guide</div>-->
</div>

<div class="content">
	<div class="toc">
		<div class="last-updated">Last updated<br />July 23, 2024</div>
		<div class="red"><a href="#top" (click)="navigate('blog/01')">1. Introductions</a></div>
		<div class="orange"><a href="#top" (click)="navigate('blog/02')">2. From the beginning</a></div>
		<div class="yellow active"><a href="#top" (click)="navigate('blog/03')">3. The first year</a></div>
		<div class="lightgreen"><a href="#top" (click)="navigate('blog/04')">4. Initial cloud hosting</a></div>
		<div class="green"><a href="#top" (click)="navigate('blog/05')">5. UI/UX</a></div>
		<div class="cyan"><a href="#top" (click)="navigate('blog/06')">6. Performance tuning</a></div>
		<!-- <div class="blue"><a href="#top" (click)="navigate('blog/07')">7. Mental health</a></div>
		<div class="darkblue"><a href="#top" (click)="navigate('blog/08')">8. Funding vs bootstrapping</a></div>
		<div class="violet"><a href="#top" (click)="navigate('blog/09')">9. What's next</a></div> -->
	</div>
	<div>
		<div class="banner yellow">
			<div class="title">Photonomy thoughts and insights:<br /><span>The first year</span></div>
		</div>
		<div class="text-container">
			<!-- <p>The first year is the easiest. There is no burn-out, there are plenty of ideas, and you have hit minimal
				roadblocks.
			</p> -->
			<p class="title first">Choosing a tech stack</p>
			<p>When embarking on a new project as a solo developer, one of the first decisions you’ll face is picking
				your technology stack. This choice usually comes down two considerations:</p>
			<ul>
				<li>Speed: If you’re looking to move fast and get a working prototype or MVP up and running, it’s
					best to stick with technologies you already know.</li>
				<li>Learning: If your goal is to learn and grow as a developer, pick any stack that interest or
					excites you, as most stacks can get the job done.</li>
			</ul>
			<p>I prioritized speed and chose
				<a href="https://angular.dev/" target="_blank">Angular <i class="fas fa-external-link-alt"></i></a>
				for the front-end,
				<a href="https://spring.io/projects/spring-boot" target="_blank">Java Spring Boot <i class="fas fa-external-link-alt"></i></a>
				for the back-end, and
				<a href="https://www.h2database.com" target="_blank">H2 <i class="fas fa-external-link-alt"></i></a>
				for the local database. I setup
				<a href="https://github.com/" target="_blank">Github <i class="fas fa-external-link-alt"></i></a>
				repositories to store the code and
				<a href="https://www.atlassian.com/software/jira" target="_blank">JIRA <i class="fas fa-external-link-alt"></i></a>
				to manage tickets and prioritize the work.
				These are all technologies I’m comfortable with, allowing me to develop quickly and efficiently.
			</p>

			<p class="title">The initial build</p>
			<p> My primary goal was to get something functional up and running as soon as possible, so I could
				start gathering feedback and iterating on the idea. I focused on developing features that interested
				me at the time, without getting bogged down by an extensive list of initial requirements. This approach
				helped me maintain momentum and avoid burnout. I knew that if I overloaded myself with too many
				features and complex designs from the get-go, I might hit that initial barrier that every project
				goes through and stall the project.</p>

			<p class="title">Validating the idea</p>
			<p>Throughout the development process, I made a point to talk with friends and family to validate the
				idea, as I always knew these people would be my initial target customers. These conversations provided
				insights into what they liked and disliked about existing social media that they used every day.
				Everyone had strong opinions, so it was not difficult to gather feedback.</p>

			<p class="title">Minimizing the scope</p>
			<p>Over the course of the first year, I dedicated myself to only working on the following core features:</p>
			<ul>
				<li>Authentication: Creating accounts and logging in securely</li>
				<li>Adding photos: Uploading or importing photos from existing social media</li>
				<li>Viewing photos: Viewing and interacting with uploaded photos</li>
				<li>Basic metadata: Adding simple metadata to help organize and search content</li>
			</ul>
			<p>I had a thousand ideas of features that I wanted to build, but I added these as future JIRA tickets and
				returned to working on the core.
			</p>

			<p>As with any side project, balancing this project with my other responsibilities was challenging.
				I dedicated nights and weekends to coding, averaging about 40 hours per month. Over the course of
				the next year, I spent 519 hours on the project. I often showed off the capabilities to hold myself
				accountable and use as motivation.</p>

			<p>One of the first year activities that we enjoyed most was importing pictures from friends social media
				accounts, often turning up memorable moments of the past. Seeing people's reactions was the point I knew
				I was onto something. The existing sites are all meant to showcase the now, and not the past.</p>

			<p class="title">First year reflections</p>

			<p>After a year of development, I took a step back and decided a more structured approach would be beneficial
				for making further progress. I took a break from development and spent time documenting and estimating
				features. I created the assets that would normally be created first in a more structured environment,
				such as design documents and architecture diagrams. I created pitch decks and roadmaps.</p>

			<p>This reflection period helped me understand what worked well and what could be improved. It also provided an
				opportunity to celebrate the progress I had made and properly plan the next steps for the platform.</p>
		</div>
	</div>
</div>
<app-footer></app-footer>