<div class="comment-container" [ngClass]="{'system': !eventComment.userId, 'inner': inner, 'liked': eventComment.reactionsLike.length > 0 || eventComment.reactionsLove.length > 0 || eventComment.reactionsSad.length > 0 || eventComment.reactionsLaugh.length > 0 || eventComment.reactionsAngry.length > 0 }">
	<div class="reactions-container">
		<div *ngIf="eventComment.reactionsLike.length > 0" class="reaction-container" [ngClass]="{'active': hasLikeReaction }">
			<i class="fas fa-thumbs-up" [ngStyle]="{'color': hasLikeReaction ? secondaryColor : null}"></i> <span class="number" [ngStyle]="{'color': hasLikeReaction ? secondaryColor : null}">{{ eventComment.reactionsLike.length }}</span>
		</div>
		<div *ngIf="eventComment.reactionsLove.length > 0" class="reaction-container" [ngClass]="{'active': hasLoveReaction }">
			<i class="fas fa-heart" [ngStyle]="{'color': hasLoveReaction ? secondaryColor : null}"></i> <span class="number" [ngStyle]="{'color': hasLoveReaction ? secondaryColor : null}">{{ eventComment.reactionsLove.length }}</span>
		</div>
		<div *ngIf="eventComment.reactionsSad.length > 0" class="reaction-container" [ngClass]="{'active': hasSadReaction }">
			<i class="fas fa-heart-broken" [ngStyle]="{'color': hasSadReaction ? secondaryColor : null}"></i> <span class="number" [ngStyle]="{'color': hasSadReaction ? secondaryColor : null}">{{ eventComment.reactionsSad.length }}</span>
		</div>
		<div *ngIf="eventComment.reactionsLaugh.length > 0" class="reaction-container" [ngClass]="{'active': hasLaughReaction }">
			<i class="fas fa-laugh-beam" [ngStyle]="{'color': hasLaughReaction ? secondaryColor : null}"></i> <span class="number" [ngStyle]="{'color': hasLaughReaction ? secondaryColor : null}">{{ eventComment.reactionsLaugh.length }}</span>
		</div>
		<div *ngIf="eventComment.reactionsAngry.length > 0" class="reaction-container" [ngClass]="{'active': hasAngryReaction }">
			<i class="fas fa-angry" [ngStyle]="{'color': hasAngryReaction ? secondaryColor : null}"></i> <span class="number" [ngStyle]="{'color': hasAngryReaction ? secondaryColor : null}">{{ eventComment.reactionsAngry.length }}</span>
		</div>
	</div>
	<div class="header-container">
		<i class="fas fa-circle-notch fa-spin" *ngIf="loadingUser"></i>
		<ng-container *ngIf="!eventComment.userId && !loadingUser">
			<img src="/assets/images/logo-icon.png" class="logo" />
		</ng-container>
		<ng-container *ngIf="eventComment.userId > 0 && !user?.imageSafeUrl && !loadingUser">
			<div class="user-initials">{{ userInitials }}</div>
		</ng-container>
		<ng-container *ngIf="eventComment.userId > 0 && user?.imageSafeUrl && !loadingUser">
			<img [src]="user.imageSafeUrl" class="image" />
		</ng-container>
		<div *ngIf="!loadingUser && user?.firstName" class="name">{{ user.firstName }} {{ user.lastName }}</div>
		<div *ngIf="!loadingUser && !user?.firstName" class="name">Vault Message</div>
		<div *ngIf="!loadingUser" class="time" [title]="eventComment.date | date:'full'">{{ timeInPast }}</div>
	</div>
	<div class="comment">{{ eventComment.comment }}</div>
	<div class="actions-container" *ngIf="eventComment.userId">
		<div *ngIf="eventComment.replies.length > 0 && !showReplies" class="show-conversation-container">
			<a class="show" (click)="showReplies = true;">Show {{ eventComment.replies.length }} replies...</a>
		</div>
		<div class="reaction-select-container" *ngIf="showReactionContainer">
			<a class="like" (click)="showReactionContainer = false">Cancel</a>
			<span class="reaction"><i class="fas fa-thumbs-up" (click)="addReactionLike()"></i></span>
			<span class="reaction"><i class="fas fa-heart" (click)="addReactionLove()"></i></span>
			<span class="reaction"><i class="fas fa-heart-broken" (click)="addReactionSad()"></i></span>
			<span class="reaction"><i class="fas fa-laugh-beam" (click)="addReactionLaugh()"></i></span>
			<span class="reaction"><i class="fas fa-angry" (click)="addReactionAngry()"></i></span>
		</div>
		<div *ngIf="!showReactionContainer">
			<a class="like" *ngIf="!hasLikeReaction && !hasLoveReaction && !hasAngryReaction && !hasSadReaction && !hasLaughReaction" (click)="addReaction()">Like</a>

			<a class="like" *ngIf="hasLikeReaction" (click)="removeReactionLike()">Unlike</a>
			<a class="like" *ngIf="hasLoveReaction" (click)="removeReactionLove()">Unlike</a>
			<a class="like" *ngIf="hasAngryReaction" (click)="removeReactionAngry()">Unlike</a>
			<a class="like" *ngIf="hasSadReaction" (click)="removeReactionSad()">Unlike</a>
			<a class="like" *ngIf="hasLaughReaction" (click)="removeReactionLaugh()">Unlike</a>

			<a class="reply" *ngIf="!showReplyContainer && !inner" (click)="addReply()">Reply</a>
			<a class="reply" *ngIf="showReplyContainer" (click)="cancelReply()">Cancel Reply</a>
			<!-- <a class="edit" (click)="editComment()">Edit</a> -->
		</div>
	</div>

	<div class="reply-container" [ngClass]="{'hidden': !showReplyContainer}">
		<form #f="ngForm" [formGroup]="replyForm" class="form">
			<i class="fas fa-comment comment-button" (click)="addReplySubmit()"></i>
			<input id="reply" type="text" class="form-control comment-input" maxlength="500" formControlName="reply" placeholder="Submit a reply" />
		</form>
	</div>
	<div class="replies-container" *ngIf="eventComment.replies.length > 0 && showReplies">
		<ng-container *ngFor="let reply of eventComment.replies">
			<app-event-comment [eventComment]="reply" [showReplies]="showReplies" [inner]="true" [primaryColor]="primaryColor" [secondaryColor]="secondaryColor"></app-event-comment>
		</ng-container>
	</div>
	<div class="hide-replies-container" *ngIf="showReplies && !inner"><a (click)="showReplies = false;">Hide replies...</a></div>
</div>