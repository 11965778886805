<div class="left-container" [ngClass]="{'expanded': leftContainerExpanded, 'collapsed': !leftContainerExpanded}">
	<div class="icon-container">
		<i *ngIf="userService.users[0]?.hasAcknowledgedExploreHints" class="fas fa-filter" [ngClass]="{'unselected': displayGallerySidebar || displayUpdateSidebar }" (click)="showFiltersSidebar()" title="Search Options" placement="right"></i>
		<i *ngIf="!userService.users[0]?.hasAcknowledgedExploreHints" class="fas fa-filter unselected disabled" title="Search Options" placement="right"></i>
		<div class="explore-tooltip-container" *ngIf="!loading && !userService.users[0]?.hasAcknowledgedExploreHints">
			<div class="text">Use the <strong>Explore</strong> page find, view, and tag your photos.</div>
			<div class="icon">
				<div><i class="fas fa-filter"></i></div>
				<div>The filter icon is used to apply search options.</div>
			</div>
			<div class="icon">
				<div><i class="fas fa-save"></i></div>
				<div>The save icon is used to create galleries based on your current search options.</div>
			</div>
			<div class="button"><button class="btn btn-primary" (click)="acknowledgeExploreHints()">Got it!</button></div>
		</div>
		<i *ngIf="userService.users[0]?.hasAcknowledgedExploreHints" class="fas fa-save" [ngClass]="{'unselected': displayFiltersSidebar || displayUpdateSidebar }" title="Save as Gallery" (click)="showGallerySidebar()"></i>
		<i *ngIf="!userService.users[0]?.hasAcknowledgedExploreHints" class="fas fa-save unselected disabled" title="Save as Gallery"></i>
	</div>

	<!-- Search Filter Panel -->
	<div class="content-container search-options-active" [ngClass]="{'hidden': !displayFiltersSidebar }">
		<div class="title">Search Options</div>
		<div class="fields sm" [ngClass]="{'loading in': loadingFilter }">
			<form #f="ngForm" [formGroup]="filterForm" class="form">
				<div class="filter">
					<label>Explore the following connections photos:</label>
					<span class="connection-select user current" [tooltip]="currentUser?.firstName + ' ' + currentUser?.lastName" placement="bottom">
						<div *ngIf="currentUser?.imageSafeUrl"><img [src]="currentUser?.imageSafeUrl" class="initials image" /></div>
						<div *ngIf="!currentUser?.imageSafeUrl">{{ currentUser?.firstName.charAt(0) }}{{ currentUser?.lastName.charAt(0)}}</div>
					</span>
					<ng-container *ngFor="let user of connectionUsersSelected">
						<app-explore-connection-icon [user]="user" [defaultPhotoOwnerUserIdsArray]="defaultPhotoOwnerUserIdsArray" (emitUnselectOwner)="unselectUser($event)"></app-explore-connection-icon>
					</ng-container>

					<div *ngIf="!displayAllConnections"><a class="select-more" (click)="displayAllConnections = true" *ngIf="connectionUsersUnselected.length > 0">Select more connections</a></div>
					<ng-container *ngIf="displayAllConnections">
						<ng-container *ngFor="let user of connectionUsersUnselected">
							<app-explore-connection-icon [user]="user" [defaultPhotoOwnerUserIdsArray]="defaultPhotoOwnerUserIdsArray" (emitSelectOwner)="selectUser($event)"></app-explore-connection-icon>
						</ng-container>
					</ng-container>
					<div *ngIf="displayAllConnections"><a class="select-more" (click)="displayAllConnections = false">Finished selecting connections</a></div>
				</div>
				<div class="filter">
					<!-- Folder -->
					<div class="form-group odd">
						<div class="folder-expand" *ngIf="folderSelectorCollapsed"><a (click)="expandFolderSelector()">Expand</a></div>
						<div class="folder-expand" *ngIf="!folderSelectorCollapsed"><a (click)="collapseFolderSelector()">Collapse</a></div>
						<!-- Label -->
						<label for="folder" class="keywords-label">
							Folder:
						</label>
						<!-- Collapsed container -->
						<div class="folder-container collapsed" *ngIf="folderSelectorCollapsed">
							<span *ngIf="selectedFolderId > -1" class="clear-folder" (click)="unselectFolder($event)">×</span>
							<div *ngIf="selectedFolderId == -1" class="blank" (click)="folderSelectorCollapsed = false"></div>
							<div *ngIf="selectedFolderId > -1" class="selected" (click)="folderSelectorCollapsed = false">{{ selectedFolderName }}</div>
						</div>
						<!-- Expanded container -->
						<div class="folder-container" [ngClass]="{'hidden': folderSelectorCollapsed}">
							<div *ngIf="selectedFolderId == -1" class="blank" (click)="folderSelectorCollapsed = true"></div>
							<div *ngIf="selectedFolderId > -1" class="selected" (click)="folderSelectorCollapsed = true">{{ selectedFolderName }}</div>
							<div *ngIf="selectedFolderId > -1" (click)="unselectFolder($event)"></div>
							<div [ngClass]="{'strong': selectedFolderId == 0}" (click)="selectNoFolder()">Photos with no folder</div>

							<!-- List folders -->
							<ng-container *ngFor="let folder of folders">
								<div (click)="selectFolder(folder)">
									<i class="fas fa-angle-right" *ngIf="expandedRootId !== folder.id" [ngClass]="{'disabled': !folder.folders || folder.folders?.length == 0}" (click)="expandFolder($event, folder)"></i>
									<i class="fas fa-angle-down" *ngIf="expandedRootId == folder.id" (click)="collapseFolder($event, folder.id)"></i>
									<i class="fas fa-folder"></i>
									<span [ngClass]="{'strong': selectedFolderId == folder.id}">{{ folder.name }}</span>
								</div>
								<!-- List Sub-folders -->
								<ng-container *ngIf="expandedRootId == folder.id">
									<ng-container *ngFor="let subfolder of folder.folders">
										<div class="subfolder" (click)="selectFolder(subfolder)">
											<i class="fas fa-folder"></i>
											<span [ngClass]="{'strong': selectedFolderId == subfolder.id}">{{ subfolder.name }}</span>
										</div>
									</ng-container>
								</ng-container>
							</ng-container>
						</div>
					</div>
				</div>
				<div class="filter">
					<label>People:</label>
					<ng-select #peopleSelect class="typeahead" [items]="people" [clearable]="true" [multiple]="true" bindLabel="name" bindValue="name" id="people" formControlName="people" (change)="filterChanged()">
					</ng-select>
				</div>
				<div class="filter">
					<label>Location:</label>
					<ng-select #locationsSelect class="typeahead" [items]="locations" [clearable]="true" [multiple]="false" bindLabel="name" bindValue="name" id="locations" formControlName="locations" (change)="filterChanged()">
					</ng-select>
				</div>
				<div class="filter"><label>Captured Date:</label>
					<input bsDatepicker [bsConfig]="{ dateInputFormat: 'MMMM DD, YYYY' }" type="text" [value]="capturedDate" id="capturedDate" formControlName="capturedDate" class="form-control date" placement="top middle" [maxDate]="maxDate" autocomplete="off" (ngModelChange)="filterChanged()" />
				</div>
				<div class="filter">
					<label>Keywords:</label>
					<ng-select #keywordsSelect class="typeahead" [items]="keywords" [clearable]="true" [multiple]="true" bindLabel="name" bindValue="id" id="keywords" formControlName="keywords" (change)="filterChanged()">
					</ng-select>
				</div>
				<div class="filter">
					<label>Event:</label>
					<ng-select #eventSelect class="typeahead" [items]="events" [clearable]="true" [multiple]="false" bindLabel="name" bindValue="id" id="events" formControlName="events" (change)="filterChanged()">
					</ng-select>
				</div>
				<div class="filter">
					<label>Only show uncategorized photos:</label>
					<div class="slider-container">
						<label class="switch">
							<input #showOnlyUncategorizedPhotosSwitch id="showOnlyUncategorizedPhotosSwitch" type="checkbox" [checked]="showOnlyUncategorizedPhotos" formControlName="showOnlyUncategorizedPhotos" (change)="filterChanged()">
							<span class="slider round"></span>
						</label>
					</div>
				</div>
			</form>
		</div>
	</div>

	<!-- Save As Gallery Panel -->
	<div class="content-container" [ngClass]="{'hidden': !displayGallerySidebar }">
		<div class="title" *ngIf="!gallery?.name">Save as Gallery</div>
		<div class="title" *ngIf="gallery?.name">Update Gallery</div>
		<div class="fields sm">
			<div class="filter">
				<form #g="ngForm" [formGroup]="galleryForm" class="form">
					<label>Gallery name:</label>
					<input *ngIf="!gallery?.name" type="text" id="name" formControlName="name" class="form-control" autocomplete="off" minlength="3">
					<div *ngIf="gallery?.name" class="gallery-name">{{ gallery.name }}</div>

					<label *ngIf="peopleSelected.length > 0 || locationsSelected.length > 0 || keywordsSelected.length > 0">Gallery Filters:</label>
					<div *ngFor="let person of peopleSelected" class="selected-filter">
						{{ person }} <em class="fas fa-times-circle" (click)="removeFilter('people', person)"></em>
					</div>
					<div *ngFor="let location of locationsSelected" class="selected-filter">
						{{ location }} <em class="fas fa-times-circle" (click)="removeFilter('location', location)"></em>
					</div>
					<div *ngFor="let keyword of keywordsSelected" class="selected-filter">
						{{ keyword }} <em class="fas fa-times-circle" (click)="removeFilter('keyword', keyword)"></em>
					</div>
					<div *ngIf="eventSelected" class="selected-filter">
						{{ eventSelected.name }} <em class="fas fa-times-circle" (click)="removeFilter('event', eventSelected.name)"></em>
					</div>
					<!--
					<div *ngIf="showOnlyUncategorizedPhotos" class="selected-filter">
						Uncategorized photos
						<em class="fas fa-times-circle" (click)="removeFilter('showOnlyUncategorizedPhotos', 'showOnlyUncategorizedPhotos')"></em>
					</div>
					-->
					<!--
					<div class="filter">Display Picture For: 5 sec
					</div>
					<div class="filter">Transition Effect: Fade
					</div>
					<div class="filter">Order: Random
					</div>
					-->
					<div class="buttons" *ngIf="loadingSave">
						&nbsp;
					</div>
					<div class="buttons" *ngIf="(g.valid || gallery?.name) && !loadingSave">
						<div *ngIf="gallery?.name" (click)="updateGallery()" class="save">Update</div>
						<div *ngIf="!gallery?.name" (click)="saveGallery()" class="save">Save</div>
						<div (click)="cancelSaveGallery()" class="cancel">Cancel</div>
					</div>
				</form>
			</div>
		</div>
	</div>
	<div class="collapse-container" *ngIf="leftContainerExpanded">
		<div class="inner" (click)="collapseSidebar()"><i class="fas fa-caret-left"></i></div>
	</div>
</div>

<div>
	<!-- FILTERS WHEN TAB IS NOT SELECTED-->
	<div class="quick-filters-container">
		<!-- Vaults need work because the id is stored in the array and must be converted to a name.-->
		<div *ngFor="let person of peopleSelected" class="selected-filter">
			{{ person }} <em class="fas fa-times-circle" (click)="removeFilter('people', person)"></em>
		</div>
		<div *ngFor="let location of locationsSelected" class="selected-filter">
			{{ location }} <em class="fas fa-times-circle" (click)="removeFilter('location', location)"></em>
		</div>
		<div *ngIf="capturedDateSelected" class="selected-filter">
			{{ capturedDateSelected | date:'MMMM dd, YYYY' }} <em class="fas fa-times-circle" (click)="removeFilter('capturedDate', capturedDateSelected)"></em>
		</div>
		<div *ngFor="let keyword of keywordsSelected" class="selected-filter">
			{{ keyword }} <em class="fas fa-times-circle" (click)="removeFilter('keyword', keyword)"></em>
		</div>
		<div *ngIf="eventSelected" class="selected-filter">
			{{ eventSelected.name }} <em class="fas fa-times-circle" (click)="removeFilter('event', eventSelected.name)"></em>
		</div>
		<div *ngIf="selectedFolderName" class="selected-filter">
			{{ selectedFolderName }} <em class="fas fa-times-circle" (click)="unselectFolder($event)"></em>
		</div>
		<div *ngIf="showOnlyUncategorizedPhotos" class="selected-filter">
			Uncategorized photos
			<em class="fas fa-times-circle" (click)="removeFilter('showOnlyUncategorizedPhotos', 'showOnlyUncategorizedPhotos')"></em>
		</div>

		<div class="sort-container">
			<label>Sort by:</label>
			<form #sa="ngForm" [formGroup]="sortByForm">
				<div [ngClass]="{'hidden': userService.users[0]?.hasAcknowledgedExploreHints }">Random</div>
				<ng-select [ngClass]="{'hidden': !userService.users[0]?.hasAcknowledgedExploreHints }" #sortBySelect [disabled]="true" [clearable]="false" id="sortBy" formControlName="sortBy" (change)="filterChanged()">
					<!-- TEMPORARILY REMOVE THE RANDOM OPTION
						<ng-option value="random">Random</ng-option>
					-->
					<ng-option value="addedAsc">Added Date Asc</ng-option>
					<ng-option value="addedDesc">Added Date Desc</ng-option>
					<ng-option value="takenAsc">Taken Date Asc</ng-option>
					<ng-option value="takenDesc">Taken Date Desc</ng-option>
				</ng-select>
			</form>
		</div>
	</div>

	<div class="image-container" [ngClass]="{'loading in': loadingSearch }">
		<div class="search-images" [ngClass]="{'hidden': externalPhotos.length == 0}">
			<infinite-scroll (scrolled)="onScroll()">
				<ngx-masonry>
					<div ngxMasonryItem class="masonry-item" id="{{'masonry-item-'+i}}" *ngFor="let photo of externalPhotos; let i = index">
						<div class="photo-container">
							<div *ngIf="photo.status == 'deleted'" class="photo-message-container">
								<div>The photo has been deleted</div>
							</div>
							<img *ngIf="photo?.imageSafeUrl" [src]="photo?.imageSafeUrl" (click)="openImageLightbox(photo, i)" />
						</div>
						<app-photo-action-container [photo]="photo" [i]="i" (emitOpenPhotoModalClassify)="openPhotoDetailsModalClassify($event)" (emitOpenPhotoModalDiscuss)="openPhotoDetailsModalDiscuss($event)" (emitSetSelectedPhotoPointer)="setSelectedPhotoPointer($event)"></app-photo-action-container>
					</div>
				</ngx-masonry>
			</infinite-scroll>
		</div>

		<div class="no-images row justify-content-center g-0" *ngIf="externalPhotos.length == 0 && loadingSearch == false">
			<div class="pending-message">There are no photos that match this criteria.</div>
			<div class="button-container col-6 col-md-3 col-lg-2">
				<div class="button" (click)="navigate('photo/add/upload')">Add Photos</div>
			</div>
		</div>

	</div>
</div>

<div class="previous-container" *ngIf="showPhotoDetailsModal" (click)="navigatePreviousPhoto($event)">
	<i class="fas fa-caret-left"></i>
</div>
<ng-template #photoDetailsDialog class="modal-dialog-centered">
	<app-photo-view [modalRef]="modalRef" [modalTab]="modalTab"></app-photo-view>
</ng-template>
<div class="close-container" *ngIf="showPhotoDetailsModal" (click)="closePhotoDetailsModal()">
	<i class="fas fa-times"></i>
</div>
<div class="switch-container" *ngIf="showPhotoDetailsModal" (click)="openImageLightbox(selectedPhoto, selectedPhotoPointer)">
	<i class="far fa-image"></i>
</div>
<div class="next-container" *ngIf="showPhotoDetailsModal" (click)="navigateNextPhoto($event)">
	<i class="fas fa-caret-right"></i>
</div>

<ng-template #imageDeleteDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-exclamation-triangle"></em> Warning</h5>
	</div>
	<div class="modal-body" [ngClass]="{'loading in': loading }">
		<p class="center">You are about to delete the following network</p>
		<p class="center bold">{{ network.name }}</p>
		<p>&nbsp;</p>
		<p class="center">This will permanently remove all photos and users from this network. Continue?</p>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-danger btn-md" (click)="deleteNetworkConfirm( network.id )">Delete</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>

<ng-template #requireUserApprovalDisableDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-exclamation-triangle"></em> Warning</h5>
	</div>
	<div class="modal-body center" [ngClass]="{'loading in': loading }">
		<p>If you continue, the following network will no longer require user approval to join:</p>
		<p>&nbsp;</p>
		<p class="bold">{{ network.name }}</p>
		<p>&nbsp;</p>
		<p>Would you like to approve or reject all current user join requests?</p>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="approveRequireUserApprovalDisable(true)">Approve all</button>
		<button type="button" class="btn btn-primary btn-md" (click)="approveRequireUserApprovalDisable(false)">Reject all</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="cancelRequireUserApprovalDisable()">Cancel</button>
	</div>
</ng-template>

<ng-template #requirePhotoApprovalDisableDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-exclamation-triangle"></em> Warning</h5>
	</div>
	<div class="modal-body center" [ngClass]="{'loading in': loading }">
		<p>If you continue, the following network will no longer require photo approval:</p>
		<p>&nbsp;</p>
		<p class="bold">{{ network.name }}</p>
		<p>&nbsp;</p>
		<p>Would you like to approve or reject all current photo submissions?</p>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="approveRequirePhotoApprovalDisable(true)">Approve all</button>
		<button type="button" class="btn btn-primary btn-md" (click)="approveRequirePhotoApprovalDisable(false)">Reject all</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="cancelRequirePhotoApprovalDisable()">Cancel</button>
	</div>
</ng-template>

<div id="myModal" class="modal" *ngIf="showImageLightbox">
	<span class="helper"></span>
	<div class="previous-container" (click)="navigatePreviousPhoto($event)">
		<i class="fas fa-caret-left"></i>
	</div>
	<div class="photo-expanded-container" [style.background-image]="'url(' + selectedPhoto.imageSafeUrl + ')'">
		<!-- <img *ngIf="selectedPhoto.imageSafeUrl" [src]="selectedPhoto.imageSafeUrl" class="modal-content" /> -->
		<div id="caption">{{ selectedPhoto?.description }}</div>
	</div>
	<div class="close-container" (click)="closeImageLightbox()">
		<i class="fas fa-times"></i>
	</div>
	<div class="switch-container" *ngIf="showImageLightbox" (click)="openPhotoDetailsModalClassify(selectedPhoto, selectedPhotoPointer)">
		<i class="fas fa-file-alt"></i>
	</div>
	<div class="next-container" (click)="navigateNextPhoto($event)">
		<i class="fas fa-caret-right"></i>
	</div>
</div>