<div class="header" id="top">
	<div class="cell-logo"><img src="assets/images/logo-white.png" class="logo" (click)="navigate('')" /></div>
	<!--<div class="cell-1">Privacy Policy</div>-->
	<!--<div class="cell-2">Terms of Service</div>-->
	<!--<div class="cell-3">Style Guide</div>-->
</div>

<div class="content">
	<div class="toc">
		<div class="last-updated">Last updated<br />July 23, 2024</div>
		<div class="red"><a href="#top" (click)="navigate('blog/01')">1. Introductions</a></div>
		<div class="orange active"><a href="#top" (click)="navigate('blog/02')">2. From the beginning</a></div>
		<div class="yellow"><a href="#top" (click)="navigate('blog/03')">3. The first year</a></div>
		<div class="lightgreen"><a href="#top" (click)="navigate('blog/04')">4. Initial cloud hosting</a></div>
		<div class="green"><a href="#top" (click)="navigate('blog/05')">5. UI/UX</a></div>
		<div class="cyan"><a href="#top" (click)="navigate('blog/06')">6. Performance tuning</a></div>
		<!-- <div class="blue"><a href="#top" (click)="navigate('blog/07')">7. Mental health</a></div>
		<div class="darkblue"><a href="#top" (click)="navigate('blog/08')">8. Funding vs bootstrapping</a></div>
		<div class="violet"><a href="#top" (click)="navigate('blog/09')">9. What's next</a></div> -->
	</div>
	<div>
		<div class="banner orange">
			<div class="title">Photonomy thoughts and insights:<br /><span>From the beginning</span></div>
		</div>
		<div class="text-container">
			<p>One evening during the summer of 2020, my family and I found ourselves lamenting the current state of
				social media. We all had the same frustrations: it had become overly commercialized, with intrusive
				ads and algorithms dictating the experience.</p>
			<p>While we do often share current experiences, what we were looking for was a way to share and celebrate
				the past. A place that we can all store and access our families historical photos. An <i>ancestory
					photo vault.</i> With my experience with content and digital asset management, I decided (with the help of an
				alcoholic beverage or two) that I could write my own and take on the tech giants. </p>

			<p>We all started brainstorming. The more discussions we had, the more it was clear. This wasn't just a
				whimsical thought. The need was
				real and pressing among my immediate circle. Too often in the startup world, ideas are forced,
				seeking to create demand rather than addressing existing needs. We wanted to create something that would
				genuinely improve our lives and the lives of those around us.</p>

			<p>I embarked on this journey fully aware of the formidable giants in this space. Competing directly
				with these behemoths seemed impractical, if not impossible. We decided on a more collaborative
				approach. Our vision was to develop a service that could seamlessly plug into these existing
				platforms, acting as a companion rather than a competitor. By doing so, we could leverage the
				strengths of established services while offering a unique, experience centered on the preservation
				and sharing of family photos.</p>

			<p>That night, Photonomy was born. On the 3rd of June 2020, the initial code base was created under the code name
				apv (ancestry photo vault).</p>
		</div>
	</div>
</div>
<app-footer></app-footer>