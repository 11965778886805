<div class="header" id="top">
	<div class="cell-logo"><img src="assets/images/logo-white.png" class="logo" (click)="navigate('')" /></div>
	<!--<div class="cell-1">Privacy Policy</div>-->
	<!--<div class="cell-2">Terms of Service</div>-->
	<!--<div class="cell-3">Style Guide</div>-->
</div>

<div class="content">
	<div class="toc">
		<div class="last-updated">Last updated<br />July 23, 2024</div>
		<div class="red"><a href="#top" (click)="navigate('blog/01')">1. Introductions</a></div>
		<div class="orange"><a href="#top" (click)="navigate('blog/02')">2. From the beginning</a></div>
		<div class="yellow"><a href="#top" (click)="navigate('blog/03')">3. The first year</a></div>
		<div class="lightgreen active"><a href="#top" (click)="navigate('blog/04')">4. Initial cloud hosting</a></div>
		<div class="green"><a href="#top" (click)="navigate('blog/05')">5. UI/UX</a></div>
		<div class="cyan"><a href="#top" (click)="navigate('blog/06')">6. Performance tuning</a></div>
		<!-- <div class="blue"><a href="#top" (click)="navigate('blog/07')">7. Mental health</a></div>
		<div class="darkblue"><a href="#top" (click)="navigate('blog/08')">8. Funding vs bootstrapping</a></div>
		<div class="violet"><a href="#top" (click)="navigate('blog/09')">9. What's next</a></div> -->
	</div>
	<div>
		<div class="banner lightgreen">
			<div class="title">Photonomy thoughts and insights:<br /><span>Initial cloud hosting</span></div>
		</div>
		<div class="text-container">
			<!-- 
			<p>First year under my belt, spent working local machine.</p>
			<p>Wanted to deploy to sandbox environment, always looking for motivation</p>
			<p>Initial deployment to Netlify (UI), Heroku (API + DB), Backblaze B2 (Object Storage)</p>
			
			<hr />
			-->
			<p>During the first year, I spent the entire time working from my local machine. As time went on,
				I wanted to deploy to a cloud environment, always looking for milestones and methods of motivation.</p>
			<p>For the initial cloud architecture, I ended up choosing
				<a href="https://www.netlify.com/" target="_blank">Netlify <i class="fas fa-external-link-alt"></i></a>
				to host the front-end,
				<a href="https://www.heroku.com/" target="_blank">Heroku <i class="fas fa-external-link-alt"></i></a>
				for the back-end and the database, and
				<a href="https://www.backblaze.com/" target="_blank">Backblaze B2 <i class="fas fa-external-link-alt"></i></a>
				for object storage. At the time all of these services had a free-tier. The architecture was as follows:
			</p>
			<p><img src="/assets/images/blog/tad-01.jpg" /></p>
			<p>The initial deployment revealed some immediate issues. Netlify handled the UI smoothly and Backblaze
				accepted the files without issue. However, the service layer quickly ran out of memory and crashed
				any time I uploaded or downloaded photos.</p>
			<p>Logging revealed that my biggest issue was compression. If a user uploaded a 5MB file, I did
				not want to stream the full 5MB every time the user viewed the file. Instead, I needed to compress
				and store a much smaller version that will be viewed while the uncompressed version sits in cold storage.</p>
			<p>My second biggest issue was volume. When browsing photos, the service layer would pull many photos at
				once, often making hundreds of calls to the service layer per minute. I was running on the lowest tier
				hosting available, and my inefficient code revealed a huge problem. If it won't work for one person, how
				could it possibly scale to thousands without bankrupting me? </p>
			<p>I immediately added designs for future optimizations, which will be covered in a later post. I did not start
				working these until later, as it's easy to get trapped optimizing code before the app even gets off the
				ground, and I didn't want to fall into this trap. I still considered myself in the validation phase, and
				I knew I could overcome these issues in the future. I got back to working on core features and left
				these challenges for another day.</p>
		</div>
	</div>
</div>
<app-footer></app-footer>