import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-terms-of-service',
	templateUrl: './terms-of-service.component.html',
	styleUrls: ['./terms-of-service.component.css']
})
export class TermsOfServiceComponent implements OnInit, AfterViewInit {
	@Input() isModal: boolean = false;
	loading = true;

	constructor(
		public router: Router
	) { }

	ngOnInit() {
		this.loading = false;
	}

	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}

	navigate(path,) {
		this.router.navigate([path]);
	}
}
