
export class Folder {
	public id: number;
	public userId: number;
	public name: string;
	public uuid: string;
	public parentId: number;

	public photosCount: number;

	public folders: Folder[] = [];
	public expanded: boolean = false; // Used on the folder tree to know if it should be expanded.
}
