import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Folder } from 'src/app/models/folder.model';
import { FolderService } from 'src/app/services/folder.service';
import { AuthService } from '../../../services/auth.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-folders',
	templateUrl: './folders.component.html',
	styleUrls: ['./folders.component.css']
})
export class FoldersComponent implements OnInit {
	@ViewChild('moveFolderDialog', { static: true }) moveFolderDialog: TemplateRef<any>;
	@ViewChild('removePhotosDialog', { static: true }) removePhotosDialog: TemplateRef<any>;
	modalRef: BsModalRef;

	loadingFolders = false;
	loadingNoFoldersCount = true;
	loadingCreateFolder = -1;
	loadingUpdateFolder = -1;
	loadingDeleteFolder = -1;
	loadingRemovePhotos = false;

	formRoot: UntypedFormGroup;
	formSub: UntypedFormGroup;

	rootFolders: any[] = [];

	// Expanded
	expandedRootFolderId = -1;

	// Create root folder
	createFolderParentId = -1;
	createRootFolderName = '';

	// Update root folder
	updateRootFolderId = -1;
	updateRootFolderName = '';

	// Update sub folder
	updateSubFolderId = -1;
	updateSubFolderName = '';

	// Create sub folder
	createSubFolderParentId = -1;
	createSubFolderName = '';

	// Move folder
	moveFolderId = -1;
	moveFolderName = '';

	// Delete folder
	deleteFolderId = -1;
	deleteFolderName = '';

	// Remove photos from folder
	removePhotosFolderId = -1;
	removePhotosFolderName = '';
	showRemovePhotosFolderSelector = false;
	removePhotosFolderSelectorExpandedFolderId = -1;
	showRemovePhotosDeleteConfirm = false;

	// Folder action icons
	showActionIconsFolderId = -1;
	folderActionText = '';

	noFoldersCount = 0;

	constructor(
		private formBuilder: UntypedFormBuilder,
		public authService: AuthService,
		private modalService: BsModalService,
		public folderService: FolderService,
		public userService: UserService,
		public router: Router
	) { }

	ngOnInit() {
		this.loadUserFolders();
		this.loadNoFoldersCount();
	}

	ngOnDestroy() { }

	/** LOAD FOLDER FUNCTIONS  **/

	loadUserFolders() {
		this.loadingFolders = true;
		this.folderService.getFolders(0).subscribe(
			response => {
				this.rootFolders = response.body;

				this.rootFolders.sort((a, b) => a.name.toString().localeCompare(b.name));
				this.rootFolders = this.rootFolders.slice();

				this.createRootFolderName = '';
				this.createSubFolderName = '';
			}
		);
	}

	loadNoFoldersCount() {
		this.loadingNoFoldersCount = true;
		this.folderService.getFolderPhotosCount(0).subscribe(
			response => {
				this.noFoldersCount = response.body;

				this.loadingNoFoldersCount = false;
			}
		);
	}


	/** CREATE ROOT FOLDER FUNCTIONS **/

	showCreateRootFolder() {
		this.createFolderParentId = 0;
	}

	cancelCreateRootFolder() {
		this.createFolderParentId = -1;
		this.createRootFolderName = '';
	}

	setTempCreateRootFolderName(e) {
		let value = e.target.value;
		this.createRootFolderName = value;
	}

	createRootFolder() {
		this.loadingCreateFolder = 0;

		let folder: Folder = new Folder;
		folder.name = this.createRootFolderName;
		folder.parentId = 0;

		this.folderService.createFolder(folder).subscribe(
			response => {
				this.rootFolders.push(response.body);

				this.rootFolders.sort((a, b) => a.name.toString().localeCompare(b.name));
				this.rootFolders = this.rootFolders.slice();

				this.createFolderParentId = -1;

				this.createRootFolderName = ''

				this.loadingCreateFolder = -1;
			}
		);
	}

	/** CREATE SUB FOLDER FUNCTIONS **/

	showCreateSubfolder(parentFolderId) {
		this.createSubFolderParentId = parentFolderId;
	}

	cancelShowCreateSubfolder(folderId) {
		this.createSubFolderParentId = -1;
		this.createSubFolderName = '';
	}

	setTempCreateSubFolderName(e) {
		let value = e.target.value;
		this.createSubFolderName = value;
	}

	createSubFolder(parentFolderId) {
		this.loadingCreateFolder = parentFolderId;

		let folder: Folder = new Folder;
		folder.name = this.createSubFolderName;
		folder.parentId = parentFolderId;

		this.folderService.createFolder(folder).subscribe(
			response => {
				let parentFolder: Folder = this.rootFolders.find(d => d.id === parentFolderId);
				console.log(parentFolder);
				if (parentFolder) {
					if (parentFolder.folders) {
						parentFolder.folders.push(response.body);
					} else {
						parentFolder.folders = [];
						parentFolder.folders.push(response.body);
					}

					parentFolder.folders.sort((a, b) => a.name.toString().localeCompare(b.name));
					parentFolder.folders = parentFolder.folders.slice();

					this.showActionIconsFolderId = -1;
					this.createSubFolderParentId = -1;
					this.createSubFolderName = '';

					this.loadingCreateFolder = -1;
				} else {
					// Error handling, just pull the folders over again completely
					this.loadingCreateFolder = -1;
				}

			}
		);
	}


	/** UPDATE ROOT FOLDER FUNCTIONS **/

	showUpdateRootFolder(folder) {
		this.folderActionText = '';
		this.updateRootFolderName = folder.name;
		this.updateRootFolderId = folder.id;
	}

	cancelUpdateRootFolder() {
		this.updateRootFolderId = -1;
	}

	setTempUpdateRootFolderName(e) {
		let value = e.target.value;
		this.updateRootFolderName = value;
	}

	updateRootFolder() {
		this.loadingUpdateFolder = 0;

		let folder: Folder = new Folder;
		folder.name = this.updateRootFolderName;
		folder.id = this.updateRootFolderId;

		this.folderService.updateFolder(folder).subscribe(
			response => {
				// TODO: Get the old folder entry.
				let item = this.rootFolders.find(d => d.id === folder.id);
				item.name = this.updateRootFolderName;

				this.rootFolders.sort((a, b) => a.name.toString().localeCompare(b.name));
				this.rootFolders = this.rootFolders.slice();

				this.updateRootFolderId = -1;
				this.updateRootFolderName = ''
				this.showActionIconsFolderId = -1

				this.loadingUpdateFolder = -1;
			}
		);
	}


	/** UPDATE SUB FOLDER FUNCTIONS **/

	showUpdateSubFolder(folder) {
		this.folderActionText = '';
		this.updateSubFolderName = folder.name;
		this.updateSubFolderId = folder.id;
	}

	cancelUpdateSubFolder() {
		this.updateSubFolderId = -1;
	}

	setTempUpdateSubFolderName(e) {
		let value = e.target.value;
		this.updateSubFolderName = value;
	}

	updateSubFolder(parentFolderId) {
		this.loadingUpdateFolder = 0;

		let folder: Folder = new Folder;
		folder.name = this.updateSubFolderName;
		folder.id = this.updateSubFolderId;

		this.folderService.updateFolder(folder).subscribe(
			response => {
				let rootItem = this.rootFolders.find(d => d.id === parentFolderId);
				console.log(rootItem);

				let subItem = rootItem.folders.find(d => d.id === folder.id);
				console.log(subItem);
				subItem.name = folder.name;

				rootItem.folders.sort((a, b) => a.name.toString().localeCompare(b.name));
				rootItem.folders = rootItem.folders.slice();

				this.updateSubFolderId = -1;
				this.updateSubFolderName = ''
				this.showActionIconsFolderId = -1

				this.loadingUpdateFolder = -1;
			}
		);
	}


	/** MOVE FOLDER FUNCTIONS **/

	showMoveFolderModal(folder) {
		this.moveFolderId = folder.id;
		this.moveFolderName = folder.name;

		this.modalRef = this.modalService.show(
			this.moveFolderDialog,
			Object.assign({}, { class: 'modal-lg' }, { backdrop: true, ignoreBackdropClick: true, animated: true })
		);
	}

	cancelFolderMove() {
		this.modalRef.hide();

		this.moveFolderId = -1;
		this.moveFolderName = '';
	}

	moveFolder(parentFolderId) {
		this.loadingUpdateFolder = 0;

		let updateFolder: Folder = new Folder;
		updateFolder.id = this.moveFolderId;
		updateFolder.parentId = parentFolderId;

		this.folderService.updateFolder(updateFolder).subscribe(
			response => {
				// ## Remove from existing folder

				let existingFolder = this.rootFolders.find(d => d.id === this.moveFolderId);
				if (existingFolder) {
					console.log('if');
					// We are moving a root folder
					this.rootFolders.splice(this.rootFolders.indexOf(existingFolder), 1);
				} else {
					console.log('else');
					// We are moving a subfolder, still need to find it
					for (let rootFolder of this.rootFolders) {
						existingFolder = rootFolder.folders.find(d => d.id === this.moveFolderId);
						if (existingFolder) {
							// Found it
							rootFolder.folders.splice(rootFolder.folders.indexOf(existingFolder), 1);

							if (rootFolder.folders.length == 0) {
								// TODO: If this is the last folder, change the expanded icon to collapsed
								this.expandedRootFolderId = -1;
							}

							break;
						}
					}
				}
				console.log(existingFolder);


				// ## Add the new folder

				if (response.body.parentId == 0) {
					// Add to root
					this.rootFolders.push(response.body);

					this.rootFolders.sort((a, b) => a.name.toString().localeCompare(b.name));
					this.rootFolders = this.rootFolders.slice();
				} else {
					// Add as a subfolder, need to find new parent
					for (let rootFolder of this.rootFolders) {
						if (rootFolder.id == response.body.parentId) {
							rootFolder.folders.push(response.body);

							rootFolder.folders.sort((a, b) => a.name.toString().localeCompare(b.name));
							rootFolder.folders = rootFolder.folders.slice();
						}
					}
				}

				this.showActionIconsFolderId = -1
				this.loadingUpdateFolder = -1;

				this.modalRef.hide();
			}
		);
	}


	/** DELETE FOLDER FUNCTIONS **/

	deleteFolder(folder) {
		this.loadingDeleteFolder = folder.id;

		this.folderService.deleteFolder(folder.id).subscribe(
			response => {
				let existingFolder = this.rootFolders.find(d => d.id === folder.id);
				if (existingFolder) {
					// We are moving a root folder
					console.log(existingFolder);
					this.rootFolders.splice(this.rootFolders.indexOf(existingFolder), 1);
				} else {
					console.log('else');
					// We are moving a subfolder, still need to find it
					for (let rootFolder of this.rootFolders) {
						existingFolder = rootFolder.folders.find(d => d.id === folder.id);
						if (existingFolder) {
							// Found it
							rootFolder.folders.splice(rootFolder.folders.indexOf(existingFolder), 1);

							if (rootFolder.folders.length == 0) {
								// TODO: If this is the last folder, change the expanded icon to collapsed
								this.expandedRootFolderId = -1;
							}

							break;
						}
					}
				}

				this.showActionIconsFolderId = -1

				this.loadingDeleteFolder = -1;
			}
		);
	}


	/** REMOVE PHOTOS FROM FOLDER FUNCTIONS **/

	showRemovePhotosDialog(folder) {
		this.removePhotosFolderId = folder.id;
		this.removePhotosFolderName = folder.name;

		this.modalRef = this.modalService.show(
			this.removePhotosDialog,
			Object.assign({}, { class: 'modal-lg' }, { backdrop: true, ignoreBackdropClick: true, animated: true })
		);
	}

	cancelRemovePhotos() {
		this.modalRef.hide();

		this.removePhotosFolderId = -1;
		this.removePhotosFolderName = '';
	}

	moveFolderPhotos() {
		this.showRemovePhotosFolderSelector = true;
	}

	moveFolderPhotosCancel() {
		this.showRemovePhotosFolderSelector = false;
	}

	moveFolderPhotosConfirm(newParentId) {
		if (newParentId !== this.removePhotosFolderId) {
			this.loadingRemovePhotos = true;
			this.folderService.moveFolderPhotos(this.removePhotosFolderId, newParentId).subscribe(
				response => {
					// UPDATE THE PHOTOS COUNT ON THE OLD FOLDER
					let photosMoved = 0;
					let oldFolder = this.rootFolders.find(d => d.id === this.removePhotosFolderId);
					if (oldFolder) {
						// We are updating a root folder
						photosMoved = oldFolder.photosCount;
						oldFolder.photosCount = 0;
					} else {
						// We are updating a subfolder, still need to find it
						for (let rootFolder of this.rootFolders) {
							oldFolder = rootFolder.folders.find(d => d.id === this.removePhotosFolderId);
							if (oldFolder) {
								// Found it
								photosMoved = oldFolder.photosCount;
								oldFolder.photosCount = 0;

								break;
							}
						}
					}

					// UPDATE THE PHOTOS COUNT ON THE NEW FOLDER
					if (newParentId == 0) {
						this.noFoldersCount += photosMoved;
					} else {
						let newFolder = this.rootFolders.find(d => d.id === newParentId);
						if (newFolder) {
							newFolder.photosCount += photosMoved;
						} else {
							// We are updating a subfolder, still need to find it
							for (let rootFolder of this.rootFolders) {
								newFolder = rootFolder.folders.find(d => d.id === newParentId);
								if (newFolder) {
									// Found it
									newFolder.photosCount += photosMoved;

									break;
								}
							}
						}
					}

					this.removePhotosFolderId = -1;
					this.removePhotosFolderName = '';
					this.showActionIconsFolderId = -1;
					this.folderActionText = '';

					this.modalRef.hide();

					this.loadingRemovePhotos = false;
				}
			);
		}
	}

	deleteFolderPhotos() {
		this.showRemovePhotosDeleteConfirm = true;
	}

	deleteFolderPhotosCancel() {
		this.showRemovePhotosDeleteConfirm = false;
	}

	deleteFolderPhotosConfirm() {
		this.loadingRemovePhotos = true;
		this.folderService.deleteFolderPhotos(this.removePhotosFolderId).subscribe(
			response => {
				// UPDATE THE PHOTOS COUNT ON THE FOLDER
				let oldFolder = this.rootFolders.find(d => d.id === this.removePhotosFolderId);
				if (oldFolder) {
					// We are updating a root folder
					oldFolder.photosCount = 0;
				} else {
					// We are updating a subfolder, still need to find it
					for (let rootFolder of this.rootFolders) {
						oldFolder = rootFolder.folders.find(d => d.id === this.removePhotosFolderId);
						if (oldFolder) {
							// Found it
							oldFolder.photosCount = 0;

							break;
						}
					}
				}

				this.removePhotosFolderId = -1;
				this.removePhotosFolderName = '';
				this.showActionIconsFolderId = -1;
				this.folderActionText = '';

				this.modalRef.hide();

				this.loadingRemovePhotos = true;
			}
		);
	}

	expandRemovePhotosFolder(event, folder) {
		event.stopPropagation();

		this.removePhotosFolderSelectorExpandedFolderId = folder.id;
	}


	collapseRemovePhotosFolder(event, folderId) {
		event.stopPropagation();

		this.removePhotosFolderSelectorExpandedFolderId = -1;
	}


	/** EXPAND FOLDER FUNCTIONS **/

	expandFolder(folder) {
		if (folder.folders && folder.folders.length > 0) {
			// Sort the subfolders
			folder.folders.sort((a, b) => a.name.toString().localeCompare(b.name));
			folder.folders = folder.folders.slice();

			this.showActionIconsFolderId = -1;
			this.expandedRootFolderId = folder.id;
		}
	}

	collapseFolder(folderId) {
		this.showActionIconsFolderId = -1;
		this.expandedRootFolderId = -1;
	}


	/** NAVIGATE FUNCTIONS **/

	exploreFolderPhotos(folderId) {
		console.log(folderId);
		this.router.navigate(['/explore'], { queryParams: { folderId: folderId } });
	}
}
