import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventPhotoAdd } from './models/event-photo-add.model';
import { EventPhotoRemove } from './models/event-photo-remove.model';
import { PhotoUploadQueueNumber } from './models/photo-upload-queue-number.model';
import { PhotoUpload } from './models/photo-upload.model';
import { AuthService } from './services/auth.service';
import { LayoutService } from './services/layout.service';
import { PhotoService } from './services/photo.service';
import { SyncService } from './services/sync.service';
import { fadeAnimation } from './shared/animations/animations';

declare let ga: Function;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	animations: [fadeAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
	// Router Vars
	routerSubscription: Subscription;
	logoutSubscription: Subscription;

	// Google Analytics Vars
	gaInitialized = false;

	// Login Vars
	showLogin = true;

	// Private Mode Vars
	privateModeEnabled = false;
	privateModeEnabledChangedSubscription: Subscription;

	// External File Sync Vars
	// syncInProgress = false;
	// syncInProgressChangedSubscription: Subscription;

	// Upload Photo Vars
	uploadPhotoSubscription: Subscription;
	photoUploads: PhotoUpload[] = [];
	uploadPhotosExpanded = true;
	uploadsProcessing = 0;
	assignedUploadQueueNumber = 0;
	processedUploadQueueNumber = 0;
	nextProcessedUploadQueueNumber = 1;

	// Event Photo Vars
	addEventPhotoSubscription: Subscription;
	addEventPhotos: EventPhotoAdd[] = [];
	addEventPhotosExpanded = false;
	addEventPhotosProcessing = 0;
	assignedAddEventPhotosQueueNumber = 0;
	processedAddEventPhotosQueueNumber = 0;
	nextProcessedAddEventPhotosQueueNumber = 1;

	removeEventPhotoSubscription: Subscription;
	removeEventPhotos: EventPhotoRemove[] = [];
	removeEventPhotosExpanded = false;
	removeEventPhotosProcessing = 0;
	assignedRemoveEventPhotosQueueNumber = 0;
	processedRemoveEventPhotosQueueNumber = 0;
	nextProcessedRemoveEventPhotosQueueNumber = 1;

	constructor(
		private router: Router,
		private authService: AuthService,
		private layoutService: LayoutService,
		private photoService: PhotoService,
		private syncService: SyncService
	) { }

	ngOnInit() {
		this.subscribeToRouterEvents();
		this.subscribeToLogout();

		this.subscribeToPrivateModeChanged();
		// this.subscribeToSyncInProgressChanged();

		this.subscribeToUploadPhoto();
		this.subscribeToAddEventPhoto();
		this.subscribeToRemoveEventPhoto();

		this.initializeGoogleAnalytics();
	}

	ngOnDestroy() {
		if (this.routerSubscription) {
			this.routerSubscription.unsubscribe();
		}
		if (this.uploadPhotoSubscription) {
			this.uploadPhotoSubscription.unsubscribe();
		}
		if (this.addEventPhotoSubscription) {
			this.addEventPhotoSubscription.unsubscribe();
		}
		if (this.removeEventPhotoSubscription) {
			this.removeEventPhotoSubscription.unsubscribe();
		}
		if (this.privateModeEnabledChangedSubscription) {
			this.privateModeEnabledChangedSubscription.unsubscribe();
		}
		// if (this.syncInProgressChangedSubscription) {
		// 	this.syncInProgressChangedSubscription.unsubscribe();
		// }
	}

	onDeactivate() {
		console.log('onDeactivate');
		document.body.scrollTop = 0;
		// Alternatively, you can scroll to top by using this other call:
		window.scrollTo(0, 0);
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	}

	/**
	 * Subscriptions
	 */
	subscribeToRouterEvents() {
		this.routerSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (this.router.url.indexOf('/login') > -1 || this.router.url.indexOf('/register') > -1 || this.router.url.indexOf('/reset-password') > -1 || this.router.url.indexOf('/privacy') > -1 || this.router.url.indexOf('/terms') > -1 || this.router.url.indexOf('/blog') > -1 || this.router.url.indexOf('/gallery') > -1 || (this.router.url.indexOf('/event/') > -1 && this.router.url.indexOf('/event/create') == -1) || this.router.url == '/') {
					this.showLogin = true;
				} else {
					this.showLogin = false;
				}
			}
		});
	}

	subscribeToLogout() {
		this.logoutSubscription = this.authService.announceLogout.subscribe((event) => {
			this.acknowledgeUploads();
			this.acknowledgeEventPhotosAdd();
			this.acknowledgeEventPhotosRemove();
		});
	}

	subscribeToUploadPhoto() {
		this.uploadPhotoSubscription = this.layoutService.uploadPhoto
			.subscribe((photoUpload: PhotoUpload) => {
				// If there are less than 2 items currently processing, immediately process this file.
				// Otherwise place it in the queue.
				this.assignedUploadQueueNumber++;
				photoUpload.queueNumber = this.assignedUploadQueueNumber;

				if (this.uploadsProcessing == 0) {
					this.uploadsProcessing++;
					this.nextProcessedUploadQueueNumber++;
					photoUpload.status = 'inprogress';
					photoUpload.signedUploadUrlNumber = 1;
				} else if (this.uploadsProcessing == 1) {
					this.uploadsProcessing++;
					this.nextProcessedUploadQueueNumber++;
					photoUpload.status = 'inprogress';
					photoUpload.signedUploadUrlNumber = 2;
				} else if (this.uploadsProcessing == 2) {
					this.uploadsProcessing++;
					this.nextProcessedUploadQueueNumber++;
					photoUpload.status = 'inprogress';
					photoUpload.signedUploadUrlNumber = 3;
				} else if (this.uploadsProcessing == 3) {
					this.uploadsProcessing++;
					this.nextProcessedUploadQueueNumber++;
					photoUpload.status = 'inprogress';
					photoUpload.signedUploadUrlNumber = 4;
				} else if (this.uploadsProcessing == 4) {
					this.uploadsProcessing++;
					this.nextProcessedUploadQueueNumber++;
					photoUpload.status = 'inprogress';
					photoUpload.signedUploadUrlNumber = 5;
				} else {
					photoUpload.status = 'queued';
				}

				this.photoUploads.push(photoUpload);
			});
	}

	subscribeToAddEventPhoto() {
		this.addEventPhotoSubscription = this.layoutService.addEventPhoto
			.subscribe((eventPhoto: EventPhotoAdd) => {
				// If there are less than 2 items currently processing, immediately process this file.
				// Otherwise place it in the queue.
				this.assignedAddEventPhotosQueueNumber++;
				eventPhoto.queueNumber = this.assignedAddEventPhotosQueueNumber;

				if (this.addEventPhotosProcessing == 0) {
					this.addEventPhotosProcessing++;
					this.nextProcessedAddEventPhotosQueueNumber++;
					eventPhoto.status = 'inprogress';
				} else if (this.addEventPhotosProcessing == 1) {
					this.addEventPhotosProcessing++;
					this.nextProcessedAddEventPhotosQueueNumber++;
					eventPhoto.status = 'inprogress';
				} else if (this.addEventPhotosProcessing == 2) {
					this.addEventPhotosProcessing++;
					this.nextProcessedAddEventPhotosQueueNumber++;
					eventPhoto.status = 'inprogress';
				} else if (this.addEventPhotosProcessing == 3) {
					this.addEventPhotosProcessing++;
					this.nextProcessedAddEventPhotosQueueNumber++;
					eventPhoto.status = 'inprogress';
				} else if (this.addEventPhotosProcessing == 4) {
					this.addEventPhotosProcessing++;
					this.nextProcessedAddEventPhotosQueueNumber++;
					eventPhoto.status = 'inprogress';
				} else {
					eventPhoto.status = 'queued';
				}

				this.addEventPhotos.push(eventPhoto);
			});
	}

	subscribeToRemoveEventPhoto() {
		this.removeEventPhotoSubscription = this.layoutService.removeEventPhoto
			.subscribe((eventPhoto: EventPhotoRemove) => {
				// If there are less than 2 items currently processing, immediately process this file.
				// Otherwise place it in the queue.
				this.assignedRemoveEventPhotosQueueNumber++;
				eventPhoto.queueNumber = this.assignedRemoveEventPhotosQueueNumber;

				if (this.removeEventPhotosProcessing == 0) {
					this.removeEventPhotosProcessing++;
					this.nextProcessedRemoveEventPhotosQueueNumber++;
					eventPhoto.status = 'inprogress';
				} else if (this.removeEventPhotosProcessing == 1) {
					this.removeEventPhotosProcessing++;
					this.nextProcessedRemoveEventPhotosQueueNumber++;
					eventPhoto.status = 'inprogress';
				} else if (this.removeEventPhotosProcessing == 2) {
					this.removeEventPhotosProcessing++;
					this.nextProcessedRemoveEventPhotosQueueNumber++;
					eventPhoto.status = 'inprogress';
				} else if (this.removeEventPhotosProcessing == 3) {
					this.removeEventPhotosProcessing++;
					this.nextProcessedRemoveEventPhotosQueueNumber++;
					eventPhoto.status = 'inprogress';
				} else if (this.removeEventPhotosProcessing == 4) {
					this.removeEventPhotosProcessing++;
					this.nextProcessedRemoveEventPhotosQueueNumber++;
					eventPhoto.status = 'inprogress';
				} else {
					eventPhoto.status = 'queued';
				}

				this.removeEventPhotos.push(eventPhoto);
			});
	}

	// subscribeToSyncInProgressChanged() {
	// 	this.syncInProgressChangedSubscription = this.syncService.syncInProgressChanged
	// 		.subscribe((value: boolean) => {
	// 			this.syncInProgress = value;
	// 		});
	// }

	subscribeToPrivateModeChanged() {
		this.privateModeEnabledChangedSubscription = this.layoutService.privateModeEnabledChanged
			.subscribe((value: boolean) => {
				this.privateModeEnabled = value;
			});
	}

	/**
	 * Init
	 */
	initializeGoogleAnalytics() {
		ga('create', 'UA-179135066-1', 'auto');
		ga('set', 'page', this.router.url);
		ga('send', 'pageview');
		this.gaInitialized = true;

		this.routerSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (this.gaInitialized) {
					ga('set', 'page', event.urlAfterRedirects);
					ga('send', 'pageview');
				}
			}
		});
	}

	/**
	 * Sync Functions
	 */
	cancelSync() {
		this.syncService.setSyncInProgress(false);
	}

	/**
	 * Upload Photo Functions
	 */
	handleUploadPhotoFinished(photoUpload: PhotoUpload) {
		if (photoUpload.queueNumber > this.processedUploadQueueNumber) { // 1 > 0		2 > 1    3 > 2
			this.processedUploadQueueNumber = photoUpload.queueNumber; // processedUploadQueueNumber = 1   = 2   = 3
		}

		// Determine if there are more items to process.
		// TODO: THIS DOESNT SEEM TO WORK IF I USE 1 PHOTO. WANT TO TEST THIS ON UPLOADS AS WELL
		if (this.assignedUploadQueueNumber >= this.nextProcessedUploadQueueNumber) { // 4 > 3    4 > 4
			// More files to process
			let photoUploadQueueNumber: PhotoUploadQueueNumber = new PhotoUploadQueueNumber();
			photoUploadQueueNumber.queueNumber = this.nextProcessedUploadQueueNumber;
			photoUploadQueueNumber.signedUploadUrlNumber = photoUpload.signedUploadUrlNumber;

			this.layoutService.announceNextUploadPhotoQueueNumber(photoUploadQueueNumber);
			this.nextProcessedUploadQueueNumber++;
		} else {
			// No more files to process
			this.uploadsProcessing = this.uploadsProcessing - 1;
		}

		// Update the status of the current item.
		const item = this.photoUploads.find(d => d.queueNumber === photoUpload.queueNumber);
		item.status = photoUpload.status;

		// Announce it so the photo ends up on the event page if the user is still on it.
		this.layoutService.announceUploadPhotoSuccess(photoUpload);

		// Update the global count, so we display the dashboard if this is the first photo uploaded.
		this.photoService.userPhotoCount++;
	}

	acknowledgeUploads() {
		this.photoUploads = [];
		this.uploadsProcessing = 0;
		this.assignedUploadQueueNumber = 0;
		this.processedUploadQueueNumber = 0;
		this.nextProcessedUploadQueueNumber = 1;
	}

	handleEventPhotoAddFinished(eventPhotoAdd: EventPhotoAdd) {
		if (eventPhotoAdd.queueNumber > this.processedAddEventPhotosQueueNumber) { // 1 > 0		2 > 1    3 > 2
			this.processedAddEventPhotosQueueNumber = eventPhotoAdd.queueNumber; // processedUploadQueueNumber = 1   = 2   = 3
		}

		// Determine if there are more items to process.
		if (this.assignedAddEventPhotosQueueNumber >= this.nextProcessedAddEventPhotosQueueNumber) { // 4 > 3    4 > 4
			// More files to process.
			let queueNumber = this.nextProcessedAddEventPhotosQueueNumber;

			this.layoutService.announceNextAddEventPhotoQueueNumber(queueNumber);
			this.nextProcessedAddEventPhotosQueueNumber++;
		} else {
			// No more files to process.
			this.addEventPhotosProcessing = this.addEventPhotosProcessing - 1;
		}

		// Update the status of the current item.
		const item = this.addEventPhotos.find(d => d.queueNumber === eventPhotoAdd.queueNumber);
		item.status = eventPhotoAdd.status;

		// Announce it so the photo ends up on the event page if the user is still on it.
		this.layoutService.announceAddEventPhotoSuccess(eventPhotoAdd);
	}

	acknowledgeEventPhotosAdd() {
		this.addEventPhotos = [];
		this.addEventPhotosProcessing = 0;
		this.assignedAddEventPhotosQueueNumber = 0;
		this.processedAddEventPhotosQueueNumber = 0;
		this.nextProcessedAddEventPhotosQueueNumber = 1;
	}

	handleEventPhotoRemoveFinished(eventPhotoRemove: EventPhotoRemove) {
		if (eventPhotoRemove.queueNumber > this.processedRemoveEventPhotosQueueNumber) { // 1 > 0		2 > 1    3 > 2
			this.processedRemoveEventPhotosQueueNumber = eventPhotoRemove.queueNumber; // processedUploadQueueNumber = 1   = 2   = 3
		}

		// Determine if there are more items to process.
		if (this.assignedRemoveEventPhotosQueueNumber >= this.nextProcessedRemoveEventPhotosQueueNumber) { // 4 > 3    4 > 4
			// More files to process.
			let queueNumber = this.nextProcessedRemoveEventPhotosQueueNumber;

			this.layoutService.announceNextRemoveEventPhotoQueueNumber(queueNumber);
			this.nextProcessedRemoveEventPhotosQueueNumber++;
		} else {
			// No more files to process.
			this.removeEventPhotosProcessing = this.removeEventPhotosProcessing - 1;
		}

		// Update the status of the current item.
		const item = this.removeEventPhotos.find(d => d.queueNumber === eventPhotoRemove.queueNumber);
		item.status = eventPhotoRemove.status;

		// Announce it so the photo is removed from the event page if the user is still on it.
		this.layoutService.announceRemoveEventPhotoSuccess(eventPhotoRemove);
	}

	acknowledgeEventPhotosRemove() {
		this.removeEventPhotos = [];
		this.removeEventPhotosProcessing = 0;
		this.assignedRemoveEventPhotosQueueNumber = 0;
		this.processedRemoveEventPhotosQueueNumber = 0;
		this.nextProcessedRemoveEventPhotosQueueNumber = 1;
	}


	/**
	 * Helper
	 */
	prepareRoute(outlet: RouterOutlet) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
	}
}
