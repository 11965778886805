import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SignedDownloadToken } from '../models/signed-download-token.model';
import { UserCredit } from '../models/user-credit.model';
import { UserDebit } from '../models/user-debit.model';
import { UserMessage } from '../models/user-message';
import { User } from '../models/user.model';
import { Widget } from '../models/widget.model';
import { PhotoService } from './photo.service';

@Injectable()
export class UserService {
	public users: User[] = []; // Stores all users, including the current user at the 0 index.
	public userChanged = new Subject<User>(); // Announce a user has changed.
	public userIdsLoading: number[] = []; // Used so we don't attempt to load the user image multiple times.

	public policiesVerified: boolean;

	public userPeople;
	public userLocations;
	public userKeywords;
	public userTags;

	constructor(
		private http: HttpClient,
		private photoService: PhotoService
	) { }

	/**
	 * LOCAL USER CACHE FUNCTIONS
	 */

	getLocalUser(index: number) {
		if (this.users[index]) {
			return this.users[index];
		} else {
			return null;
		}
	}

	getLocalUserId(index: number) {
		if (this.users[index]) {
			return this.users[index].id;
		} else {
			return null;
		}
	}

	getLocalUserUuid(index: number) {
		if (this.users[index]) {
			return this.users[index].uuid;
		} else {
			return null;
		}
	}

	getLocalUserFullName(index: number) {
		if (this.users[index]) {
			return this.users[0].firstName + ' ' + this.users[0].lastName;
		} else {
			return null;
		}
	}

	addLocalUser(user: User) {
		this.users.push(user);
		this.userChanged.next(user);
	}

	updateLocalUser(user: User) {
		let item = this.users.find(d => d.id === user.id);
		if (item) {
			// It is worth noting this solution creates a new array which can be 
			// problematic in some situations (namely, Angular reference change detection). 
			this.users = this.users.map(u => u.id !== user.id ? u : user);
			this.userChanged.next(user);
		}
	}


	/**
	 * USER IMAGE FUNCTIONS
	 */

	/**
	 * If you already have the user or only the image needs returned, use this function.
	 */
	retrieveUserImageSafeUrl(user: User, photoIndex: number) {
		if (user.imageExternalUrl) {
			let token: SignedDownloadToken = null;
			token = this.photoService.signedDownloadTokens.find(d => d.scope === 'profile');

			if (token) {
				// Found a valid token, use it
				let item = this.users.find(d => d.id === user.id);
				if (item) {
					item.imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + user.imageExternalUrl + "?Authorization=" + token.token;
					if (photoIndex >= 0) {
						item.photoIndex = photoIndex;
					}

					let usIdLoadingItem = this.userIdsLoading.find(d => d === user.id);
					this.userIdsLoading.splice(this.userIdsLoading.indexOf(usIdLoadingItem), 1);

					this.userChanged.next(item);
				}
			} else {
				// No valid token for scope, pull new token
				this.photoService.getSignedDownloadToken('profile', 'profile').subscribe(
					response => {
						let responseToken = response.body;
						let imageSafeUrl = '';

						// Make sure token doesn't already exist.
						let token2 = this.photoService.signedDownloadTokens.find(d => d.scope === 'profile');
						if (token2) {
							imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + user.imageExternalUrl + "?Authorization=" + token2.token;
						} else {
							imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + user.imageExternalUrl + "?Authorization=" + responseToken.token;
							this.photoService.signedDownloadTokens.push(responseToken);
						}

						let item = this.users.find(d => d.id === user.id);
						if (item) {
							item.imageSafeUrl = imageSafeUrl;
							if (photoIndex >= 0) {
								item.photoIndex = photoIndex;
							}

							let usIdLoadingItem = this.userIdsLoading.find(d => d === user.id);
							this.userIdsLoading.splice(this.userIdsLoading.indexOf(usIdLoadingItem), 1);

							this.userChanged.next(item);
						}
					}
				);
			}
		} else {
			if (photoIndex >= 0) {
				user.photoIndex = photoIndex;
			}

			let usIdLoadingItem = this.userIdsLoading.find(d => d === user.id);
			this.userIdsLoading.splice(this.userIdsLoading.indexOf(usIdLoadingItem), 1);

			this.userChanged.next(user);
		}
	}

	/**
	 * If all the details of a user are needed, use this function.
	 * 
	 * Get the user, then retrieve the image safe url. This is captured
	 * in the subscription above and announced back to the comment component.
	 * 
	 * @param userId
	 * @param photoIndex - Optional parameter to maintain the context of the photo, used on the explore page
	 */
	getUserByUserId(userId, photoIndex) {
		console.log(userId);
		// Lookup the user in the cache by user id.
		let user = this.users.find(d => d.id === userId);
		if (!user) {
			// No user, need to look it up.  Since this function is referenced within a .html, need to add loading
			// check so the call isn't performed multiple times when loading comments.
			let item = this.userIdsLoading.find(d => d === userId);
			if (!item) {
				this.userIdsLoading.push(userId);

				this.retrieveUserBasic(userId).subscribe(
					response => {
						let user = response.body;

						if (photoIndex >= 0) {
							user.photoIndex = photoIndex;
						}
						this.users.push(user);

						this.retrieveUserImageSafeUrl(user, photoIndex);
					}
				);
			}
		} else {
			// TODO: If there is an externalUrl, but not an imageSafeUrl, retrieve the image

			// Found the user in the cache, announce it.
			if (photoIndex >= 0) {
				user.photoIndex = photoIndex;
			}
			this.userChanged.next(user);
		}
	}

	deleteProfileImage(): Observable<any> {
		const requestUrl = '/api/user/image';

		return this.http.delete<User>(requestUrl, { observe: 'response' });
	}


	/**
	 * USER FUNCTIONS
	 */

	retrieveUserBasic(userId) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		const requestUrl = '/api/user/basic?userId=' + userId;

		return this.http.get<User>(requestUrl, { headers: headers, observe: 'response' });
	}

	retrieveCurrentUser() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		const requestUrl = '/api/user/current';

		return this.http.get<User>(requestUrl, { headers: headers, observe: 'response' });
	}

	updateCurrentUser(user: User) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/user';

		return this.http.put<User>(requestUrl, user, { headers: headers, observe: 'response' });
	}

	updateCurrentUserPassword(currentPassword: string, password: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		headers = headers.set('Pwd1', `Basic ${btoa(currentPassword)}`);
		headers = headers.set('Pwd2', `Basic ${btoa(password)}`);

		const requestUrl = '/api/user/password';

		return this.http.put<any>(requestUrl, null, { headers: headers, observe: 'response' });
	}


	/**
	 * CURRENT USER DASHBOARD FUNCTIONS
	 */

	resetDashboardWidgets() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/user/dashboard/reset';

		return this.http.post<Array<Widget>>(requestUrl, { headers: headers });
	}

	updateDashboardWidgets(widgets: Widget[]) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/user/dashboard';

		return this.http.put<User>(requestUrl, widgets, { headers: headers, observe: 'response' });
	}

	retrieveUserMessages() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/user/messages';

		return this.http.get<Array<UserMessage>>(requestUrl, { headers: headers, observe: 'response' });
	}

	/**
	 * CURRENT USER METADATA FUNCTIONS
	 */

	addUserPerson(person: string): Observable<any> {
		const requestUrl = '/api/user/person';

		return this.http.post<boolean>(requestUrl, person);
	}

	deleteUserPerson(person: string): Observable<any> {
		const requestUrl = '/api/user/person';

		return this.http.delete<boolean>(requestUrl, { body: person });
	}

	getUserPeople() {
		const requestUrl = '/api/user/people';

		return this.http.get<Array<string>>(requestUrl);
	}

	getUserLocations() {
		const requestUrl = '/api/user/locations';

		return this.http.get<Array<string>>(requestUrl);
	}

	addUserLocation(location: string): Observable<any> {
		const requestUrl = '/api/user/location';

		return this.http.post<boolean>(requestUrl, location);
	}

	deleteUserLocation(location: string): Observable<any> {
		const requestUrl = '/api/user/location';

		return this.http.delete<boolean>(requestUrl, { body: location });
	}

	/** REPLACE API KEY WITH OAUTH */
	getLocationSuggestions(location: string) {
		const requestUrl = 'https://autocomplete.search.hereapi.com/v1/autocomplete?q=' + location + '&apiKey=7CZJWw-_K2S_y_hy2fHYjyubfCLEE5vI0LCuFi5Ow40';

		return this.http.get<any>(requestUrl, { observe: 'response' });
	}

	getUserKeywords() {
		const requestUrl = '/api/user/keywords';

		return this.http.get<Array<string>>(requestUrl);
	}

	addUserKeyword(keyword: string): Observable<any> {
		const requestUrl = '/api/user/keyword';

		return this.http.post<boolean>(requestUrl, keyword);
	}

	deleteUserKeyword(keyword: string): Observable<any> {
		const requestUrl = '/api/user/keyword';

		return this.http.delete<boolean>(requestUrl, { body: keyword });
	}

	findPublicTagsByKeyword(keyword: string): Observable<any> {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('keyword', keyword);

		const requestUrl = '/api/user/tags/public';


		return this.http.get<Array<string>>(requestUrl, { params: queryParameters });
	}

	getUserPublicTags() {
		const requestUrl = '/api/user/tags';

		return this.http.get<Array<string>>(requestUrl);
	}

	addUserPublicTag(tag: string): Observable<any> {
		const requestUrl = '/api/user/tag';

		return this.http.post<boolean>(requestUrl, tag);
	}

	deleteUserPublicTag(tag: string): Observable<any> {
		const requestUrl = '/api/user/tag';

		return this.http.delete<boolean>(requestUrl, { body: tag });
	}

	/**
	 * CURRENT USER MISC FUNCTIONS
	 */

	checkIfInvited() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/user/invited';

		return this.http.get<number>(requestUrl, { headers: headers, observe: 'response' });
	}

	verifyPrivacyPolicy() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/user/privacy';

		return this.http.get<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	acknowledgePrivacyPolicy() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/user/privacy';

		return this.http.put<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	verifyTermsOfService() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/user/terms';

		return this.http.get<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	acknowledgeTermsOfService() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/user/terms';

		return this.http.put<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	acknowledgeExploreHint() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = '/api/user/hint/explore';

		return this.http.put<boolean>(requestUrl, { headers: headers, observe: 'response' });
	}

	getUserCredits(vaultId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('vaultId', vaultId);

		const requestUrl = '/api/vault/credits';

		return this.http.get<Array<UserCredit>>(requestUrl, { headers: headers, observe: 'response', params: queryParameters });
	}

	getUserDebits(vaultId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('vaultId', vaultId);

		const requestUrl = '/api/vault/debits';

		return this.http.get<Array<UserDebit>>(requestUrl, { headers: headers, observe: 'response', params: queryParameters });
	}
}
