<div class="header" id="top">
	<div class="cell-logo"><img src="assets/images/logo-white.png" class="logo" (click)="navigate('')" /></div>
	<!--<div class="cell-1">Privacy Policy</div>-->
	<!--<div class="cell-2">Terms of Service</div>-->
	<!--<div class="cell-3">Style Guide</div>-->
</div>

<div class="content">
	<div class="toc">
		<div class="last-updated">Last updated<br />July 23, 2024</div>
		<div class="red active"><a href="#top" (click)="navigate('blog/01')">1. Introductions</a></div>
		<div class="orange"><a href="#top" (click)="navigate('blog/02')">2. From the beginning</a></div>
		<div class="yellow"><a href="#top" (click)="navigate('blog/03')">3. The first year</a></div>
		<div class="lightgreen"><a href="#top" (click)="navigate('blog/04')">4. Initial cloud hosting</a></div>
		<div class="green"><a href="#top" (click)="navigate('blog/05')">5. UI/UX</a></div>
		<div class="cyan"><a href="#top" (click)="navigate('blog/06')">6. Performance tuning</a></div>
		<!-- <div class="blue"><a href="#top" (click)="navigate('blog/07')">7. Mental health</a></div>
		<div class="darkblue"><a href="#top" (click)="navigate('blog/08')">8. Funding vs bootstrapping</a></div>
		<div class="violet"><a href="#top" (click)="navigate('blog/09')">9. What's next</a></div> -->
	</div>
	<div>
		<div class="banner red">
			<div class="title">Photonomy thoughts and insights:<br /><span>Introductions</span></div>
		</div>
		<div class="text-container">
			<img src="/assets/images/blog/me.jpg" class="me" align="left" />
			<p>Hi there! My name is
				<a href="https://www.linkedin.com/in/brad-hickey-747ab79/" target="_blank">Brad Hickey <i class="fas fa-external-link-alt"></i></a>
				, and I am a senior solution architect by day and full-stack developer by night.
			</p>

			<p>Over the past several years, my family and I have grown increasingly frustrated with the direction
				social media has taken. From privacy concerns to intrusive advertisements, the experience has
				become less social and overly commercialized. None of the current platforms offered us the experience
				we were looking for... a way to share pictures and communicate without the extras. With the itch to
				sharpen my development skills (my full time job doesn't always offer me that ability), I decided to
				build my own.
			</p>

			<p>In this series, I will share some of my thoughts and insights from along the way, from a web developer
				and solo-tech founder point of view. This series is meant to inform and enlighten both aspiring and
				seasoned web devs who challenge themselves to create great things.</p>

			<p>I plan to explore topics across Photonomy's entire lifecycle-to-date, such as idea formation, getting
				started, designs, development, UI/UX, hosting, security, and scaling. Since I am a 1-man team,
				I didn't always follow the typical application lifecycle that a larger team might.</p>

			<p>Here's to hoping my industry insights will help those who seek knowledge.</p>
			<p class="center"><i class="fas fa-glass-cheers"></i></p>
		</div>
	</div>
</div>
<app-footer></app-footer>