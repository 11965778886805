import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, CurrencyPipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { GridsterModule } from 'angular-gridster2';
import { AngularSplitModule } from 'angular-split';
import { UserIdleModule } from 'angular-user-idle';
import { QRCodeModule } from 'angularx-qrcode';
import { NgImageSliderModule } from 'ng-image-slider';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxMasonryModule } from 'ngx-masonry';
import { NgProgressModule } from 'ngx-progressbar';
import { ToastrModule } from 'ngx-toastr';
import { SafePipeModule } from 'safe-pipe';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth-interceptor';
import { AuthGuard } from './guards/auth.guard';
import { ConnectionsComponent } from './pages/authenticated/connections/connections.component';
import { DashboardComponent } from './pages/authenticated/dashboard/dashboard.component';
import { EventCreateComponent } from './pages/authenticated/events/create/create.component';
import { EventsComponent } from './pages/authenticated/events/events.component';
import { FoldersComponent } from './pages/authenticated/folders/folders.component';
import { PhotosAddComponent } from './pages/authenticated/photos/add/add.component';
import { PhotosAddFacebookComponent } from './pages/authenticated/photos/add/facebook/facebook.component';
import { PhotosAddInstagramComponent } from './pages/authenticated/photos/add/instagram/instagram.component';
import { PhotosAddOnedriveComponent } from './pages/authenticated/photos/add/onedrive/onedrive.component';
import { PhotosAddUploadComponent } from './pages/authenticated/photos/add/upload/upload.component';
import { PhotoExploreComponent } from './pages/authenticated/photos/explore/explore.component';
import { PhotoActionContainerComponent } from './pages/authenticated/photos/explore/photo-action-container/photo-action-container.component';
import { ExpandedPhotoGalleryComponent } from './pages/authenticated/photos/gallery/expanded/expanded.component';
import { PinnedPhotoGalleryComponent } from './pages/authenticated/photos/gallery/pinned/pinned.component';
import { UncategorizedPreviewPhotoGalleryComponent } from './pages/authenticated/photos/gallery/uncategorized-preview/uncategorized-preview.component';
import { PhotosComponent } from './pages/authenticated/photos/photos.component';
import { PhotoViewComponent } from './pages/authenticated/photos/view/view.component';
import { UserProfileComponent } from './pages/authenticated/profile/profile.component';
import { UserTimelineComponent } from './pages/authenticated/timeline/timeline.component';
import { Blog01Component } from './pages/unauthenticated/blog/01/blog-01.component';
import { Blog02Component } from './pages/unauthenticated/blog/02/blog-02.component';
import { Blog03Component } from './pages/unauthenticated/blog/03/blog-03.component';
import { Blog04Component } from './pages/unauthenticated/blog/04/blog-04.component';
import { Blog05Component } from './pages/unauthenticated/blog/05/blog-05.component';
import { Blog06Component } from './pages/unauthenticated/blog/06/blog-06.component';
import { Blog07Component } from './pages/unauthenticated/blog/07/blog-07.component';
import { Blog08Component } from './pages/unauthenticated/blog/08/blog-08.component';
import { Blog09Component } from './pages/unauthenticated/blog/09/blog-09.component';
import { EventComponent } from './pages/unauthenticated/event/event.component';
import { EventPhotoActionContainerComponent } from './pages/unauthenticated/event/photo-action-container/photo-action-container.component';
import { ForgotPasswordComponent } from './pages/unauthenticated/forgot-password/forgot-password.component';
import { GalleryShareComponent } from './pages/unauthenticated/gallery/gallery-share.component';
import { IndexComponent } from './pages/unauthenticated/index/index.component';
import { LoginComponent } from './pages/unauthenticated/login/login.component';
import { PrivacyPolicyComponent } from './pages/unauthenticated/privacy-policy/privacy-policy.component';
import { RegisterComponent } from './pages/unauthenticated/register/register.component';
import { TermsOfServiceComponent } from './pages/unauthenticated/terms-of-service/terms-of-service.component';
import { AuthService } from './services/auth.service';
import { EventCommentService } from './services/event-comment.service';
import { EventService } from './services/event.service';
import { FacebookService } from './services/facebook.service';
import { FolderService } from './services/folder.service';
import { GalleryService } from './services/gallery.service';
import { InstagramService } from './services/instagram.service';
import { InvoiceService } from './services/invoice.service';
import { LayoutService } from './services/layout.service';
import { OnedriveService } from './services/onedrive.service';
import { PaymentService } from './services/payment.service';
import { PhotoCommentService } from './services/photo-comment.service';
import { PhotoService } from './services/photo.service';
import { SyncService } from './services/sync.service';
import { UserConnectionService } from './services/user-connection.service';
import { UserTranslationService } from './services/user-translation.service';
import { UserService } from './services/user.service';
import { CardConnectionCopyToClipboardComponent } from './shared/cards/connection-copy-to-clipboard/card-connection-copy-to-clipboard.component';
import { CardConnectionCreateNavComponent } from './shared/cards/connection-create-nav/card-connection-create-nav.component';
import { CardConnectionInviteComponent } from './shared/cards/connection-invite/card-connection-invite.component';
import { CardConnectionSendRequestComponent } from './shared/cards/connection-send-request/card-connection-send-request.component';
import { CardConnectionViewComponent } from './shared/cards/connection-view/card-connection-view.component';
import { CardConnectionsTitleComponent } from './shared/cards/connections-title/card-connections-title.component';
import { CardEventCreateTitleComponent } from './shared/cards/event-create-title/card-event-create-title.component';
import { CardEventViewComponent } from './shared/cards/event-view/card-event-view.component';
import { CardEventsAddNavComponent } from './shared/cards/events-add-nav/card-events-add-nav.component';
import { CardEventsTitleComponent } from './shared/cards/events-title/card-events-title.component';
import { CardGalleriesTitleComponent } from './shared/cards/galleries-title/card-galleries-title.component';
import { CardGalleryPinComponent } from './shared/cards/gallery-pin/card-gallery-pin.component';
import { CardGalleryPinnedComponent } from './shared/cards/gallery-pinned/card-gallery-pinned.component';
import { CardGalleryUncategorizedPhotosComponent } from './shared/cards/gallery-uncategorized-photos/card-gallery-uncategorized-photos.component';
import { CardPaymentMethodManageNavComponent } from './shared/cards/payment-method-manage-nav/payment-method-manage-nav.component';
import { CardPhotosAddNavComponent } from './shared/cards/photos-add-nav/card-photos-add-nav.component';
import { CardPhotosSyncAmazonPhotosNavComponent } from './shared/cards/photos-sync-amazon-photos-nav/card-photos-sync-amazon-photos-nav.component';
import { CardPhotosSyncAppleIcloudNavComponent } from './shared/cards/photos-sync-apple-icloud-nav/card-photos-sync-apple-icloud-nav.component';
import { CardPhotosSyncDropboxNavComponent } from './shared/cards/photos-sync-dropbox-nav/card-photos-sync-dropbox-nav.component';
import { CardPhotosSyncFacebookNavComponent } from './shared/cards/photos-sync-facebook-nav/card-photos-sync-facebook-nav.component';
import { CardPhotosSyncGooglePhotosNavComponent } from './shared/cards/photos-sync-google-photos-nav/card-photos-sync-google-photos-nav.component';
import { CardPhotosSyncInstagramNavComponent } from './shared/cards/photos-sync-instagram-nav/card-photos-sync-instagram-nav.component';
import { CardPhotosSyncMicrosoftOnedriveNavComponent } from './shared/cards/photos-sync-microsoft-onedrive-nav/card-photos-sync-microsoft-onedrive-nav.component';
import { CardPhotosSyncNavComponent } from './shared/cards/photos-sync-nav/card-photos-sync-nav.component';
import { CardPhotosSyncTitleComponent } from './shared/cards/photos-sync-title/card-photos-sync-title.component';
import { CardPhotosUploadNavComponent } from './shared/cards/photos-upload-nav/card-photos-upload-nav.component';
import { CardStartHereDescriptionComponent } from './shared/cards/start-here-description/card-start-here-description';
import { CardStartHereComponent } from './shared/cards/start-here/card-start-here';
import { CardUserSettingsTitleComponent } from './shared/cards/user-settings-title/card-user-settings-title.component';
import { EventCommentComponent } from './shared/event-comment/event-comment.component';
import { ExploreConnectionIconComponent } from './shared/explore-connection-icon/explore-connection-icon.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { InfiniteScrollComponent } from './shared/infinite-scroll/infinite-scroll.component';
import { PaymentMethodManageComponent } from './shared/payment-method-manage/payment-method-manage.component';
import { PhotoCommentComponent } from './shared/photo-comment/photo-comment.component';
import { ProfileConnectionIconComponent } from './shared/profile-connection-icon/profile-connection-icon.component';
import { AddPhotoToEventProgressComponent } from './shared/progress-containers/add-photo-to-event/add-photo-to-event.component';
import { RemovePhotoFromEventProgressComponent } from './shared/progress-containers/remove-photo-from-event/remove-photo-from-event.component';
import { UploadPhotoProgressComponent } from './shared/progress-containers/upload-photo/upload-photo.component';
import { SharedModule } from './shared/shared.module';
import { WidgetConnectionsQuickLinksComponent } from './shared/widgets/connections-quick-links/widget-connections-quick-links.component';
import { WidgetGalleryUncategorizedPhotosComponent } from './shared/widgets/gallery-uncategorized-photos/widget-gallery-uncategorized-photos.component';
import { WidgetPhotosMyUncategorizedGraphComponent } from './shared/widgets/my-photos-uncategorized-graph/widget-photos-my-uncategorized-graph.component';
import { WidgetPhotoStorageGraphComponent } from './shared/widgets/photo-storage-graph/widget-photo-storage-graph.component';
import { WidgetPhotosConnectionsGraphComponent } from './shared/widgets/photos-connections-graph/widget-photos-connections-graph.component';
import { WidgetPhotosMyUploadedGraphComponent } from './shared/widgets/photos-my-uploaded-graph/widget-photos-my-uploaded-graph.component';
import { WidgetPhotosQuickLinksComponent } from './shared/widgets/photos-quick-links/widget-photos-quick-links.component';
import { UserMessageComponent } from './shared/widgets/user-messages/user-message/user-message.component';
import { UserMessagesComponent } from './shared/widgets/user-messages/user-messages.component';
import { WidgetWelcomeMessageComponent } from './shared/widgets/welcome-message/widget-welcome-message.component';
import { DashboardWidgetComponent } from './shared/widgets/widget.component';
import { SpinnerDirective } from './spinner.directive';

@NgModule({
	declarations: [
		AddPhotoToEventProgressComponent,
		AppComponent,
		Blog01Component,
		Blog02Component,
		Blog03Component,
		Blog04Component,
		Blog05Component,
		Blog06Component,
		Blog07Component,
		Blog08Component,
		Blog09Component,
		CardConnectionCopyToClipboardComponent,
		CardConnectionCreateNavComponent,
		CardConnectionInviteComponent,
		CardConnectionSendRequestComponent,
		CardConnectionViewComponent,
		CardConnectionsTitleComponent,
		CardEventCreateTitleComponent,
		CardEventViewComponent,
		CardEventsAddNavComponent,
		CardEventsTitleComponent,
		CardGalleriesTitleComponent,
		CardGalleryPinComponent,
		CardGalleryPinnedComponent,
		CardGalleryUncategorizedPhotosComponent,
		CardPaymentMethodManageNavComponent,
		CardPhotosAddNavComponent,
		CardPhotosSyncAmazonPhotosNavComponent,
		CardPhotosSyncAppleIcloudNavComponent,
		CardPhotosSyncDropboxNavComponent,
		CardPhotosSyncFacebookNavComponent,
		CardPhotosSyncGooglePhotosNavComponent,
		CardPhotosSyncInstagramNavComponent,
		CardPhotosSyncMicrosoftOnedriveNavComponent,
		CardPhotosSyncNavComponent,
		CardPhotosSyncTitleComponent,
		CardPhotosUploadNavComponent,
		CardStartHereComponent,
		CardStartHereDescriptionComponent,
		CardUserSettingsTitleComponent,
		ConnectionsComponent,
		DashboardComponent,
		DashboardWidgetComponent,
		EventCommentComponent,
		EventComponent,
		EventCreateComponent,
		EventPhotoActionContainerComponent,
		EventsComponent,
		ExpandedPhotoGalleryComponent,
		ExploreConnectionIconComponent,
		FoldersComponent,
		FooterComponent,
		ForgotPasswordComponent,
		GalleryShareComponent,
		HeaderComponent,
		IndexComponent,
		InfiniteScrollComponent,
		LoginComponent,
		PaymentMethodManageComponent,
		PhotoActionContainerComponent,
		PhotoCommentComponent,
		PhotoExploreComponent,
		PhotoViewComponent,
		PhotosAddComponent,
		PhotosAddFacebookComponent,
		PhotosAddInstagramComponent,
		PhotosAddOnedriveComponent,
		PhotosAddUploadComponent,
		PhotosComponent,
		PinnedPhotoGalleryComponent,
		PrivacyPolicyComponent,
		ProfileConnectionIconComponent,
		RegisterComponent,
		RemovePhotoFromEventProgressComponent,
		SpinnerDirective,
		TermsOfServiceComponent,
		UncategorizedPreviewPhotoGalleryComponent,
		UploadPhotoProgressComponent,
		UserMessageComponent,
		UserMessagesComponent,
		UserProfileComponent,
		UserTimelineComponent,
		WidgetConnectionsQuickLinksComponent,
		WidgetGalleryUncategorizedPhotosComponent,
		WidgetPhotoStorageGraphComponent,
		WidgetPhotosConnectionsGraphComponent,
		WidgetPhotosMyUncategorizedGraphComponent,
		WidgetPhotosMyUploadedGraphComponent,
		WidgetPhotosQuickLinksComponent,
		WidgetWelcomeMessageComponent
	],
	imports: [
		AngularSplitModule.forRoot(),
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
		CdkTableModule,
		CollapseModule,
		ColorPickerModule,
		CommonModule,
		FormsModule,
		GridsterModule,
		HttpClientModule,
		MatPaginatorModule,
		MatSortModule,
		MatTableModule,
		NgImageSliderModule,
		NgProgressModule,
		NgSelectModule,
		NgxDropzoneModule,
		NgxMasonryModule,
		QRCodeModule,
		ReactiveFormsModule,
		SafePipeModule,
		SharedModule,
		TabsModule.forRoot(),
		/**
		MsalModule.forRoot(
		  {
			auth: {
			  clientId: "d8505678-00fd-4898-b69c-f296543c22d7",
			  redirectUri: "http://localhost/"
			}
		  }, {
			protectedResourceMap: [
				['https://graph.microsoft.com/v1.0/me', ['user.read']]
			]
		  }
		), */
		/**
		MsalModule.forRoot(
			{
				auth: {
					clientId: "d8505678-00fd-4898-b69c-f296543c22d7"
				}
			}
		),*/
		ToastrModule.forRoot({
			timeOut: 4000,
			extendedTimeOut: 0,
			progressBar: true,
			progressAnimation: 'decreasing',
			positionClass: 'container-alerts',
			toastClass: 'alert',
			iconClasses: {
				error: 'alert-danger',
				info: 'alert-info',
				success: 'alert-success',
				warning: 'alert-warning'
			}
		}),
		TooltipModule.forRoot(),
		TypeaheadModule.forRoot(),
		UserIdleModule.forRoot({ idle: 900, timeout: 120, ping: 300 })
	],
	exports: [
		TabsModule
	],
	providers: [
		AuthService,
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		AuthGuard,
		AuthInterceptor,
		CurrencyPipe,
		EventCommentService,
		EventService,
		FacebookService,
		FolderService,
		GalleryService,
		InstagramService,
		InvoiceService,
		LayoutService,
		OnedriveService,
		PaymentService,
		PhotoCommentService,
		PhotoService,
		SyncService,
		UserConnectionService,
		UserService,
		UserTranslationService
		/**{ provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true }**/
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
