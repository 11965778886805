import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { ConnectionsComponent } from './pages/authenticated/connections/connections.component';
import { DashboardComponent } from './pages/authenticated/dashboard/dashboard.component';
import { EventCreateComponent } from './pages/authenticated/events/create/create.component';
import { EventsComponent } from './pages/authenticated/events/events.component';
import { FoldersComponent } from './pages/authenticated/folders/folders.component';
import { PhotosAddComponent } from './pages/authenticated/photos/add/add.component';
import { PhotosAddFacebookComponent } from './pages/authenticated/photos/add/facebook/facebook.component';
import { PhotosAddInstagramComponent } from './pages/authenticated/photos/add/instagram/instagram.component';
import { PhotosAddOnedriveComponent } from './pages/authenticated/photos/add/onedrive/onedrive.component';
import { PhotosAddUploadComponent } from './pages/authenticated/photos/add/upload/upload.component';
import { PhotoExploreComponent } from './pages/authenticated/photos/explore/explore.component';
import { PhotosComponent } from './pages/authenticated/photos/photos.component';
import { PhotoViewComponent } from './pages/authenticated/photos/view/view.component';
import { UserProfileComponent } from './pages/authenticated/profile/profile.component';
import { UserTimelineComponent } from './pages/authenticated/timeline/timeline.component';
import { Blog01Component } from './pages/unauthenticated/blog/01/blog-01.component';
import { Blog02Component } from './pages/unauthenticated/blog/02/blog-02.component';
import { Blog03Component } from './pages/unauthenticated/blog/03/blog-03.component';
import { Blog04Component } from './pages/unauthenticated/blog/04/blog-04.component';
import { Blog05Component } from './pages/unauthenticated/blog/05/blog-05.component';
import { Blog06Component } from './pages/unauthenticated/blog/06/blog-06.component';
import { EventComponent } from './pages/unauthenticated/event/event.component';
import { ForgotPasswordComponent } from './pages/unauthenticated/forgot-password/forgot-password.component';
import { GalleryShareComponent } from './pages/unauthenticated/gallery/gallery-share.component';
import { IndexComponent } from './pages/unauthenticated/index/index.component';
import { LoginComponent } from './pages/unauthenticated/login/login.component';
import { PrivacyPolicyComponent } from './pages/unauthenticated/privacy-policy/privacy-policy.component';
import { RegisterComponent } from './pages/unauthenticated/register/register.component';
import { TermsOfServiceComponent } from './pages/unauthenticated/terms-of-service/terms-of-service.component';

const appRoutes: Routes = [
	{ path: '', component: IndexComponent },
	{ path: 'register/:userUuid/:emailVerifyCode', component: RegisterComponent },
	{ path: 'login/forgot-password', component: ForgotPasswordComponent },
	{ path: 'privacy', component: PrivacyPolicyComponent, data: { scrollPositionRestoration: 'top' } },
	{ path: 'terms', component: TermsOfServiceComponent, data: { scrollPositionRestoration: 'top' } },
	{ path: 'blog', component: Blog01Component },
	{ path: 'blog/01', component: Blog01Component },
	{ path: 'blog/02', component: Blog02Component },
	{ path: 'blog/03', component: Blog03Component },
	{ path: 'blog/04', component: Blog04Component },
	{ path: 'blog/05', component: Blog05Component },
	{ path: 'blog/06', component: Blog06Component },
	// { path: 'blog/07', component: Blog07Component },
	// { path: 'blog/08', component: Blog08Component },
	// { path: 'blog/09', component: Blog09Component },
	{ path: 'login', component: LoginComponent },
	{ path: 'login/:userUuid/:emailVerifyCode', component: LoginComponent },
	{ path: 'login/reset/:resetEmail/:resetPasswordCode', component: LoginComponent },
	{ path: 'login/reset/:resetToken', component: LoginComponent },
	{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
	{ path: 'photos', component: PhotosComponent, canActivate: [AuthGuard] },
	{ path: 'photo/add', component: PhotosAddComponent, canActivate: [AuthGuard] },
	{ path: 'photo/add/facebook', component: PhotosAddFacebookComponent, canActivate: [AuthGuard] },
	{ path: 'photo/add/instagram', component: PhotosAddInstagramComponent, canActivate: [AuthGuard] },
	{ path: 'photo/add/onedrive', component: PhotosAddOnedriveComponent, canActivate: [AuthGuard] },
	{ path: 'photo/add/upload', component: PhotosAddUploadComponent, canActivate: [AuthGuard] },
	{ path: 'photo/:photoId', component: PhotoViewComponent, canActivate: [AuthGuard] },
	{ path: 'gallery/:shareCode1/:shareCode2', component: GalleryShareComponent },
	{ path: 'connections', component: ConnectionsComponent, canActivate: [AuthGuard] },
	{ path: 'connection/:connectionId', component: ConnectionsComponent, canActivate: [AuthGuard] },
	{ path: 'explore', component: PhotoExploreComponent, canActivate: [AuthGuard] },
	{ path: 'profile', component: UserProfileComponent, canActivate: [AuthGuard] },
	{ path: 'timeline', component: UserTimelineComponent, canActivate: [AuthGuard] },
	{ path: 'folders', component: FoldersComponent, canActivate: [AuthGuard] },
	{ path: 'events', component: EventsComponent, canActivate: [AuthGuard] },
	{ path: 'event/create', component: EventCreateComponent, canActivate: [AuthGuard] },
	{ path: 'event/:shareCode1/:shareCode2', component: EventComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes)],
	exports: [RouterModule]
})
export class AppRoutingModule {

}
