import { Folder } from "./folder.model";
import { Photo } from "./photo.model";

export class PhotoUpload {
	public file: any;
	public photo: Photo;
	public form: any;
	public formLocation: any;
	public event: any;
	public uploadToAccount: any; // This is used when uploading to another users event to specify whose account the upload should go to
	public folder: Folder;

	public status;
	public errorMessage;
	public queueNumber;
	public signedUploadUrlNumber;
}