import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as EmailValidator from 'email-validator';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Event } from 'src/app/models/event.model';
import { User } from 'src/app/models/user.model';
import { EventService } from 'src/app/services/event.service';
import { PhotoService } from 'src/app/services/photo.service';
import { UserConnectionService } from 'src/app/services/user-connection.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-event-create',
	templateUrl: './create.component.html',
	styleUrls: ['./create.component.css']

})
export class EventCreateComponent implements OnInit, OnDestroy {
	@ViewChild('confirmCreateDialog', { static: true }) confirmCreateDialog: TemplateRef<any>;
	@ViewChild('managePaymentMethodDialog', { static: true }) managePaymentMethodDialog: TemplateRef<any>;
	@ViewChild('beneficiaryUserOrEmailSelect') beneficiaryUserOrEmailSelect;
	@ViewChild('moderatorUsersOrEmailsSelect') moderatorUsersOrEmailsSelect;
	@ViewChild('memberUsersOrEmailsSelect') memberUsersOrEmailsSelect;

	modalRef: BsModalRef;

	loadingConnections = true;
	loadingUserPaymentMethods = true;
	loadingCreateEvent = false;

	form: UntypedFormGroup;

	error = '';

	user: User;
	userChangedSubscription: Subscription;
	userPaymentMethodsExist = false;

	users: any[] = [];
	tempEmail = '';

	showStartTime = false;
	showEndDate = false;
	showEndTime = false;

	addedEvent: Event;

	displayAddPhotos = false;
	preExistingPhotos = [];
	preExistingPhotoImages: any[] = [];

	location = '';
	locationEmbedUrl = '';
	locationSuggestions = [];

	constructor(
		private connectionService: UserConnectionService,
		private eventService: EventService,
		private photoService: PhotoService,
		private userService: UserService,
		private formBuilder: UntypedFormBuilder,
		private router: Router
	) { }

	ngOnInit() {
		this.subscribeToUserChanged();

		this.initForm();
		this.retrieveConnectionsActive();

		if (this.userService.users[0]) {
			this.user = this.userService.users[0];

			this.checkUserPaymentMethods();
		}
	}

	ngOnDestroy(): void {
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	subscribeToUserChanged() {
		this.userChangedSubscription = this.userService.userChanged.subscribe(
			user => {
				if (user.id == this.userService.users[0].id) {
					this.user = user;

					this.checkUserPaymentMethods();
				}
			}
		);
	}

	initForm() {
		this.form = this.formBuilder.group({
			/**
			'type': new FormControl(null, [
				Validators.required,
			]), */
			'name': new UntypedFormControl(null, [
				Validators.required,
			]),
			'details': new UntypedFormControl(null, []),
			'startDate': new UntypedFormControl(null, [
				Validators.required,
			]),
			'startTimeHour': new UntypedFormControl('01', []),
			'startTimeMinute': new UntypedFormControl('00', []),
			'startTimeMeridiem': new UntypedFormControl('PM', []),
			'endDate': new UntypedFormControl(null, []),
			'endTimeHour': new UntypedFormControl('01', []),
			'endTimeMinute': new UntypedFormControl('00', []),
			'endTimeMeridiem': new UntypedFormControl('PM', []),
			'location': new UntypedFormControl(null, []),
			'locationShowMap': new UntypedFormControl(true, []),
			'canMembersContribute': new UntypedFormControl(true, [
				Validators.required,
			]),
			'canMembersDiscuss': new UntypedFormControl(true, [
				Validators.required,
			]),
			'canMembersShare': new UntypedFormControl(true, [
				Validators.required,
			]),
			'canConnectionsView': new UntypedFormControl(true, [
				Validators.required,
			]),
			'canConnectionsContribute': new UntypedFormControl(true, [
				Validators.required,
			]),
			'canConnectionsDiscuss': new UntypedFormControl(true, [
				Validators.required,
			]),
			'canConnectionsShare': new UntypedFormControl(true, [
				Validators.required,
			]),
			'canConnectionsJoin': new UntypedFormControl(true, [
				Validators.required,
			]),
			'canPublicView': new UntypedFormControl(true, [
				Validators.required,
			]),
			'canPublicContribute': new UntypedFormControl(false, [
				Validators.required,
			]),
			'canPublicShare': new UntypedFormControl(false, [
				Validators.required,
			]),
			'addToMyTimeline': new UntypedFormControl(false, [
				Validators.required,
			]),
			'memberUsersOrEmails': new UntypedFormControl(null, []),
		});
	}

	checkUserPaymentMethods() {
		if (this.user.paymentMethodId) {
			let now = new Date(); // 5/13/22
			let expireDate = new Date(this.user.paymentMethodCcExpirationYear, this.user.paymentMethodCcExpirationMonth, 0);

			if (expireDate > now) {
				this.userPaymentMethodsExist = true;
			}

			this.loadingUserPaymentMethods = false;
		} else {
			this.loadingUserPaymentMethods = false;
		}
	}

	retrieveConnectionsActive() {
		this.loadingConnections = true;
		this.connectionService.getUserConnectionsActive().subscribe(
			response => {
				if (response.status === 200) {
					let users = [];

					for (const user of response.body) {
						users.push({ id: user.id, name: user.firstName + ' ' + user.lastName });
					}

					this.users = users;

					this.loadingConnections = false;
				} else {
					this.loadingConnections = false;
				}
			},
			err => {
				this.loadingConnections = false;
			}
		);
	}

	/** PERMISSIONS FUNCTIONS */

	canConnectionsViewChanged(event) {
		if (!event.target.checked) {
			let canConnectionsContribute = { 'canConnectionsContribute': false };
			this.form.patchValue(canConnectionsContribute);

			let canConnectionsShare = { 'canConnectionsShare': false };
			this.form.patchValue(canConnectionsShare);

			let canConnectionsDiscuss = { 'canConnectionsDiscuss': false };
			this.form.patchValue(canConnectionsDiscuss);

			let canConnectionsJoin = { 'canConnectionsJoin': false };
			this.form.patchValue(canConnectionsJoin);

			let canPublicView = { 'canPublicView': false };
			this.form.patchValue(canPublicView);

			let canPublicContribute = { 'canPublicContribute': false };
			this.form.patchValue(canPublicContribute);

			let canPublicShare = { 'canPublicShare': false };
			this.form.patchValue(canPublicShare);
		}
	}

	canPublicViewChanged(event) {
		if (!event.target.checked) {
			let canPublicContribute = { 'canPublicContribute': false };
			this.form.patchValue(canPublicContribute);

			let canPublicShare = { 'canPublicShare': false };
			this.form.patchValue(canPublicShare);
		} else {
			let canConnectionsView = { 'canConnectionsView': true };
			this.form.patchValue(canConnectionsView);
		}
	}

	canMembersContributeChanged(event) {
		if (!event.target.checked) {
			let canConnectionsContribute = { 'canConnectionsContribute': false };
			this.form.patchValue(canConnectionsContribute);

			let canPublicContribute = { 'canPublicContribute': false };
			this.form.patchValue(canPublicContribute);
		}
	}

	canConnectionsContributeChanged(event) {
		if (!event.target.checked) {
			let canPublicContribute = { 'canPublicContribute': false };
			this.form.patchValue(canPublicContribute);
		} else {
			let canConnectionsView = { 'canConnectionsView': true };
			this.form.patchValue(canConnectionsView);

			let canMembersContribute = { 'canMembersContribute': true };
			this.form.patchValue(canMembersContribute);
		}
	}

	canPublicContributeChanged(event) {
		if (event.target.checked) {
			let canMembersContribute = { 'canMembersContribute': true };
			this.form.patchValue(canMembersContribute);

			let canConnectionsContribute = { 'canConnectionsContribute': true };
			this.form.patchValue(canConnectionsContribute);

			let canConnectionsView = { 'canConnectionsView': true };
			this.form.patchValue(canConnectionsView);

			let canPublicView = { 'canPublicView': true };
			this.form.patchValue(canPublicView);
		}
	}

	canMembersShareChanged(event) {
		if (!event.target.checked) {
			let canConnectionsShare = { 'canConnectionsShare': false };
			this.form.patchValue(canConnectionsShare);

			let canPublicShare = { 'canPublicShare': false };
			this.form.patchValue(canPublicShare);
		}
	}

	canConnectionsShareChanged(event) {
		if (!event.target.checked) {
			let canPublicShare = { 'canPublicShare': false };
			this.form.patchValue(canPublicShare);
		} else {
			let canConnectionsView = { 'canConnectionsView': true };
			this.form.patchValue(canConnectionsView);

			let canMembersShare = { 'canMembersShare': true };
			this.form.patchValue(canMembersShare);
		}
	}

	canPublicShareChanged(event) {
		if (event.target.checked) {
			let canMembersShare = { 'canMembersShare': true };
			this.form.patchValue(canMembersShare);

			let canConnectionsShare = { 'canConnectionsShare': true };
			this.form.patchValue(canConnectionsShare);

			let canConnectionsView = { 'canConnectionsView': true };
			this.form.patchValue(canConnectionsView);

			let canPublicView = { 'canPublicView': true };
			this.form.patchValue(canPublicView);
		}
	}

	canMembersDiscussChanged(event) {
		if (!event.target.checked) {
			let canConnectionsDiscuss = { 'canConnectionsDiscuss': false };
			this.form.patchValue(canConnectionsDiscuss);
		}
	}

	canConnectionsDiscussChanged(event) {
		if (event.target.checked) {
			let canConnectionsView = { 'canConnectionsView': true };
			this.form.patchValue(canConnectionsView);

			let canMembersDiscuss = { 'canMembersDiscuss': true };
			this.form.patchValue(canMembersDiscuss);
		}
	}

	canConnectionsJoinChanged(event) {
		if (event.target.checked) {
			let canConnectionsView = { 'canConnectionsView': true };
			this.form.patchValue(canConnectionsView);
		}
	}

	/**
	 * CREATE EVENT
	 * 
	 * 1. Create an event object from the form values.
	 * 2. Handle start date and time
	 * 3. Handle end date and time
	 * 4. Create the event.
	 * 5. TODO: Add members to the event.
	 * 6. TODO: Detect if photos already exist.
	 * 7. TODO: Navigate to the Events page.
	 */
	createEvent() {
		this.loadingCreateEvent = true;

		// 1
		let event = new Event();
		event.name = this.form.value.name;
		event.details = this.form.value.details;
		event.location = this.form.value.location;
		event.locationShowMap = this.form.value.locationShowMap;
		event.canMembersContribute = this.form.value.canMembersContribute;
		event.canMembersDiscuss = this.form.value.canMembersDiscuss;
		event.canMembersShare = this.form.value.canMembersShare;
		event.canConnectionsView = this.form.value.canConnectionsView;
		event.canConnectionsContribute = this.form.value.canConnectionsContribute;
		event.canConnectionsDiscuss = this.form.value.canConnectionsDiscuss;
		event.canConnectionsShare = this.form.value.canConnectionsShare;
		event.canConnectionsJoin = this.form.value.canConnectionsJoin;
		event.canPublicView = this.form.value.canPublicView;
		event.canPublicContribute = this.form.value.canPublicContribute;
		event.canPublicShare = this.form.value.canPublicShare;
		event.addToOwnerTimeline = this.form.value.addToMyTimeline;

		// 2. Handle start date and time
		let startDate = new Date(this.form.value.startDate);

		// TODO: Handle unselected date field
		//			Wed Dec 31 1969 00:00:00 GMT-0500 (Eastern Standard Time)

		if (this.showStartTime) {
			// Date + time
			event.startDateIncludeTime = true;

			let startTimeHour = this.form.value.startTimeHour;
			let startTimeMinute = this.form.value.startTimeMinute;
			let startTimeMeridiem = this.form.value.startTimeMeridiem;

			// 12:00 AM = MIDNIGHT		// 0
			// 01:00 AM					// 1
			// 02:00 AM					// 2
			// 03:00 AM					// 3
			// 04:00 AM					// 4
			// 05:00 AM					// 5
			// 06:00 AM					// 6
			// 07:00 AM					// 7
			// 08:00 AM					// 8
			// 09:00 AM					// 9
			// 10:00 AM					// 10
			// 11:00 AM					// 11

			// 12:00 PM = NOON			// 12
			// 01:00 PM					// 13
			// 02:00 PM					// 14
			// 03:00 PM					// 15
			// 04:00 PM					// 16
			// 05:00 PM					// 17
			// 06:00 PM					// 18
			// 07:00 PM					// 19
			// 08:00 PM					// 20
			// 09:00 PM					// 21
			// 10:00 PM					// 22
			// 11:00 PM					// 23

			if (startTimeMeridiem == 'AM') {
				if (startTimeHour == '12') {
					startTimeHour = 0;
				} else if (startTimeHour == '01') {
					startTimeHour = 1;
				} else if (startTimeHour == '02') {
					startTimeHour = 2;
				} else if (startTimeHour == '03') {
					startTimeHour = 3;
				} else if (startTimeHour == '04') {
					startTimeHour = 4;
				} else if (startTimeHour == '05') {
					startTimeHour = 5;
				} else if (startTimeHour == '06') {
					startTimeHour = 6;
				} else if (startTimeHour == '07') {
					startTimeHour = 7;
				} else if (startTimeHour == '08') {
					startTimeHour = 8;
				} else if (startTimeHour == '09') {
					startTimeHour = 9;
				} else if (startTimeHour == '10') {
					startTimeHour = 10;
				} else if (startTimeHour == '11') {
					startTimeHour = 11;
				}
			} else {
				if (startTimeHour == '12') {
					startTimeHour = 12;
				} else if (startTimeHour == '01') {
					startTimeHour = 13;
				} else if (startTimeHour == '02') {
					startTimeHour = 14;
				} else if (startTimeHour == '03') {
					startTimeHour = 15;
				} else if (startTimeHour == '04') {
					startTimeHour = 16;
				} else if (startTimeHour == '05') {
					startTimeHour = 17;
				} else if (startTimeHour == '06') {
					startTimeHour = 18;
				} else if (startTimeHour == '07') {
					startTimeHour = 19;
				} else if (startTimeHour == '08') {
					startTimeHour = 20;
				} else if (startTimeHour == '09') {
					startTimeHour = 21;
				} else if (startTimeHour == '10') {
					startTimeHour = 22;
				} else if (startTimeHour == '11') {
					startTimeHour = 23;
				}
			}

			if (startTimeMinute == '00') {
				startTimeMinute = 0;
			} else if (startTimeMinute == '15') {
				startTimeMinute = 15;
			} else if (startTimeMinute == '30') {
				startTimeMinute = 30;
			} else if (startTimeMinute == '45') {
				startTimeMinute = 45;
			}

			startDate.setHours(startTimeHour, startTimeMinute, 0, 0);
		} else {
			// Date only
			event.startDateIncludeTime = false;

			startDate.setHours(0, 0, 0, 0);
		}
		event.startDateTime = startDate;

		// 3. Handle end date and time
		if (this.showEndDate) {
			let endDate = new Date(this.form.value.endDate);

			// TODO: Handle unselected date field
			//			Wed Dec 31 1969 00:00:00 GMT-0500 (Eastern Standard Time)

			if (this.showEndTime) {
				// Date + time
				event.endDateIncludeTime = true;

				let endTimeHour = this.form.value.endTimeHour;
				let endTimeMinute = this.form.value.endTimeMinute;
				let endTimeMeridiem = this.form.value.endTimeMeridiem;

				if (endTimeMeridiem == 'AM') {
					if (endTimeHour == '12') {
						endTimeHour = 0;
					} else if (endTimeHour == '01') {
						endTimeHour = 1;
					} else if (endTimeHour == '02') {
						endTimeHour = 2;
					} else if (endTimeHour == '03') {
						endTimeHour = 3;
					} else if (endTimeHour == '04') {
						endTimeHour = 4;
					} else if (endTimeHour == '05') {
						endTimeHour = 5;
					} else if (endTimeHour == '06') {
						endTimeHour = 6;
					} else if (endTimeHour == '07') {
						endTimeHour = 7;
					} else if (endTimeHour == '08') {
						endTimeHour = 8;
					} else if (endTimeHour == '09') {
						endTimeHour = 9;
					} else if (endTimeHour == '10') {
						endTimeHour = 10;
					} else if (endTimeHour == '11') {
						endTimeHour = 11;
					}
				} else {
					if (endTimeHour == '12') {
						endTimeHour = 12;
					} else if (endTimeHour == '01') {
						endTimeHour = 13;
					} else if (endTimeHour == '02') {
						endTimeHour = 14;
					} else if (endTimeHour == '03') {
						endTimeHour = 15;
					} else if (endTimeHour == '04') {
						endTimeHour = 16;
					} else if (endTimeHour == '05') {
						endTimeHour = 17;
					} else if (endTimeHour == '06') {
						endTimeHour = 18;
					} else if (endTimeHour == '07') {
						endTimeHour = 19;
					} else if (endTimeHour == '08') {
						endTimeHour = 20;
					} else if (endTimeHour == '09') {
						endTimeHour = 21;
					} else if (endTimeHour == '10') {
						endTimeHour = 22;
					} else if (endTimeHour == '11') {
						endTimeHour = 23;
					}
				}

				if (endTimeMinute == '00') {
					endTimeMinute = 0;
				} else if (endTimeMinute == '15') {
					endTimeMinute = 15;
				} else if (endTimeMinute == '30') {
					endTimeMinute = 30;
				} else if (endTimeMinute == '45') {
					endTimeMinute = 45;
				}

				endDate.setHours(endTimeHour, endTimeMinute, 0, 0);
			} else {
				// Date only
				event.endDateIncludeTime = false;

				endDate.setHours(23, 59, 0, 0);
			}
			event.endDateTime = endDate;
		}

		if (!event.endDateTime || (event.endDateTime > event.startDateTime)) {
			this.error = '';

			// 4
			this.eventService.createEvent(event).subscribe(
				response => {
					if (response.status === 200) {
						let event: Event = response.body;

						// Add the event to the page and service caches.
						this.addedEvent = event;
						this.eventService.eventsAsOwner.push(event);

						let url = '/event/' + event.shareCode1 + '/' + event.shareCode2;
						this.navigate(url);
					} else {
						this.error = 'An error occurred.';
						this.loadingCreateEvent = false;
					}
				},
				err => {
					this.error = err.error.message;
					this.loadingCreateEvent = false;
				}
			);
		} else if (event.startDateTime > event.endDateTime) {
			this.error = 'The event end date must be after the start date.';
		} else {
			this.error = 'The event cannot start and end at the same time.';
		}
	}

	/**
	 * MEMBER USERS OR EMAILS KEYUP
	 * 
	 * Sets the temporary location variable to the keyword that is being typed by the user. This happens on every keypress.
	 * This will be used when creating new keywords.
	 */
	memberUsersOrEmailsKeyup() {
		this.tempEmail = this.memberUsersOrEmailsSelect.searchTerm;
	}

	/**
	 * MEMBER USERS OR EMAILS BLUR
	 * 
	 * @param event 
	 */
	memberUsersOrEmailsBlur() {
		if (this.tempEmail && this.tempEmail.length > 0) {
			// TODO: First make sure they are not selecting a term.  For example, if they 
			//          type 'we' and select 'wedding', it adds 'we' to the list which is not intended.

			// TODO: Make sure the value is an email address format.

			// Determine if the item already exists.
			let item = this.memberUsersOrEmailsSelect.itemsList.findByLabel(this.tempEmail);
			if (item) {
				// Item already exists, select it.
				this.memberUsersOrEmailsSelect.select(item);
				this.tempEmail = '';
			} else {
				// Ensure this is a valid email address format.
				if (EmailValidator.validate(this.tempEmail)) {
					this.users.push({ id: this.tempEmail, name: this.tempEmail, type: 'new' });
					this.users.sort((a, b) => a.name.localeCompare(b.name));
					this.users = this.users.slice();

					// Select the item.
					// TODO: There has to be a better way than a setTimeout for this.
					const _this = this;
					setTimeout(function () {
						let item = _this.memberUsersOrEmailsSelect.itemsList.findByLabel(_this.tempEmail);
						if (item) {
							_this.memberUsersOrEmailsSelect.select(item);
							_this.tempEmail = '';
						}
					}, 200);
				}
			}
		}
	}

	/**
	 * MEMBER USERS OR EMAILS CHANGE
	 * 
	 * Set the temporary email to blank.
	 */
	memberUsersOrEmailsChange(event) {
		this.tempEmail = '';
	}

	/**
	 * MEMBER USERS OR EMAILS UNSELECT
	 * 
	 * @param email
	 */
	memberUsersOrEmailsUnselect(email) {
		for (let i = 0; i < this.memberUsersOrEmailsSelect.itemsList.items.length; i++) {
			if (this.memberUsersOrEmailsSelect.itemsList.items[i].value === email.value) {
				this.memberUsersOrEmailsSelect.itemsList.items.splice(i, 1);
			}
		}
	}

	memberUsersOrEmailsAdd(item) {
		// Unselect the item from the member dropdown first.
		let moderatorItem = this.moderatorUsersOrEmailsSelect.itemsList.findByLabel(item.name);
		if (moderatorItem) {
			this.moderatorUsersOrEmailsSelect.unselect(moderatorItem);
		}
	}


	increaseEndHour() {
		if (this.form.value.endTimeHour == '01') {
			let value = { 'endTimeHour': '02' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '02') {
			let value = { 'endTimeHour': '03' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '03') {
			let value = { 'endTimeHour': '04' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '04') {
			let value = { 'endTimeHour': '05' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '05') {
			let value = { 'endTimeHour': '06' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '06') {
			let value = { 'endTimeHour': '07' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '07') {
			let value = { 'endTimeHour': '08' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '08') {
			let value = { 'endTimeHour': '09' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '09') {
			let value = { 'endTimeHour': '10' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '10') {
			let value = { 'endTimeHour': '11' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '11') {
			let value = { 'endTimeHour': '12' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '12') {
			let value = { 'endTimeHour': '01' };
			this.form.patchValue(value);
		}
	}

	decreaseEndHour() {
		if (this.form.value.endTimeHour == '01') {
			let value = { 'endTimeHour': '12' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '02') {
			let value = { 'endTimeHour': '01' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '03') {
			let value = { 'endTimeHour': '02' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '04') {
			let value = { 'endTimeHour': '03' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '05') {
			let value = { 'endTimeHour': '04' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '06') {
			let value = { 'endTimeHour': '05' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '07') {
			let value = { 'endTimeHour': '06' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '08') {
			let value = { 'endTimeHour': '07' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '09') {
			let value = { 'endTimeHour': '08' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '10') {
			let value = { 'endTimeHour': '09' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '11') {
			let value = { 'endTimeHour': '10' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeHour == '12') {
			let value = { 'endTimeHour': '11' };
			this.form.patchValue(value);
		}
	}

	increaseEndMinute() {
		if (this.form.value.endTimeMinute == '00') {
			let value = { 'endTimeMinute': '15' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeMinute == '15') {
			let value = { 'endTimeMinute': '30' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeMinute == '30') {
			let value = { 'endTimeMinute': '45' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeMinute == '45') {
			let value = { 'endTimeMinute': '00' };
			this.form.patchValue(value);
		}
	}

	decreaseEndMinute() {
		if (this.form.value.endTimeMinute == '00') {
			let value = { 'endTimeMinute': '45' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeMinute == '15') {
			let value = { 'endTimeMinute': '00' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeMinute == '30') {
			let value = { 'endTimeMinute': '15' };
			this.form.patchValue(value);
		} else if (this.form.value.endTimeMinute == '45') {
			let value = { 'endTimeMinute': '30' };
			this.form.patchValue(value);
		}
	}

	increaseStartHour() {
		if (this.form.value.startTimeHour == '01') {
			let value = { 'startTimeHour': '02' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '02') {
			let value = { 'startTimeHour': '03' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '03') {
			let value = { 'startTimeHour': '04' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '04') {
			let value = { 'startTimeHour': '05' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '05') {
			let value = { 'startTimeHour': '06' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '06') {
			let value = { 'startTimeHour': '07' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '07') {
			let value = { 'startTimeHour': '08' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '08') {
			let value = { 'startTimeHour': '09' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '09') {
			let value = { 'startTimeHour': '10' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '10') {
			let value = { 'startTimeHour': '11' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '11') {
			let value = { 'startTimeHour': '12' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '12') {
			let value = { 'startTimeHour': '01' };
			this.form.patchValue(value);
		}
	}

	decreaseStartHour() {
		if (this.form.value.startTimeHour == '01') {
			let value = { 'startTimeHour': '12' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '02') {
			let value = { 'startTimeHour': '01' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '03') {
			let value = { 'startTimeHour': '02' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '04') {
			let value = { 'startTimeHour': '03' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '05') {
			let value = { 'startTimeHour': '04' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '06') {
			let value = { 'startTimeHour': '05' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '07') {
			let value = { 'startTimeHour': '06' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '08') {
			let value = { 'startTimeHour': '07' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '09') {
			let value = { 'startTimeHour': '08' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '10') {
			let value = { 'startTimeHour': '09' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '11') {
			let value = { 'startTimeHour': '10' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeHour == '12') {
			let value = { 'startTimeHour': '11' };
			this.form.patchValue(value);
		}
	}

	increaseStartMinute() {
		if (this.form.value.startTimeMinute == '00') {
			let value = { 'startTimeMinute': '15' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeMinute == '15') {
			let value = { 'startTimeMinute': '30' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeMinute == '30') {
			let value = { 'startTimeMinute': '45' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeMinute == '45') {
			let value = { 'startTimeMinute': '00' };
			this.form.patchValue(value);
		}
	}

	decreaseStartMinute() {
		if (this.form.value.startTimeMinute == '00') {
			let value = { 'startTimeMinute': '45' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeMinute == '15') {
			let value = { 'startTimeMinute': '00' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeMinute == '30') {
			let value = { 'startTimeMinute': '15' };
			this.form.patchValue(value);
		} else if (this.form.value.startTimeMinute == '45') {
			let value = { 'startTimeMinute': '30' };
			this.form.patchValue(value);
		}
	}

	changeStartMeridiem() {
		let value;
		if (this.form.value.startTimeMeridiem == 'PM') {

			value = { 'startTimeMeridiem': 'AM' };


		} else {
			value = { 'startTimeMeridiem': 'PM' };
		}
		this.form.patchValue(value);
	}

	changeEndMeridiem() {
		let value;
		if (this.form.value.endTimeMeridiem == 'PM') {

			value = { 'endTimeMeridiem': 'AM' };


		} else {
			value = { 'endTimeMeridiem': 'PM' };
		}
		this.form.patchValue(value);
	}

	setStartTimeToNoon() {
		let valueHour = { 'startTimeHour': '12' };
		this.form.patchValue(valueHour);

		let valueMinutes = { 'startTimeMinutes': '00' };
		this.form.patchValue(valueMinutes);

		let valueMeridiem = { 'startTimeMeridiem': 'PM' };
		this.form.patchValue(valueMeridiem);
	}

	setStartTimeToMidnight() {
		let valueHour = { 'startTimeHour': '12' };
		this.form.patchValue(valueHour);

		let valueMinutes = { 'startTimeMinutes': '00' };
		this.form.patchValue(valueMinutes);

		let valueMeridiem = { 'startTimeMeridiem': 'AM' };
		this.form.patchValue(valueMeridiem);
	}

	setEndTimeToNoon() {
		let valueHour = { 'endTimeHour': '12' };
		this.form.patchValue(valueHour);

		let valueMinutes = { 'endTimeMinutes': '00' };
		this.form.patchValue(valueMinutes);

		let valueMeridiem = { 'endTimeMeridiem': 'PM' };
		this.form.patchValue(valueMeridiem);
	}

	setEndTimeToMidnight() {
		let valueHour = { 'endTimeHour': '12' };
		this.form.patchValue(valueHour);

		let valueMinutes = { 'endTimeMinutes': '00' };
		this.form.patchValue(valueMinutes);

		let valueMeridiem = { 'endTimeMeridiem': 'AM' };
		this.form.patchValue(valueMeridiem);
	}

	// delay(callback, ms) {
	// 	let timer = 0;
	// 	return function () {
	// 		let context = this, args = arguments;
	// 		clearTimeout(timer);
	// 		timer = setTimeout(function () {
	// 			callback.apply(context, args);
	// 		}, ms || 0);
	// 	};
	// }

	locationKeyup() {
		if (this.form.value.location.length > 3) {
			this.userService.getLocationSuggestions(this.form.value.location).subscribe(
				response => {
					this.locationSuggestions = response.body.items;
				}
			);
		}
	}

	selectLocation(location: string) {
		this.location = location;
		this.locationSuggestions = [];

		let url = 'https://www.google.com/maps/embed/v1/place?&q=' + location + '&key=AIzaSyBaIc0xglS3_DQ0S5h8B-nnnm1ohED0BsI'
		url = url.replace(/\s/g, "+");

		this.locationEmbedUrl = url;
	}

	clearLocation() {
		this.location = '';
		this.locationSuggestions = [];
		this.locationEmbedUrl = '';

		this.form.controls['location'].setValue('');
	}

	/**
	 * NAVIGATE
	 * 
	 * @param route 
	 */
	navigate(route: string) {
		this.router.navigate([route]);
	}

	navigateToPaymentMethods() {
		this.router.navigate(['profile/'], { queryParams: { tab: 'payment' } });
	}
}
