import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-privacy-policy',
	templateUrl: './privacy-policy.component.html',
	styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
	@Input() isModal: boolean = false;
	loading = true;

	constructor(
		private route: ActivatedRoute,
		public router: Router
	) { }

	ngOnInit() {
		/**
		this.route.queryParams.subscribe(params => {
			if (params['footer']) {
				const vaultId = params['vaultId'];
				
			}
		}); */
		this.loading = false;
	}

	navigate(path,) {
		this.router.navigate([path]);
	}
}
