<nav class="main-header navbar navbar-default">
	<!-- Desktop -->
	<div class="container-fluid desktop row g-0">
		<div class="col-2">
			<img class="logo default" src="/assets/images/logo.jpg" />
			<img class="logo private" src="/assets/images/logo-text-white.png" />
			<!--<em class="fas fa-lock"></em>-->
		</div>
		<div class="col-8">
			<div class="center">
				<li class="nav-link">
					<a href="javascript:void(0)" class="no-padding-right" (click)="navigate($event, '/dashboard')">Dashboard</a>
				</li>
				<li class="nav-link">
					<a href="javascript:void(0)" class="no-padding-right" (click)="navigate($event, '/explore')">Explore</a>
				</li>
				<li class="nav-link">
					<a href="javascript:void(0)" class="no-padding-right" (click)="navigate($event, '/timeline')">Timeline</a>
				</li>
				<li class="nav-link">
					<a href="javascript:void(0)" class="no-padding-right" (click)="navigate($event, '/events')">Events</a>
				</li>
				<!--
				<li class="nav-link">
					<a href="javascript:void(0)" class="no-padding-right" (click)="navigate($event, '/photos')">Galleries</a>
				</li>
				<li class="nav-link">
					<a href="javascript:void(0)" class="no-padding-right" (click)="navigate($event, '/connections')">Connections</a>
				</li>
				-->
			</div>
		</div>
		<div class="col-2">
			<div class="align-right">
				<div *ngIf="router.url.startsWith('/dashboard')" class="icon-container" dropdown>
					<div class="hover-text">{{ iconHoverText }}</div>
					<a *ngIf="dashboardEditMode" (mouseenter)="updateIconHoverText('Reset dashboard')" (mouseleave)="updateIconHoverText('')" (click)="resetWidgets()"><i class="fas fa-undo-alt"></i></a>
					<a *ngIf="dashboardEditMode" (mouseenter)="updateIconHoverText('Add widget')" (mouseleave)="updateIconHoverText('')" (click)="displayWidgetMenu()" class="user-dropdown-toggle" role="button"><i class="fas fa-columns"></i></a>

					<ul *ngIf="showWidgetsMenu" class="dropdown-menu">
						<li id="galleries"><a href="javascript:void(0)" class="no-padding-right" (click)="triggerShowGalleries($event)"><i *ngIf="!showMenuGalleries" class="fas fa-angle-down"></i> <i *ngIf="showMenuGalleries" class="fas fa-angle-up"></i> Galleries <span class="caret"></span></a></li>
						<ul *ngIf="showMenuGalleries">
							<li><a href="javascript:void(0)" class="no-padding-right" (click)="addWidget('photos-uncategorized-gallery')">Uncategorized Gallery</a></li>
						</ul>
						<li id="graphs"><a href="javascript:void(0)" class="no-padding-right" (click)="triggerShowGraphs($event)"><i *ngIf="!showMenuGraphs" class="fas fa-angle-down"></i> <i *ngIf="showMenuGraphs" class="fas fa-angle-up"></i> Graphs <span class="caret"></span></a></li>
						<ul *ngIf="showMenuGraphs">
							<li><a href="javascript:void(0)" class="no-padding-right" (click)="addWidget('photos-connections-graph')">Connections Photos</a></li>
							<li><a href="javascript:void(0)" class="no-padding-right" (click)="addWidget('photos-uncategorized-graph')">My Uncategorized Photos</a></li>
							<li><a href="javascript:void(0)" class="no-padding-right" (click)="addWidget('photos-my-uploaded-graph')">My Uploaded Photos</a></li>
							<li><a href="javascript:void(0)" class="no-padding-right" (click)="addWidget('vault-storage-graph')">Total Vault Storage</a></li>
						</ul>

						<li id="quick-links"><a href="javascript:void(0)" class="no-padding-right" (click)="triggerShowQuickLinks($event)"><i *ngIf="!showMenuQuickLinks" class="fas fa-angle-down"></i> <i *ngIf="showMenuQuickLinks" class="fas fa-angle-up"></i> Quick Links <span class="caret"></span></a></li>
						<ul *ngIf="showMenuQuickLinks">
							<li><a href="javascript:void(0)" class="no-padding-right" (click)="addWidget('connections-quick-links')">Connections</a></li>
							<li><a href="javascript:void(0)" class="no-padding-right" (click)="addWidget('photos-quick-links')">Photos</a></li>
						</ul>
						<li><a href="javascript:void(0)" class="no-padding-right" (click)="addWidget('user-messages')">User Notifications</a></li>
						<li><a href="javascript:void(0)" class="no-padding-right" (click)="addWidget('welcome-message')">Welcome Message</a></li>
					</ul>
					<a *ngIf="dashboardEditMode && !showUserWelcomeMessage" title="Lock Dashboard" (mouseenter)="updateIconHoverText('Lock dashboard')" (mouseleave)="updateIconHoverText('')" (click)="lockDashboard()" class="unlock"><i class="fas fa-unlock"></i></a>
					<a *ngIf="!dashboardEditMode && !showUserWelcomeMessage" title="Unlock Dashboard" (mouseenter)="updateIconHoverText('Unlock dashboard')" (mouseleave)="updateIconHoverText('')" (click)="unlockDashboard()" class="lock"><i class="fas fa-lock"></i></a>
				</div>
				<li class="icon-link">
					<i title="Add Photo" class="fas fa-plus" (click)="navigate($event, '/photo/add/upload')"></i>
				</li>
				<li class="nav-link dropdown">
					<div title="User Menu" *ngIf="!user?.imageSafeUrl" (click)="displayUserMenu()" class="dropdown-toggle" role="button">
						<span class="initials">{{ userInitials }}</span>
						<span class="caret"></span>
					</div>
					<div title="User Menu" *ngIf="user?.imageSafeUrl" (click)="displayUserMenu()" class="dropdown-toggle" role="button">
						<img [src]="user?.imageSafeUrl" class="initials image" />
						<span class="caret"></span>
					</div>
					<ul *ngIf="showUserMenu" class="dropdown-menu">
						<!-- <li><a href="javascript:void(0)" (click)="switchPrivateMode()">Private Mode</a></li> -->
						<li><a href="javascript:void(0)" (click)="navigate($event, '/connections')"><i class="fas fa-users"></i>My Connections</a></li>
						<li><a href="javascript:void(0)" (click)="navigate($event, '/events')"><i class="fas fa-calendar-alt"></i>My Events</a></li>
						<li><a href="javascript:void(0)" (click)="navigate($event, '/folders')"><i class="fas fa-folder"></i>My Folders</a></li>
						<li><a href="javascript:void(0)" (click)="navigate($event, '/photos')"><i class="fas fa-images"></i>My Galleries</a></li>
						<li class="spacer"></li>
						<!--
						<li><a href="javascript:void(0)" (click)="navigate($event, '/workflows')">Workflows</a></li>
						<li><a href="javascript:void(0)" (click)="navigate($event, '/marketplace')">Marketplace</a></li>
						-->
						<li><a href="javascript:void(0)" (click)="navigate($event, '/profile')" class="purple"><i class="fas fa-cog"></i>User Settings</a></li>
						<li class="spacer"></li>
						<li><a href="javascript:void(0)" (click)="logout()" class="red"><i class="fas fa-sign-out-alt"></i>Logout</a></li>
					</ul>
				</li>
			</div>
		</div>
	</div>

	<!-- Mobile -->
	<div class="container-fluid mobile row g-0">
		<div class="col-6">
			<img class="logo" src="/assets/images/logo.jpg" />
		</div>
		<div class="col-6">
			<button #collapsePagelistButton type="button" class="btn navbar-right toggle-collapse-button" aria-controls="collapsable">
				<em class="fas fa-bars"></em>
			</button>
		</div>
	</div>

</nav>