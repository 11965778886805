<div class="container" [ngClass]="timeOfDay.toLowerCase()">
	<div class="loading-container loading in" *ngIf="loading">&nbsp;</div>
	<ng-container *ngIf="!loading">
		<div class="message" *ngIf="timeOfDay == 'night'" [ngClass]="{'fade-out': fadeText1 }">Greetings, night owl</div>
		<div class="message" *ngIf="timeOfDay !== 'night'" [ngClass]="{'fade-in-out': fadeText1 }">Good {{ timeOfDay }}, {{ userService.users[0]?.firstName }}</div>
	</ng-container>

	<!-- Morning Assets -->
	<div *ngIf="timeOfDay == 'morning'" class="clouds-white"></div>

	<!-- Afternoon Assets -->
	<!-- <div *ngIf="timeOfDay == 'afternoon'" class="butterfly-container">
		<div class="butterfly">
			<div class="wing">
				<div class="bit"></div>
				<div class="bit"></div>
			</div>
			<div class="wing">
				<div class="bit"></div>
				<div class="bit"></div>
			</div>
		</div>
	</div> -->


	<!-- Evening Assets  -->
	<!-- <div *ngIf="timeOfDay == 'evening'" class="bird-container">
		<div class="bird"></div>
	</div> -->
	<!-- <div *ngIf="timeOfDay == 'evening'" class="photonomy-evening">
		<div>Photonomy</div>
	</div> -->

	<!-- Night Assets -->
	<div *ngIf="timeOfDay == 'night'" class="stars"></div>
	<div *ngIf="timeOfDay == 'night'" class="twinkling"></div>
	<div *ngIf="timeOfDay == 'night'" class="text">Photonomy</div>
	<div *ngIf="timeOfDay == 'night'" class="clouds"></div>
	<div *ngIf="timeOfDay == 'night'" class="shooting-star"></div>

	<!-- UNUSED -->
	<!-- <div *ngIf="timeOfDay == 'evening'" class="balloon-container">
			<div class="balloon">
				<span class="ropes"></span>
				<span class="basket"></span>
			</div>
		</div> -->
</div>