import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { PhotoService } from 'src/app/services/photo.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-widget-gallery-uncategorized-photos',
    templateUrl: './widget-gallery-uncategorized-photos.component.html',
    styleUrls: ['./widget-gallery-uncategorized-photos.component.css']
})
export class WidgetGalleryUncategorizedPhotosComponent implements OnInit, OnDestroy {
    modalRef: BsModalRef;

    loading = true;

    images: Object[] = [];
    imageIndex = 0;

    user: User;
    userChangedSubscription: Subscription;

    constructor(
        private router: Router,
        private photoService: PhotoService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.subscribeToUserChanged();

        if (this.userService.users[0]) {
            this.user = this.userService.users[0];

            this.retrieveUncategorizedPhotos();
        }
    }

    ngOnDestroy() { }

    subscribeToUserChanged() {
        this.userChangedSubscription = this.userService.userChanged.subscribe(
            user => {
                if (user.id == this.userService.users[0].id) {
                    this.user = user;

                    this.retrieveUncategorizedPhotos();
                }
            }
        );
    }

    /**
     * RETRIEVE UNCATEGORIZED PHOTOS
     * 
     * 1. Retrieve uncategorized photos.
     * 2. Loop photos on a timer so they process in succession.
     * 3. If there is an imageUrl, check if the image exists. Do this because Facebook cache URLs expire.
     * 4. If the image exists, display the photo.
     * 5. If the image does not exist, retrieve the photo from Facebook, save the new URL, and dislay the image.
     * 6. If there is not an imageUrl, check the type of photo.
     * 7. If facebook, load the image from Facebook, save the new URL, and dislay the image.
     * 8. If upload, retrieve and dislay the image.
     */
    retrieveUncategorizedPhotos() {
        this.photoService.getUncategorizedPhotosRandom().subscribe(
            response => {
                let index = 0;
                let photos = response.body;
                let _this = this;

                if (photos.length > 0) {
                    (function repeat() {
                        setTimeout(function () {
                            let photo = photos[index];

                            let token = _this.photoService.signedDownloadTokens.find(d => d.userId === photo.userId.toString());
                            if (token) {
                                if (!photo.imageSafeUrl) {
                                    let imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + token.token;

                                    let image = { image: imageSafeUrl, thumbImage: imageSafeUrl };
                                    _this.images.push(image);
                                } else {
                                    let image = { image: photo.imageSafeUrl, thumbImage: photo.imageSafeUrl };
                                    _this.images.push(image);
                                }

                                index++;
                                if (index == photos.length) {
                                    _this.loading = false;
                                } else {
                                    repeat();
                                }
                            } else {
                                let type = 'userPublic';
                                if (photo.connectionsCanView == false) {
                                    type = 'userPrivate';
                                }
                                _this.photoService.getSignedDownloadToken(photo.userId.toString(), type).subscribe(
                                    response => {
                                        let token = response.body;
                                        if (!photo.imageSafeUrl) {
                                            let imageSafeUrl = 'https://f003.backblazeb2.com/file/photonomy-prod/' + photo.imageUrlMed + "?Authorization=" + token.token;

                                            let image = { image: imageSafeUrl, thumbImage: imageSafeUrl };
                                            _this.images.push(image);
                                        } else {
                                            let image = { image: photo.imageSafeUrl, thumbImage: photo.imageSafeUrl };
                                            _this.images.push(image);
                                        }
                                        token.userId = _this.user.id.toString();
                                        _this.photoService.signedDownloadTokens.push(token);

                                        index++;
                                        if (index == photos.length) {
                                            _this.loading = false;
                                        } else {
                                            repeat();
                                        }
                                    }
                                );
                            }
                        }, 200);
                    })();


                    /** LEGACY FACEBOOK CODE
                    (function repeat() {
                        setTimeout(function () {
                            let photo = photos[index];
                            // TODO: Check if the image size from the URL is the same before loading the image from facebook or database.
                            if (photo.imageUrl) {
                                // Check if the imageUrl is still valid or if the cached url has expired.
                                _this.imageExists(photo.imageUrl, function (exists) {
                                    if (exists) {
                                        let image = { image: photo.imageUrl, thumbImage: photo.imageUrl };
                                        _this.images.push(image);

                                        // TODO: For whatever reason ng-image-slider does not display 0 index photos.
                                        //       Remove whenever this is fixed.
                                        if (index == 0) {
                                            _this.images.push(image);
                                        }

                                        // First loop stop the page load indicator, last loop stop the uncategorized load indicator
                                        index++;
                                        _this.loadingFirst = false;
                                        if (index == photos.length) {
                                            _this.loadingLast = false;
                                        } else {
                                            repeat();
                                        }

                                    } else if (photo.type == 'facebook') {
                                        // Photo URL is no longer valid, retrieve the new URL.
                                        // TODO: Move the authentication into the service?
                                        _this.facebookService.authenticate(function (model) {
                                            _this.facebookService.setToken(model.authResponse.accessToken);
                                            _this.facebookService.setUserId(model.authResponse.userID);

                                            _this.facebookService.retrievePhoto(photo.externalId, function (response2) {
                                                // TODO: Check to see if this photo status is pending?
                                                let image = { image: response2.images[0].source, thumbImage: response2.images[0].source };
                                                _this.images.push(image);

                                                // TODO: For whatever reason ng-image-slider does not display 0 index photos.
                                                //       Remove whenever this is fixed.
                                                if (index == 0) {
                                                    _this.images.push(image);
                                                }

                                                // Update the URL in the database.
                                                photo.imageUrl = response2.images[0].source;
                                                _this.photoService.updatePhoto(photo).subscribe(
                                                    response3 => {
                                                        // Do nothing.  The photo has been updated.
                                                    });

                                                // First loop stop the page load indicator, last loop stop the uncategorized load indicator
                                                index++;
                                                _this.loadingFirst = false;
                                                if (index == photos.length) {
                                                    _this.loadingLast = false;
                                                } else {
                                                    repeat();
                                                }
                                            });
                                        });
                                    }
                                });
                            } else if (photo.type == 'facebook') {
                                _this.facebookService.authenticate(function (model) {
                                    _this.facebookService.setToken(model.authResponse.accessToken);
                                    _this.facebookService.setUserId(model.authResponse.userID);

                                    _this.facebookService.retrievePhoto(photo.externalId, function (response2) {
                                        let image = { image: response2.images[0].source, thumbImage: response2.images[0].source };
                                        _this.images.push(image);

                                        // TODO: For whatever reason ng-image-slider does not display 0 index photos.
                                        //       Remove whenever this is fixed.
                                        if (index == 0) {
                                            _this.images.push(image);
                                        }

                                        // Update the URL in the database.
                                        photo.imageUrl = response2.images[0].source;
                                        _this.photoService.updatePhoto(photo).subscribe(
                                            response3 => {
                                                // Do nothing.  The photo has been updated.
                                            });

                                        // First loop stop the page load indicator, last loop stop the uncategorized load indicator
                                        index++;
                                        _this.loadingFirst = false;
                                        if (index == photos.length) {
                                            _this.loadingLast = false;
                                        } else {
                                            repeat();
                                        }
                                    });
                                });
                            } else if (photo.type == 'upload') {

                                _this.photoService.getPhotoImage(photo.externalIdMed).subscribe(
                                    response => {
                                        var reader = new FileReader();
                                        reader.readAsDataURL(response.body);
                                        reader.onloadend = function () {
                                            var base64data = reader.result;
                                            let image = { image: base64data, thumbImage: base64data };
                                            _this.images.push(image);

                                            // TODO: For whatever reason ng-image-slider does not display 0 index photos.
                                            //       Remove whenever this is fixed.
                                            if (index == 0) {
                                                _this.images.push(image);
                                            }

                                            // First loop stop the page load indicator, last loop stop the uncategorized load indicator
                                            index++;
                                            _this.loadingFirst = false;
                                            if (index == photos.length) {
                                                _this.loadingLast = false;
                                            } else {
                                                repeat();
                                            }
                                        }
                                    },
                                    error => {
                                        // TODO: Error handling
                                        console.log('An error occurred:');
                                        console.log(error);
                                    });

                            }
                            //}
                        }, 200);

                    })(); */
                } else { // No Photos
                    this.loading = false;
                }
            });
    }

    imageExists(url, callback) {
        var img = new Image();
        img.onload = function () { callback(true); };
        img.onerror = function () { callback(false); };
        img.src = url;
    }

    prevImageClick() {
        if (this.imageIndex == 0) {
            this.imageIndex = this.images.length - 1;
        } else {
            this.imageIndex = this.imageIndex - 1;
        }
    }

    nextImageClick() {
        if (this.images.length == (this.imageIndex + 1)) {
            this.imageIndex = 0;
        } else {
            this.imageIndex = this.imageIndex + 1;
        }
    }

    navigate(route: string) {
        this.router.navigate([route]);
    }

    navigateToUncategorized() {
        this.router.navigate(['/explore/'], { queryParams: { uncategorized: 'true' } });
    }

    viewGallery() {
        // TODO: Store the network data to a global variable so i don't have to re-load the network data

        this.router.navigate(['/photos/uncategorized']);
    }
}
