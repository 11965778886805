import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Folder } from 'src/app/models/folder.model';
import { PhotoUpload } from 'src/app/models/photo-upload.model';
import { Photo } from 'src/app/models/photo.model';
import { User } from 'src/app/models/user.model';
import { FolderService } from 'src/app/services/folder.service';
import { LayoutService } from 'src/app/services/layout.service';
import { UserConnectionService } from 'src/app/services/user-connection.service';
import { UserService } from 'src/app/services/user.service';

const EXIF = require('exif-js');

@Component({
	selector: 'app-photos-add-upload',
	templateUrl: './upload.component.html',
	styleUrls: ['./upload.component.css']
})
export class PhotosAddUploadComponent implements OnInit {
	@ViewChild('presetMetadataDialog', { static: true }) presetMetadataDialog: TemplateRef<any>;
	@ViewChild('keywordsSelect') keywordsSelect;
	@ViewChild('locationsSelect') locationsSelect;
	@ViewChild('peopleSelect') peopleSelect;

	modalRef: BsModalRef;
	loadingUserKeywords = true;
	loadingUserPeople = true;
	loadingUserLocations = true;
	loadingUserPublicTags = false;
	loadingPhotos = false;
	loadingFolders = true;
	loadingCreateFolder = -1;
	showHelp = false;

	paymentMethodsExist = false;

	files: any[] = [];

	photo: Photo;

	form: UntypedFormGroup;

	rootFolders: any[] = [];
	selectedFolder: Folder = null;
	folderSelectorCollapsed = true;
	expandedRootId = -1;
	newFolderParentId = -1;
	newSubFolderParentId = -1;

	people: any[] = [];
	tempPerson = '';

	keywords: any[] = [];
	tempKeyword = '';

	locations: any[] = [];
	locationSuggestions: any[] = [];
	locationShowSuggestions: boolean = false;
	userLocationSuggestions: any[] = [];
	selectedLocation = '';				// This is the selected location, either from the db or a suggestion
	tempLocation = '';					// If this is set, it needs to be added as a user location

	tagsSelectorCollapsed = true;
	selectedTags: any[] = [];
	userTags: any[] = [];
	filteredUserTags: any[] = [];
	filteredPublicTags: any[] = [];
	tempTag = '';

	user: User;
	userChangedSubscription: Subscription;

	private locationSearchSubject = new Subject<string>();
	private tagSearchSubject = new Subject<string>();
	private readonly debounceTimeMs = 500; // Set the debounce time (in milliseconds)

	constructor(
		private router: Router,
		private formBuilder: UntypedFormBuilder,
		private connectionService: UserConnectionService,
		private folderService: FolderService,
		private layoutService: LayoutService,
		private userService: UserService
	) { }

	ngOnInit() {
		this.subscribeToUserChanged();
		this.subscribeToLocationInputChanged();
		this.subscribeToTagInputChanged();

		this.loadPeople();
		this.loadUserLocations();
		this.loadUserKeywords();
		this.loadUserPublicTags();
		this.loadUserFolders();

		if (this.userService.users[0]) {
			this.user = this.userService.users[0];

			this.checkUserPaymentMethod();
			this.loadFormData();
		} else {
			this.initForm();
		}
	}

	subscribeToUserChanged() {
		this.userChangedSubscription = this.userService.userChanged.subscribe(
			user => {
				if (user.id == this.userService.users[0].id) {
					this.user = user;

					this.checkUserPaymentMethod();
					this.loadFormData();
				}
			}
		);
	}

	subscribeToLocationInputChanged() {
		this.locationSearchSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe(() => {
			this.locationKeyupSearch();
		});
	}

	subscribeToTagInputChanged() {
		this.tagSearchSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe(() => {
			this.tagsKeyupSearch();
		});
	}

	initForm() {
		this.form = this.formBuilder.group({
			'newRootFolderName': new UntypedFormControl(),
			'newSubFolderName': new UntypedFormControl(),
			'people': new UntypedFormControl(),
			'location': new UntypedFormControl(),
			'keywords': new UntypedFormControl(),
			'tags': new UntypedFormControl([]),
			'connectionsCanView': new UntypedFormControl(false, []),
			'connectionsCanReact': new UntypedFormControl(false, []),
			'connectionsCanDiscuss': new UntypedFormControl(false, []),
			'connectionsCanSuggest': new UntypedFormControl(false, []),
			'connectionsCanSeeExif': new UntypedFormControl(false, []),
		});

		this.form.controls['tags'].disable();
	}

	/** POST INIT FUNCTIONS **/

	/** 
	 * LOAD FOLDERS
	 */
	loadUserFolders() {
		this.loadingFolders = true;
		this.folderService.getFolders(0).subscribe(
			response => {
				this.rootFolders = response.body;

				this.rootFolders.sort((a, b) => a.name.toString().localeCompare(b.name));
				this.rootFolders = this.rootFolders.slice();

				//this.loadingFolders = false;
			}
		);
	}

	/**
	 * LOAD USER KEYWORDS
	 * 
	 * Load the list of user keywords for the Keywords dropdown. 
	 * 
	 * 1. Load the user keywords. 
	 * 2. Sort the keywords alphabetically.
	 * 
	 * TODO: Implement cacheing.
	 */
	loadUserKeywords() {
		this.userService.getUserKeywords().subscribe(
			response => {
				for (const keyword of response) {
					this.keywords.push({ name: keyword, type: 'db' });
				}
				this.keywords.sort((a, b) => a.name.localeCompare(b.name));
				this.keywords = this.keywords.slice();

				this.loadingUserKeywords = false;
			}
		);
	}

	loadUserPublicTags() {
		this.userService.getUserPublicTags().subscribe(
			response => {
				for (const tag of response) {
					this.userTags.push({ name: tag, type: 'db' });
				}
				this.userTags.sort((a, b) => a.name.localeCompare(b.name));
				this.userTags = this.userTags.slice();

				this.loadingUserPublicTags = false;
			}
		);
	}

	/**
	 * LOAD PEOPLE
	 * 
	 * Load the list of user connections for People dropdown.
	 *
	 * 1. Add the current user.
	 * 2. Retrieve and add active user connections.
	 * 3. Sort the people alphabetically.
	 * 
	 * TODO: Implement cacheing.
	 */
	loadPeople() {
		let user = this.userService.getLocalUser(0);
		if (user?.firstName) {
			user.name = user.firstName + " " + user.lastName;
			this.people.push(user);
		} else {
			// If the page is reloaded the user is not yet ready.
			this.userService.retrieveCurrentUser().subscribe(
				response => {
					user = response.body;
					user.name = response.body.firstName + " " + response.body.lastName;
					this.people.push(user);
				}
			);
		}

		this.connectionService.getUserConnectionsActive().subscribe(
			response => {
				for (const user of response.body) {
					user.name = user.firstName + " " + user.lastName;
					this.people.push(user);
				}
				this.people.sort((a, b) => a.name.localeCompare(b.name));
				this.people = this.people.slice();

				this.loadUserPeople();
			}
		);
	}

	/**
	 * LOAD USER PEOPLE
	 * 
	 * Load the list of non-users for the People dropdown.
	 * 
	 * 1. Retrieve and add user people.
	 * 2. Sort the people alphabetically.
	 * 
	 * TODO: Implement cacheing.
	 * TODO: Do not add duplicate names.
	 */
	loadUserPeople() {
		this.userService.getUserPeople().subscribe(
			response => {
				for (const person of response) {
					this.people.push({ name: person, type: 'db' });
				}
				this.people.sort((a, b) => a.name.localeCompare(b.name));
				this.people = this.people.slice();

				this.loadingUserPeople = false;
			}
		);
	}

	/**
	 * LOAD USER LOCATIONS
	 * 
	 * Load list of user locations for the Locations dropdown.
	 * 
	 * 1. 
	 * 
	 * TODO: Implement cacheing.
	 */
	loadUserLocations() {
		this.userService.getUserLocations().subscribe(
			response => {
				for (const location of response) {
					this.locations.push({ name: location, type: 'db' });
				}
				this.locations.sort((a, b) => a.name.localeCompare(b.name));
				this.locations = this.locations.slice();

				this.loadingUserLocations = false;
			}
		);
	}


	/** POST USER LOAD FUNCTIONS **/

	checkUserPaymentMethod() {
		if (this.user.paymentMethodId) {
			let now = new Date(); // 5/13/22
			let expireDate = new Date(this.user.paymentMethodCcExpirationYear, this.user.paymentMethodCcExpirationMonth, 0);

			if (expireDate > now) {
				this.paymentMethodsExist = true;
			}
		}
	}

	loadFormData() {
		this.form = this.formBuilder.group({
			'newRootFolderName': new UntypedFormControl(),
			'newSubFolderName': new UntypedFormControl(),
			'people': new UntypedFormControl(),
			'location': new UntypedFormControl(),
			'keywords': new UntypedFormControl(),
			'tags': new UntypedFormControl(),
			'connectionsCanView': new UntypedFormControl(this.userService.users[0].connectionsCanViewByDefault, []),
			'connectionsCanReact': new UntypedFormControl(this.userService.users[0].connectionsCanReactByDefault, []),
			'connectionsCanDiscuss': new UntypedFormControl(this.userService.users[0].connectionsCanDiscussByDefault, []),
			'connectionsCanSuggest': new UntypedFormControl(this.userService.users[0].connectionsCanSuggestByDefault, []),
			'connectionsCanSeeExif': new UntypedFormControl(this.userService.users[0].connectionsCanSeeExifByDefault, []),
		});
	}

	/** FOLDER FUNCTIONS **/

	expandFolderSelector() {
		this.folderSelectorCollapsed = false;
	}

	collapseFolderSelector() {
		this.folderSelectorCollapsed = true;
		this.newSubFolderParentId = -1;
		this.newFolderParentId = -1;
	}

	expandFolder(event, folder) {
		event.stopPropagation();

		if (folder.folders && folder.folders.length > 0) {
			this.expandedRootId = folder.id;
		}
	}

	collapseFolder(event, folderId) {
		event.stopPropagation();

		this.expandedRootId = -1;
	}

	showCreateSubfolder(event, parentFolderId) {
		event.stopPropagation();
		this.newSubFolderParentId = parentFolderId;
	}

	cancelShowCreateSubfolder() {
		event.stopPropagation();
		this.newSubFolderParentId = -1;
	}

	createSubFolder(parentFolderId) {
		this.loadingCreateFolder = parentFolderId;

		let folder: Folder = new Folder;
		folder.name = this.form.value.newSubFolderName;
		folder.parentId = parentFolderId;

		this.folderService.createFolder(folder).subscribe(
			response => {
				let parentFolder: Folder = this.rootFolders.find(d => d.id === parentFolderId);
				if (parentFolder) {
					if (parentFolder.folders) {
						parentFolder.folders.push(response.body);
					} else {
						parentFolder.folders = [];
						parentFolder.folders.push(response.body);
					}

					parentFolder.folders.sort((a, b) => a.name.toString().localeCompare(b.name));
					parentFolder.folders = parentFolder.folders.slice();

					this.newSubFolderParentId = -1;

					// this.form.value.newSubFolderName = ''; // Doesn't work. Need to reset form.

					this.loadingCreateFolder = -1;
				} else {
					// Error handling, just pull the folders over again completely
					this.loadingCreateFolder = -1;
				}

			}
		);
	}

	selectFolder(folder) {
		this.selectedFolder = folder;
		this.newSubFolderParentId = -1;
		this.newFolderParentId = -1;
		this.folderSelectorCollapsed = true;
	}

	selectNoFolder() {
		this.selectedFolder = null;
		this.expandedRootId = -1;
		this.newSubFolderParentId = -1;
		this.newFolderParentId = -1;
		this.folderSelectorCollapsed = true;
	}

	createRootFolder() {
		this.loadingCreateFolder = 0;

		let folder: Folder = new Folder;
		folder.name = this.form.value.newRootFolderName;
		folder.parentId = 0;

		this.folderService.createFolder(folder).subscribe(
			response => {
				this.rootFolders.push(response.body);

				this.rootFolders.sort((a, b) => a.name.toString().localeCompare(b.name));
				this.rootFolders = this.rootFolders.slice();

				this.newFolderParentId = -1;
				this.form.value.newRootFolderName = ''; // Doesn't work. Need to reset form.

				this.loadingCreateFolder = -1;
			}
		);
	}

	cancelCreateRootFolder() {
		this.newFolderParentId = -1;
		this.form.value.newRootFolderName = ''; // Doesn't work. Need to reset form.
	}

	/** PEOPLE DROPDOWN FUNCTIONS **/

	peopleKeyup() {
		this.tempPerson = this.peopleSelect.searchTerm;
	}

	peopleBlur() {
		if (this.tempPerson.length > 0) {
			// TODO #1: First make sure they are not selecting a term.  For example, if they 
			//          type 'we' and select 'wedding', it adds 'we' to the list which is not intended.
			// TODO #2: Make sure the value does not already exist in the array.  This might not
			//          be an issue after #1 is fixed.
			// THESE MIGHT ALREADY BE FIXED.
			this.people.push({ name: this.tempPerson, type: 'new' });
			this.people.sort((a, b) => a.name.localeCompare(b.name));
			this.people = this.people.slice();

			// TODO: There has to be a better way than a setTimeout for this.
			const _this = this;
			setTimeout(function () {
				let item = _this.peopleSelect.itemsList.findByLabel(_this.tempPerson);
				_this.peopleSelect.select(item);
				_this.tempPerson = '';
			}, 100);
		}
	}

	/**
	 * Set the temporary person to blank. This happens when a user selects a person so a new one is not created.
	 */
	peopleChange() {
		this.tempPerson = '';
	}

	peopleUnselect(person) {
		for (let i = 0; i < this.peopleSelect.itemsList.items.length; i++) {
			if (this.peopleSelect.itemsList.items[i].value === person.value) {
				this.peopleSelect.itemsList.items.splice(i, 1);
			}
		}
	}


	/** LOCATIONS DROPDOWN FUNCTIONS **/

	locationKeyup() {
		this.locationSearchSubject.next(null);
	}

	locationKeyupSearch() {
		if (this.form.value.location.length > 3) {

			if (this.locationShowSuggestions) {
				this.locationShowSuggestions = true;

				this.getLocationSuggestions();
			} else {
				let locations = this.locations.filter((location) => location.name.toLowerCase().includes(this.form.value.location.toLowerCase()));
				if (locations.length > 0) {
					this.locationShowSuggestions = false;
					this.locationSuggestions = [];

					this.userLocationSuggestions = locations;
				} else {
					this.userLocationSuggestions = [];

					this.locationShowSuggestions = true;

					this.getLocationSuggestions();
				}
			}
		} else if (this.form.value.location.length == 0) {
			this.userLocationSuggestions = [];
			this.locationSuggestions = [];
			this.locationShowSuggestions = false;
		}
	}

	loadMoreSuggestions() {
		this.locationShowSuggestions = true;
		this.userLocationSuggestions = [];

		this.getLocationSuggestions();
	}

	getLocationSuggestions() {
		this.userLocationSuggestions = [];

		this.userService.getLocationSuggestions(this.form.value.location).subscribe(
			response => {
				this.locationSuggestions = response.body.items;
			}
		);
	}

	addSuggestion() {
		this.selectedLocation = this.form.value.location;

		this.locationSuggestions = [];
		this.userLocationSuggestions = [];
		this.locationShowSuggestions = false;

		this.tempLocation = this.selectedLocation;
	}

	selectLocation(location: string) {
		this.selectedLocation = location;

		this.locationSuggestions = [];
		this.userLocationSuggestions = [];
		this.locationShowSuggestions = false;

		this.tempLocation = '';
	}

	selectLocationSuggestion(location: string) {
		this.selectedLocation = location;

		this.locationSuggestions = [];
		this.userLocationSuggestions = [];
		this.locationShowSuggestions = false;

		this.tempLocation = location;
	}

	clearLocation() {
		this.selectedLocation = '';

		this.locationSuggestions = [];
		this.userLocationSuggestions = [];
		this.locationShowSuggestions = false;

		this.tempLocation = '';

		this.form.controls['location'].setValue('');
	}

	/** KEYWORDS DROPDOWN FUNCTIONS **/

	keywordsKeyup() {
		this.tempKeyword = this.keywordsSelect.searchTerm;
	}

	keywordsBlur() {
		if (this.tempKeyword.length > 0) {
			// TODO #1: First make sure they are not selecting a term.  For example, if they 
			//          type 'we' and select 'wedding', it adds 'we' to the list which is not intended.
			// TODO #2: Make sure the value does not already exist in the array.  This might not
			//          be an issue after #1 is fixed.
			// THESE MIGHT ALREADY BE FIXED.
			this.keywords.push({ name: this.tempKeyword, type: 'new' });
			this.keywords.sort((a, b) => a.name.localeCompare(b.name));
			this.keywords = this.keywords.slice();

			// TODO: There has to be a better way than a setTimeout for this.
			const _this = this;
			setTimeout(function () {
				let item = _this.keywordsSelect.itemsList.findByLabel(_this.tempKeyword);
				_this.keywordsSelect.select(item);
				_this.tempKeyword = '';
			}, 100);
		}
	}

	/**
	 * Set the temporary keyword to blank. This happens when a user selects a keyword so a new one is not created.
	 */
	keywordsChange() {
		this.tempKeyword = '';
	}

	keywordsUnselect(keyword) {
		for (let i = 0; i < this.keywordsSelect.itemsList.items.length; i++) {
			if (this.keywordsSelect.itemsList.items[i].value === keyword.value) {
				this.keywordsSelect.itemsList.items.splice(i, 1);
			}
		}
	}

	/** PUBLIC TAGS DROPDOWN FUNCTIONS **/

	// Happens when the user presses the arrow down icon in the tag input
	showAllUserTags() {
		this.tagsSelectorCollapsed = !this.tagsSelectorCollapsed;
	}

	// Happens when the user releases any key when in the tags input

	tagsKeyup() {
		this.tagSearchSubject.next(null);
	}

	tagsKeyupSearch() {
		let value = this.form.controls['tags'].value;
		if (value.length > 1) {
			this.tagsSelectorCollapsed = false;
			this.tempTag = value;

			this.filteredUserTags = this.userTags.filter(d => String(d.name).toUpperCase().includes(this.tempTag.toUpperCase()));

			this.findPublicTags(this.tempTag.toUpperCase());
		} else {
			this.tagsSelectorCollapsed = true;
			this.tempTag = '';

			this.filteredUserTags = [];
			this.filteredPublicTags = [];
		}
	}

	// Happens when the user releases the enter key when in the tags input
	// This should add the typed value as a new tag
	tagsKeyupEnter() {
		let item = this.selectedTags.find(d => d.name === this.tempTag);
		if (!item && this.tempTag.length > 1) {
			this.selectedTags.push({ name: this.tempTag, type: 'new' });
			this.selectedTags.sort((a, b) => a.name.localeCompare(b.name));
			this.selectedTags = this.selectedTags.slice();
		}

		this.filteredPublicTags = [];
		this.filteredUserTags = [];
		this.tagsSelectorCollapsed = true;

		this.form.controls['tags'].setValue('');

		this.tempTag = '';
	}

	// Happens when the user clicks on a tag
	selectTag(tag) {
		let item = this.selectedTags.find(d => d.name === tag.name);
		if (!item) {
			this.selectedTags.push(tag);
			this.selectedTags.sort((a, b) => a.name.localeCompare(b.name));
			this.selectedTags = this.selectedTags.slice();
		}

		this.filteredPublicTags = [];
		this.filteredUserTags = [];
		this.tagsSelectorCollapsed = true;

		this.form.controls['tags'].setValue('');

		this.tempTag = '';
	}

	// Happens when the user presses the X icon on the tag
	removeTag(tag) {
		let item = this.selectedTags.find(d => d.name === tag.name);
		if (item) {
			this.selectedTags.splice(this.selectedTags.indexOf(item), 1);
		}
	}

	cancelAddTag() {
		this.filteredPublicTags = [];
		this.filteredUserTags = [];
		this.tagsSelectorCollapsed = true;
		this.form.controls['tags'].setValue('');
		this.tempTag = '';
	}

	clearTags() {
		this.selectedTags = [];
		this.filteredUserTags = [];
		this.filteredPublicTags = [];
		this.tagsSelectorCollapsed = true;
		this.tempTag = '';
	}

	findPublicTags(keyword) {
		this.loadingUserPublicTags = true;
		this.filteredPublicTags = [];
		this.userService.findPublicTagsByKeyword(keyword).subscribe(
			response => {
				for (const tag of response) {
					let item = this.filteredUserTags.find(d => d.name === tag);
					if (!item) {
						this.filteredPublicTags.push({ name: tag, type: 'new' });
					}
				}
				this.filteredPublicTags.sort((a, b) => a.name.localeCompare(b.name));
				this.filteredPublicTags = this.filteredPublicTags.slice();

				this.loadingUserPublicTags = false;
			}
		);
	}

	/** UPLOAD PHOTOS FUNCTIONS **/

	uploadPhotosSubmit() {
		let index = this.files.length;
		while (index--) {
			let photoUpload: PhotoUpload = new PhotoUpload();
			photoUpload.file = this.files[index];
			photoUpload.folder = this.selectedFolder;
			photoUpload.form = this.form;

			// NOTE: I would prefer to add this to the form via patchValue, but I couldn't
			//		 get it working right away.
			if (this.selectedLocation !== '' && this.selectedLocation.length > 0) {
				photoUpload.formLocation = this.selectedLocation;
			}

			// Same with this.
			if (this.selectedTags.length > 0) {
				let tagsOnlyArray = [];
				for (let tag of this.selectedTags) {
					tagsOnlyArray.push(tag.name)
				}
				photoUpload.formPublicTags = tagsOnlyArray;
			}

			this.layoutService.announceUploadPhoto(photoUpload);

			this.files.splice(index, 1);

			if (index == 0) {
				this.createCustomMetadata();
				this.clearForm();
			}
		}
	}

	/**
	 * createCustomMetadata - On the last loop, add any custom metadata.
	 */
	createCustomMetadata() {
		// Add new people to the user people table
		if (this.form.value.people) {
			for (const item of this.peopleSelect.itemsList.items) { // TODO: This causes issues.
				if (item.value.type == 'new') {
					item.value.type = 'db';

					this.userService.addUserPerson(item.value.name).subscribe(
						response => {
							// TODO: Error validation.
						});
				}
			}
		}

		// Add new keywords to the user keyword table
		if (this.form.value.keywords) {
			for (const item of this.keywordsSelect.itemsList.items) { // TODO: This causes issues.
				if (item.value.type == 'new') {
					item.value.type = 'db';

					this.userService.addUserKeyword(item.value.name).subscribe(
						response => {
							// TODO: Error validation.
						});
				}
			}
		}

		// Add new locations to the user location table
		if (this.tempLocation && this.tempLocation.length > 0) {
			this.userService.addUserLocation(this.tempLocation).subscribe(
				response => {
					// TODO: Error validation.
					this.tempLocation = '';
				}
			);
		}

		// Add new global tags to the user tags table
		if (this.selectedTags && this.selectedTags.length > 0) {
			for (const item of this.selectedTags) { // TODO: This causes issues.
				if (item.type == 'new') {
					item.type = 'db';
					item.name = item.name.toUpperCase();
					this.userService.addUserPublicTag(item.name).subscribe(
						response => {
							// Add it locally
							this.userTags.push(item);
							this.userTags.sort((a, b) => a.name.localeCompare(b.name));
							this.userTags = this.userTags.slice();
						});
				}
			}
		}
	}

	onPhotoAdd(event) {
		this.files.push(...event.addedFiles);
	}

	onPhotoRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
	}

	clearFiles() {
		this.files = [];
	}

	clearForm() {
		this.clearLocation();
		this.clearTags();
		this.clearFiles();
		this.loadFormData();

		this.selectedFolder = null;
		this.folderSelectorCollapsed = true;
		this.expandedRootId = -1;
		this.newFolderParentId = -1;
		this.newSubFolderParentId = -1;
	}


	/** PHOTO PERMISSION FUNCTIONS **/

	connectionsCanViewSwitchChanged(event) {
		if (event.srcElement.checked) {
			// Enable
			this.loadFormData();
		} else {
			// Disable
			this.initForm();

			// Remove public tags
			this.clearTags();
		}
	}


	/** HELPER FUNCTIONS **/

	parseExifDate(s) {
		var b = s.split(/\D/);
		return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
	}

	numDaysBetween(d1, d2) {
		var diff = Math.abs(d1.getTime() - d2.getTime());
		return diff / (1000 * 60 * 60 * 24);
	}

	navigate(path) {
		this.router.navigate([path]);
	}

	navigateToPaymentMethods() {
		this.router.navigate(['profile/'], { queryParams: { tab: 'payment' } });
	}
}
