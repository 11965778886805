<div class="header" id="top">
	<div class="cell-logo"><img src="assets/images/logo-white.png" class="logo" (click)="navigate('')" /></div>
	<!--<div class="cell-1">Privacy Policy</div>-->
	<!--<div class="cell-2">Terms of Service</div>-->
	<!--<div class="cell-3">Style Guide</div>-->
</div>

<div class="content">
	<div class="toc">
		<div class="last-updated">Last updated<br />July 23, 2024</div>
		<div class="red"><a href="#top" (click)="navigate('blog/01')">1. Introductions</a></div>
		<div class="orange"><a href="#top" (click)="navigate('blog/02')">2. From the beginning</a></div>
		<div class="yellow"><a href="#top" (click)="navigate('blog/03')">3. The first year</a></div>
		<div class="lightgreen"><a href="#top" (click)="navigate('blog/04')">4. Initial cloud hosting</a></div>
		<div class="green active"><a href="#top" (click)="navigate('blog/05')">5. UI/UX</a></div>
		<div class="cyan"><a href="#top" (click)="navigate('blog/06')">6. Performance tuning</a></div>
		<!-- <div class="blue"><a href="#top" (click)="navigate('blog/07')">7. Mental health</a></div>
		<div class="darkblue"><a href="#top" (click)="navigate('blog/08')">8. Funding vs bootstrapping</a></div>
		<div class="violet"><a href="#top" (click)="navigate('blog/09')">9. What's next</a></div> -->
	</div>
	<div>
		<div class="banner green">
			<div class="title">Photonomy thoughts and insights:<br /><span>UI/UX</span></div>
		</div>
		<div class="text-container">
			<p>From what I have seen, one of the most challenging tasks for full-stack engineers is building user
				interfaces. Coders aren't typically designers, and designers aren't typically coders. They both play a
				very different role in the application lifecycle.
			</p>
			<p>I fall somewhere in the middle. I am not the best coder, but I can get the job done, and usually fairly well.
				I am not the best designer, but I do know how to study other designs and combine them into a useful aesthetic.
				What I lack is the deeper understanding of the human behaviors, which professional designs not only understand
				but take fully into account.
			</p>
			<p>Shortly after the one year mark I commissioned a design from a very talented
				<a href="https://www.linkedin.com/in/mia-amlk" target="_blank">designer <i class="fas fa-external-link-alt"></i></a>
				from Croatia. I
				understood my limitations and needed outside counsel to better understand what I was building. This was my
				first real cost to the project, which put skin in the game and added additional motiviation to keep going.
			</p>
		</div>
	</div>
</div>
<app-footer></app-footer>