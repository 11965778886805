<div class="loading in" *ngIf="loadingUserPeople || loadingUserLocations || loadingUserKeywords">
	&nbsp;
</div>

<div class="scroll" *ngIf="!paymentMethodsExist && !loadingUserPeople && !loadingUserLocations && !loadingUserKeywords">
	<div class="welcome-title">
		<div class="title">Upload Photos</div>
	</div>
	<div class="welcome-message">
		To upload photos, you must have a valid payment method added to your account...
	</div>
	<div class="welcome-content">
		<div class="column">
			<div class="icon-cell">
				<i class="far fa-credit-card"></i>
			</div>
			<div class="border-cell">
				<div class="content-cell">
					<div class="content-title">Payment Methods</div>
					<div class="content-text">
						<p>Photo storage costs are $0.50 per GB per month, which can be pre-paid or billed monthly.</p>
					</div>
					<div class="button-cell">
						<div class="button" (click)="navigateToPaymentMethods()">Create a Payment Method</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="row" *ngIf="paymentMethodsExist && !loadingUserPeople && !loadingUserLocations && !loadingUserKeywords">
	<div class="col-md-4">
		<div class="metadata-container">
			<form #s="ngForm" [formGroup]="form" class="form">
				<!-- Folder -->
				<div class="form-group odd">
					<!-- Label -->
					<label for="folder" class="keywords-label">
						Select an optional folder location:
						<i class="fas fa-question-circle" [tooltip]="folderLocationTemplate" placement="bottom"></i>
						<ng-template #folderLocationTemplate>
							To update or delete folders, visit the My Folders page
						</ng-template>
					</label>
					<div class="folder-expand" *ngIf="folderSelectorCollapsed"><a (click)="expandFolderSelector()">Expand</a></div>
					<div class="folder-expand" *ngIf="!folderSelectorCollapsed"><a (click)="collapseFolderSelector()">Collapse</a></div>
					<!-- Collapsed container -->
					<div class="folder-container collapsed" *ngIf="folderSelectorCollapsed">
						<div *ngIf="selectedFolder?.id > 0" class="selected" (click)="folderSelectorCollapsed = false"><i class="fas fa-folder"></i>{{ selectedFolder?.name }}</div>
						<div *ngIf="!selectedFolder" class="unselected" (click)="folderSelectorCollapsed = false">No folder</div>
					</div>
					<!-- Expanded container -->
					<div class="folder-container" [ngClass]="{'hidden': folderSelectorCollapsed}">
						<div *ngIf="selectedFolder?.id > 0" class="selected" (click)="folderSelectorCollapsed = true"><i class="fas fa-folder"></i>{{ selectedFolder?.name }}</div>
						<div [ngClass]="{'strong': !selectedFolder}" (click)="selectNoFolder()">No folder</div>
						<!-- List folders -->
						<ng-container *ngFor="let folder of rootFolders">
							<div (click)="selectFolder(folder)">
								<i class="fas fa-angle-right" *ngIf="expandedRootId !== folder.id" [ngClass]="{'disabled': !folder.folders || folder.folders?.length == 0}" (click)="expandFolder($event, folder)"></i>
								<i class="fas fa-angle-down" *ngIf="expandedRootId == folder.id" (click)="collapseFolder($event, folder.id)"></i>
								<i class="fas fa-folder"></i>
								<span [ngClass]="{'strong': selectedFolder?.id == folder.id}">{{ folder.name }}</span>
								<i class="fas fa-folder-plus right" *ngIf="newSubFolderParentId == -1" (click)="showCreateSubfolder($event, folder.id)" title="Create sub-folder"></i>
							</div>
							<!-- Create sub-folder -->
							<div [ngClass]="{'hidden': newSubFolderParentId !== folder.id}" class="new-sub-folder">
								<i class="fas fa-folder"></i>
								<input formControlName="newSubFolderName" placeholder="Enter folder name" />
								<i class="fas fa-times" (click)="cancelShowCreateSubfolder(folder.id)"></i>
								<i class="fas fa-check" *ngIf="form.value.newSubFolderName?.length > 0" (click)="createSubFolder(folder.id)"></i>
								<div class="icon-spacer" *ngIf="!form.value.newSubFolderName || form.value.newSubFolderName?.length == 0"></div>
							</div>
							<!-- List Sub-folders -->
							<ng-container *ngIf="expandedRootId == folder.id">
								<ng-container *ngFor="let subfolder of folder.folders">
									<div class="subfolder" (click)="selectFolder(subfolder)">
										<i class="fas fa-folder"></i>
										<span [ngClass]="{'strong': selectedFolder?.id == subfolder.id}">{{ subfolder.name }}</span>
									</div>
								</ng-container>
							</ng-container>
						</ng-container>
						<!-- Create folder -->
						<div *ngIf="newFolderParentId !== 0" (click)="newFolderParentId = 0"><i class="fas fa-folder-plus create"></i> Create folder</div>
						<div [ngClass]="{'hidden': newFolderParentId !== 0}" class="new-parent-folder">
							<i class="fas fa-folder create"></i>
							<input formControlName="newRootFolderName" placeholder="Enter folder name" />
							<i class="fas fa-times" (click)="cancelCreateRootFolder()"></i>
							<i class="fas fa-check" *ngIf="form.value.newRootFolderName?.length > 0" (click)="createRootFolder()"></i>
							<div class="icon-spacer" *ngIf="!form.value.newRootFolderName || form.value.newRootFolderName?.length == 0"></div>
						</div>
					</div>
				</div>

				<br />

				<!-- People -->
				<div class="form-group odd">
					<label for="people">Who is in the photo?</label>
					<ng-select #peopleSelect class="typeahead" [disabled]="loadingPhotos" [items]="people" [clearable]="true" [multiple]="true" bindLabel="name" bindValue="name" id="people" formControlName="people" (change)="peopleChange()" (keyup)="peopleKeyup()" (blur)="peopleBlur()" (keyup.enter)="peopleBlur()" (remove)="peopleUnselect($event)"></ng-select>
				</div>

				<!-- Date
				<div class="form-group even">
					<label for="capturedDate">When was this photo taken?</label> <i class="fas fa-question right" (click)="showHelp=!showHelp"></i>
					<div *ngIf="showHelp" class="help-text">NOTE: This date will only be used if the photo contains no EXIF data. Photos taken with digital cameras typically contain EXIF data while scanned photos do not.</div>
					<input bsDatepicker type="text" [disabled]="loadingPhotos" id="capturedDate" formControlName="capturedDate" class="form-control date" placement="top middle" [maxDate]="maxDate" autocomplete="off">
				</div>-->

				<!-- Locations -->
				<div class="form-group odd">
					<label for="locations">Where was this photo taken?</label>
					<input [ngClass]="{'hidden': selectedLocation !== '' }" type="text" id="location" formControlName="location" class="form-control" (keyup)="locationKeyup()" autocomplete="off">
					<div class="location-suggestion-position-container">
						<div class="location-suggestion-container" *ngIf="userLocationSuggestions.length > 0">
							<div *ngFor="let location of userLocationSuggestions" (click)="selectLocation(location.name)">
								{{ location.name }}
							</div>
							<div (click)="loadMoreSuggestions()">Load more results...</div>
						</div>

						<div class="location-suggestion-container" *ngIf="locationSuggestions.length > 0 || (form.value.location && form.value.location.length > 0 && locationShowSuggestions)">
							<div *ngFor="let suggestion of locationSuggestions" (click)="selectLocationSuggestion(suggestion.title)">
								{{ suggestion.title }}
							</div>
							<div (click)="addSuggestion()" *ngIf="(form.value.location && form.value.location.length > 0)">Add '{{ form.value.location }}'...</div>
						</div>
						<div *ngIf="selectedLocation !== ''" class="location-selected">{{ selectedLocation }} (<a (click)="clearLocation()" href="javascript:void(0)">clear location</a>)</div>
					</div>
				</div>

				<!-- Keywords -->
				<div class="form-group even">
					<label for="keywords">Enter photo keywords:</label>
					<ng-select #keywordsSelect class="typeahead" [disabled]="loadingPhotos" [items]="keywords" [clearable]="true" [multiple]="true" bindLabel="name" bindValue="name" id="keywords" formControlName="keywords" (change)="keywordsChange()" (keyup)="keywordsKeyup()" (blur)="keywordsBlur()" (keyup.enter)="keywordsBlur()" (remove)="keywordsUnselect($event)"></ng-select>
				</div>

				<br />
				<label for="keywords" class="keywords-label">
					Allow your connections to:
					<i class="fas fa-question-circle" [tooltip]="defaultSettingsTemplate" placement="bottom"></i>
					<ng-template #defaultSettingsTemplate>
						Defaults for these settings can be changed in User Settings
					</ng-template>
				</label>

				<div class="flex">
					<div class="form-group left">
						<div class="option">
							<div class="slider-container">
								<label class="switch">
									<input id="connectionsCanViewSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanView" (change)="connectionsCanViewSwitchChanged($event)" />
									<span class="slider round"></span>
								</label>
							</div>
							<label for="keywords">View photos</label>
						</div>
					</div>
					<div class="form-group right">
						<div class="option">
							<div class="slider-container" [ngClass]="{'hidden': form.value.connectionsCanView }">
								<label class="switch">
									<span class="slider round"></span>
								</label>
							</div>
							<div class="slider-container" [ngClass]="{'hidden': !form.value.connectionsCanView }">
								<label class="switch">
									<input id="connectionsCanReactSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanReact" />
									<span class="slider round"></span>
								</label>
							</div>
							<label for="keywords">React to photos</label>
						</div>
					</div>
				</div>
				<div class="flex">
					<div class="form-group left">
						<div class="option">
							<div class="slider-container" [ngClass]="{'hidden': form.value.connectionsCanView }">
								<label class="switch">
									<span class="slider round"></span>
								</label>
							</div>
							<div class="slider-container" [ngClass]="{'hidden': !form.value.connectionsCanView }">
								<label class="switch">
									<input id="connectionsCanDiscussSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanDiscuss" />
									<span class="slider round"></span>
								</label>
							</div>
							<label for="keywords">Discuss photos</label>
						</div>
					</div>
					<div class="form-group right">
						<div class="option">
							<div class="slider-container" [ngClass]="{'hidden': form.value.connectionsCanView }">
								<label class="switch">
									<span class="slider round"></span>
								</label>
							</div>
							<div class="slider-container" [ngClass]="{'hidden': !form.value.connectionsCanView }">
								<label class="switch">
									<input id="connectionsCanSuggestSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanSuggest" />
									<span class="slider round"></span>
								</label>
							</div>
							<label for="keywords">Suggest metadata</label>
						</div>
					</div>
				</div>
				<div class="flex">
					<div class="form-group left">
						<div class="option">
							<div class="slider-container" [ngClass]="{'hidden': form.value.connectionsCanView }">
								<label class="switch">
									<span class="slider round"></span>
								</label>
							</div>
							<div class="slider-container" [ngClass]="{'hidden': !form.value.connectionsCanView }">
								<label class="switch">
									<input id="connectionsCanSeeExifSwitch" type="checkbox" class="switch-input" formControlName="connectionsCanSeeExif" />
									<span class="slider round"></span>
								</label>
							</div>
							<label for="keywords">See EXIF data</label>
						</div>
					</div>
				</div>

				<!-- Buttons -->
				<div class="upload-button-container">
					<button type="button" class="btn btn-primary btn-md button" (click)="uploadPhotosSubmit()" [disabled]="!s.valid || files.length == 0 || loadingPhotos">Upload {{ files?.length }} Photos</button>
					<button type="button" class="btn btn-default btn-md button" (click)="clearForm()" [disabled]="loadingPhotos">Reset</button>
				</div>

			</form>
		</div>
	</div>
	<div class="col-md-8">
		<div ngx-dropzone class="custom-dropzone" (change)="onPhotoAdd($event)" [expandable]="false" accept="image/jpeg,image/jpg,image/png,image/gif" [maxFileSize]="10485760">
			<ngx-dropzone-label class="full">
				<div class="text">Drop images here or click to browse</div>
			</ngx-dropzone-label>
			<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files; let i = index" class="upload-row" [id]="i" [file]="f" [removable]="!loadingPhotos" (removed)="onPhotoRemove(f)">
				<ngx-dropzone-label class="photo-details">
					<span *ngIf="!f.error">
						{{ f.name }}
						<br />{{ f.size/1024/1024 | number:'.2' }} MB
					</span>
				</ngx-dropzone-label>
			</ngx-dropzone-image-preview>
		</div>
	</div>
</div>