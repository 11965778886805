import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Gallery } from 'src/app/models/gallery.model';
import { FacebookService } from 'src/app/services/facebook.service';
import { PhotoService } from 'src/app/services/photo.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-expanded-photo-gallery',
	templateUrl: './expanded.component.html',
	styleUrls: ['./expanded.component.css']
})
export class ExpandedPhotoGalleryComponent implements OnInit {
	@Input() gallery: Gallery;

	loading = false;

	photos: Object[] = [];

	constructor(
		private facebookService: FacebookService,
		private photoService: PhotoService,
		private domSanitizer: DomSanitizer,
		private userService: UserService
	) { }

	ngOnInit() {
		this.retrieveGalleryImages(this.gallery)
	}

	retrieveGalleryImages(gallery: Gallery) {
		this.loading = true;

		let ownerUserIdsArray = [];
		ownerUserIdsArray.push(this.userService.users[0].id);
		let ownerUserIds = JSON.stringify(ownerUserIdsArray);

		let people = "";
		if (gallery && gallery.people) {
			people = JSON.parse(gallery.people);
		}

		// TODO: Add support for captured date
		//let capturedDate = "";
		//if (gallery && gallery.capturedDate) {
		//  capturedDate = gallery.capturedDate;
		//}

		let locations = "";
		if (gallery && gallery.locations) {
			locations = JSON.parse(gallery.locations);
		}

		let keywords = "";
		if (gallery && gallery.keywords && gallery.keywords != undefined) {
			keywords = JSON.parse(gallery.keywords);
		}

		let event = "";
		if (gallery && gallery.event && gallery.event != undefined) {
			keywords = JSON.parse(gallery.event);
		}

		let folder = "";
		if (gallery && gallery.folder && gallery.folder != undefined) {
			folder = JSON.parse(gallery.folder);
		}

		// TODO: Add support for showOnlyUncategorizedPhotos
		let showOnlyUncategorizedPhotos = false;

		this.photoService.getPhotos(0, "random", ownerUserIds, people, null, locations, keywords, folder, event, showOnlyUncategorizedPhotos).subscribe(
			response => {
				if (response.status === 200) {
					let imageIndex = 0;
					for (let photo of response.body) {
						// TODO: Check if the image size from the URL is the same before loading the image from facebook or database.
						if (photo.imageUrl) {
							// Check if the imageUrl is still valid.
							let _this = this;
							this.imageExists(photo.imageUrl, function (exists) {
								if (exists) {
									let image = { image: photo.imageUrl, thumbImage: photo.imageUrl };
									_this.photos.push(image);

									// TODO: For whatever reason ng-image-slider does not display 0 index photos.
									//       Remove whenever this is fixed.
									if (imageIndex == 0) {
										_this.photos.push(image);
										imageIndex++;
									}
								} else if (photo.type == 'facebook') {
									// Photo URL is no longer valid, retrieve the new URL.
									// TODO: Move the authentication into the service?
									_this.facebookService.authenticate(function (model) {
										_this.facebookService.setToken(model.authResponse.accessToken);
										_this.facebookService.setUserId(model.authResponse.userID);

										_this.facebookService.retrievePhoto(photo.externalId, function (response2) {
											// TODO: Check to see if this photo status is pending?
											let image = { image: response2.images[0].source, thumbImage: response2.images[0].source };
											_this.photos.push(image);

											// Update the URL in the database.
											photo.imageUrl = response2.images[0].source;
											_this.photoService.updatePhoto(photo, false).subscribe(
												response3 => {
													// Do nothing.  The photo has been updated.
												});
										});
									});
								}
							});
						} else if (photo.type == 'facebook') {
							let _this = this;

							_this.facebookService.authenticate(function (model) {
								_this.facebookService.setToken(model.authResponse.accessToken);
								_this.facebookService.setUserId(model.authResponse.userID);

								_this.facebookService.retrievePhoto(photo.externalId, function (response2) {
									let image = { image: response2.images[0].source, thumbImage: response2.images[0].source };
									_this.photos.push(image);

									// TODO: For whatever reason ng-image-slider does not display 0 index photos.
									//       Remove whenever this is fixed.
									if (imageIndex == 0) {
										_this.photos.push(image);
										imageIndex++;
									}

									// Update the URL in the database.
									photo.imageUrl = response2.images[0].source;
									_this.photoService.updatePhoto(photo, false).subscribe(
										response3 => {
											// Do nothing.  The photo has been updated.
										});
								});
							});
						} else if (photo.type == 'upload') {

							// TODO: Retrieve photo
							this.photoService.getPhotoImage(photo.externalIdMed).subscribe(
								response => {
									var reader = new FileReader();
									reader.readAsDataURL(response.body);

									let _this = this;
									reader.onloadend = function () {
										var base64data = reader.result;
										let image = { image: base64data, thumbImage: base64data };
										_this.photos.push(image);

										// TODO: For whatever reason ng-image-slider does not display 0 index photos.
										//       Remove whenever this is fixed.
										if (imageIndex == 0) {
											_this.photos.push(image);
											imageIndex++;
										}
									};
								},
								error => {
									// TODO: Handle errors
									console.log('error');
									console.log(error);
								});
						}
					}

					this.loading = false;
				} else {
					this.loading = false;
				}
			},
			err => {
				this.loading = false;
			}
		);
	}

	imageExists(url, callback) {
		var img = new Image();
		img.onload = function () { callback(true); };
		img.onerror = function () { callback(false); };
		img.src = url;
	}
}
