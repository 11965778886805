import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {
    currentYear;

    constructor(
        public router: Router
    ) { }

    ngOnInit() {
        let currentDate = new Date();
        this.currentYear = currentDate.getFullYear();
    }

    ngOnDestroy() {

    }

    navigate(path,) {
        this.router.navigate([path]);
    }

}
