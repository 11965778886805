import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import imageCompression from 'browser-image-compression';
import CryptoJS from 'crypto-js';
import { Subscription } from 'rxjs';
import { PhotoUploadQueueNumber } from 'src/app/models/photo-upload-queue-number.model';
import { PhotoUpload } from 'src/app/models/photo-upload.model';
import { Photo } from 'src/app/models/photo.model';
import { LayoutService } from 'src/app/services/layout.service';
import { PhotoService } from 'src/app/services/photo.service';
import { UserService } from 'src/app/services/user.service';

const EXIF = require('exif-js');

@Component({
	selector: 'app-upload-photo-progress',
	templateUrl: './upload-photo.component.html',
	styleUrls: ['./upload-photo.component.css']
})
export class UploadPhotoProgressComponent implements OnInit {
	@Input() photoUpload: PhotoUpload;
	@Output() finished = new EventEmitter<PhotoUpload>();

	loading = true;

	uploadPhotoNextQueueSubscription: Subscription;

	photo: Photo;
	photoChecksumMed;

	uploadProgress = 1;
	uploadLastFileSizeLoaded = 0;
	signedUploadUrl;

	imgSafeUrl: SafeUrl;

	constructor(
		private domSanitizer: DomSanitizer,
		private layoutService: LayoutService,
		private photoService: PhotoService,
		private userService: UserService
	) { }

	ngOnInit() {
		// If the incoming photo has the status of inprogress, process it right away. 
		// Otherwise, wait for the queueNumber to be announced within the subscription.
		if (this.photoUpload.status == 'inprogress') {
			this.uploadPhotoStep1();
		} else if (this.photoUpload.status == 'queued') {
			this.subscribeToNextQueueNumberAnnounced();
		}
	}

	ngOnDestroy() {
		if (this.uploadPhotoNextQueueSubscription) {
			this.uploadPhotoNextQueueSubscription.unsubscribe();
		}
	}

	subscribeToNextQueueNumberAnnounced() {
		this.uploadPhotoNextQueueSubscription = this.layoutService.callNextUploadPhotoQueueNumber
			.subscribe((photoUploadQueueNumber: PhotoUploadQueueNumber) => {
				if (photoUploadQueueNumber.queueNumber == this.photoUpload.queueNumber) {
					this.photoUpload.status == 'inprogress';
					this.photoUpload.signedUploadUrlNumber = photoUploadQueueNumber.signedUploadUrlNumber;
					this.uploadPhotoStep1();
				}
			});
	}

	/**
	 * UPLOAD OVERVIEW
	 * 
	 * Uploading each file is a 5 step process:
	 * 1. Retrieve the B2 signed upload URLs, either from the cache or request a new one.
	 * 2. Add the initial photo entry to the database.
	 * 3. Upload the original image, retrying up to 2 times, then compress the photo and generate a checksum.
	 * 4. Upload the compressed image, retrying up to 2 times.
	 * 5. Gather remaining metadata and update the photo, which finishes the process.
	 * 
	 * Each loop will generate the maximum of 5 api requests:
	 * 1. Retrieve the upload URL		-> Server
	 * 2. Create the photo				-> Server
	 * 3. Upload the original image		-> B2
	 * 4. Upload the compressed image	-> B2
	 * 5. Update the photo				-> Server
	 * 
	 * If one step fails, the previous steps are backed-out as-needed.
	 * 
	 * KNOWN ISSUES:
	 * - Random CORS issues using localhost makes it so I cannot test properly
	 * - Finished uploading 22 photos message shows while the last photo is still processing
	 * - Handle expired upload urls
	 * - As of now only calls to B2 retry. Should retry calls to the Photonomy API as well
	 * 
	 * FIXED ISSUES NEED TESTING:
	 * - Still getting auth token limit errors occassionally due to timing
	 * - If the original file upload retries, it doesn't reset the loading indicator progress
	 * 
	 * TESTING:
	 * 
	 * Z Wedding Photos - 57 photos
	 * 
	 * Upload x2 = 2:51
	 * Upload x4 = 1:23
	 * Upload x5 = 
	 * 
	 */


	/**
	 * UPLOAD PHOTO STEP 1 - Retrieve a B2 signed upload URL, either from the cache or request a new one.
	 * 
	 * 1. Determine if the photo will use signed upload URL 1,2,3,4,5.
	 * 2. Retrieve the url from the cache and continue to step 2.
	 * 3. Retrieve the url from the server, assign it to the cache, and continue to step 2.
	 * 
	 */
	uploadPhotoStep1() {
		// 1
		if (this.photoUpload.signedUploadUrlNumber == 1) {
			if (this.photoService.signedUploadUrl1) {
				// 2
				this.signedUploadUrl = this.photoService.signedUploadUrl1;
				this.uploadPhotoStep2();
			} else {
				// 3
				this.photoService.getSignedUploadUrl().subscribe(
					response => {
						this.photoService.signedUploadUrl1 = response.body;
						this.signedUploadUrl = this.photoService.signedUploadUrl1;
						this.uploadPhotoStep2();
					}
				);
			}
		} else if (this.photoUpload.signedUploadUrlNumber == 2) {
			if (this.photoService.signedUploadUrl2) {
				// 2
				this.signedUploadUrl = this.photoService.signedUploadUrl2;
				this.uploadPhotoStep2();
			} else {
				// 3
				this.photoService.getSignedUploadUrl().subscribe(
					response => {
						this.photoService.signedUploadUrl2 = response.body;
						this.signedUploadUrl = this.photoService.signedUploadUrl2;
						this.uploadPhotoStep2();
					}
				);
			}
		} else if (this.photoUpload.signedUploadUrlNumber == 3) {
			if (this.photoService.signedUploadUrl3) {
				// 2
				this.signedUploadUrl = this.photoService.signedUploadUrl3;
				this.uploadPhotoStep2();
			} else {
				// 3
				this.photoService.getSignedUploadUrl().subscribe(
					response => {
						this.photoService.signedUploadUrl3 = response.body;
						this.signedUploadUrl = this.photoService.signedUploadUrl3;
						this.uploadPhotoStep2();
					}
				);
			}
		} else if (this.photoUpload.signedUploadUrlNumber == 4) {
			if (this.photoService.signedUploadUrl4) {
				// 2
				this.signedUploadUrl = this.photoService.signedUploadUrl4;
				this.uploadPhotoStep2();
			} else {
				// 3
				this.photoService.getSignedUploadUrl().subscribe(
					response => {
						this.photoService.signedUploadUrl4 = response.body;
						this.signedUploadUrl = this.photoService.signedUploadUrl4;
						this.uploadPhotoStep2();
					}
				);
			}
		} else if (this.photoUpload.signedUploadUrlNumber == 5) {
			if (this.photoService.signedUploadUrl5) {
				// 2
				this.signedUploadUrl = this.photoService.signedUploadUrl5;
				this.uploadPhotoStep2();
			} else {
				// 3
				this.photoService.getSignedUploadUrl().subscribe(
					response => {
						this.photoService.signedUploadUrl5 = response.body;
						this.signedUploadUrl = this.photoService.signedUploadUrl5;
						this.uploadPhotoStep2();
					}
				);
			}
		}
	}

	/**
	 * UPLOAD PHOTO STEP 2 - Add the initial photo entry to the database.
	 * 
	 * 1. Set vars.
	 * 2. Run the original file through a file reader to generate image and sha1 checksum.
	 * 3. TODO: Set the image safe url to display the image.
	 * 4. Add a initial photo upload entry.
	 * 5. Add the initial photo entry to the database.
	 * 6. Calculate fileName of the original and compressed files.
	 * 7. Set the proper status if this is part of an event.
	 * 8. Go to next step.
	 * 
	 */
	uploadPhotoStep2() {
		// 1
		this.uploadProgress = 1;
		let _this = this;
		const file = this.photoUpload.file;

		// 2
		const reader = new FileReader();
		reader.onload = function (e) {
			// 3. TODO: Set the image safe url to display the image.
			//_this.imgSafeUrl = _this.domSanitizer.bypassSecurityTrustUrl(reader.result.toString());

			// 4 
			const checksum = CryptoJS.SHA1(CryptoJS.enc.Latin1.parse(reader.result)) + '';

			// 5
			_this.photoService.addPhoto('upload', checksum).subscribe(
				response => {
					_this.photo = response.body;

					// 6
					// POSSIBLE FILENAMES:
					// 		Shared filename - Folder / No Folder
					// 			user_uuid/file-sha1-checksum.file-extension.toUpperCase()
					// 		Private filename - Folder / No folder
					//			user_private_uuid/file-sha1-checksum.file-extension.toUpperCase()
					// 		Event filename - My upload
					// 			user_uuid/folder_uuid/file-sha1-checksum.file-extension.toUpperCase()
					// 		Event filename - Owner upload
					// 			event_owner_user_uuid/folder_uuid/file-sha1-checksum.file-extension.toUpperCase()

					let fileName = _this.photoUpload.file.name; // IMG_2146.JPG
					let regexPattern = /(?:\.([^.]+))?$/;
					let fileExtension = regexPattern.exec(fileName)[1]; // "JPEG"
					if (fileExtension.toUpperCase() == 'JPEG') {
						fileExtension = 'JPG';
					}

					let filePath = '';
					let filePathMed = '';

					// filePath: Part 1
					console.log(_this.photoUpload);
					// !!!! ERROR !!!! - _this.photoUpload.form is null when uploading to an event
					if (_this.photoUpload.form?.value.connectionsCanView || _this.photoUpload.event) {
						// Uploading to the event owners account.
						if (_this.photo.userId !== _this.photoUpload.event?.owner && _this.photoUpload.uploadToAccount == 'owner') {
							let eventOwner = _this.userService.users.find(d => d.id === _this.photoUpload.event.owner);
							console.log(eventOwner);
							if (!eventOwner) {
								// TODO: Need to look it up
							}
							filePath += eventOwner.uuid + '/';
							filePathMed += eventOwner.uuid + '/';
						} else {
							filePath += _this.userService.getLocalUserUuid(0) + '/';
							filePathMed += _this.userService.getLocalUserUuid(0) + '/';
						}
					} else {
						filePath += _this.userService.users[0].uuidPrivate + '/';
						filePathMed += _this.userService.users[0].uuidPrivate + '/';
					}

					// filePath: Part 2
					// TODO: THIS DOES NOT WORK.  IT SHOULD BE GETTING THE UUID FROM THE EVENT'S CORRESPONDING FOLDER
					if (_this.photoUpload.event) {
						console.log('event1');
						console.log(_this.photoUpload.event);
						// TODO: LOOKUP THE FOLDER UUID

						filePath += _this.photoUpload.event.shareCode1 + '/';
						filePathMed += _this.photoUpload.event.shareCode1 + '/';
					}

					// filePath: Part 3
					filePath += checksum + '.' + fileExtension;
					filePathMed += checksum + '-MED.' + fileExtension;
					console.log(filePathMed);
					// 7
					if (_this.photoUpload.event) {
						_this.photo.eventId = _this.photoUpload.event.id;

						console.log(_this.photo.userId);
						console.log(_this.photoUpload.event.owner);
						console.log(_this.photoUpload.uploadToAccount);

						if (_this.photo.userId == _this.photoUpload.event.owner) {
							// Current user is the owner
							console.log('Current user is the owner');
						} else if (_this.photoUpload.uploadToAccount == 'owner') {
							// Current user is not the owner, and upload to owners account
							console.log('Current user is not the owner, but upload to owner');
							_this.photo.userId = _this.photoUpload.event.owner;
							_this.photo.status = 'pending';
							_this.photo.addedByUserId = _this.userService.getLocalUserId(0);
						} else {
							// Current user is not the owner, and upload to current users account
							console.log('Current user is not the owner, and upload to existing account');
							_this.photo.status = 'pending';
						}
					}

					_this.photo.imageUrl = filePath.toUpperCase();
					_this.photo.imageUrlMed = filePathMed.toUpperCase();
					_this.photo.imageOriginalFilename = fileName;
					_this.photo.imageSize = file.size;
					_this.photo.imageMimeType = file.type;
					_this.photo.checksum = checksum;
					if (_this.photoUpload.folder?.id) {
						_this.photo.folderId = _this.photoUpload.folder.id;
					} else {
						_this.photo.folderId = 0;
					}

					// 8
					_this.uploadPhotoStep3(1);
				},
				error => {
					// ERROR HANDLING THOUGHTS: 
					//    ERROR DURING ACTION: 
					//       Creating the initial photo entry in the database.
					//    THOUGHTS: 
					//       This is the first place an error could occur during the upload process while
					//       communicating with the api. This would most likely happen because we detected
					//       a file that already matches this checksum within the database.
					//    PLAN: 
					//       Since no data has been modified at this point, only need to update the
					//       progress indicators.
					//    TO TEST:
					//       Update the service layer to temporarily return an exception on the following call
					//          POST /api/photo

					_this.photoUpload.status = 'skipped';
					_this.uploadProgress = 100;

					_this.finish();
				}
			);
		};
		reader.readAsBinaryString(file);
	}

	/**
	 * UPLOAD PHOTOS STEP 4 - Upload the original image, retrying up to 2 times, then compress the photo and generate a checksum.
	 * 
	 * 1. Upload the original file to B2 using the signed URL.
	 * 2. Update progress indicators.
	 * 3. Image is uploaded, set global vars.
	 * 4. Set the status to finishing so the color changes orange.
	 * 5. Compress the file.
	 * 6. Run the compressed file through a file reader to generate image and sha1 checksum.
	 * 7. Go to next step.
	 * 
	 * @param retryNumber 
	 */
	uploadPhotoStep3(retryNumber: number) {
		this.uploadProgress = 1;

		const file = this.photoUpload.file;
		const uploadUrl = this.signedUploadUrl.uploadUrl;
		const authToken = this.signedUploadUrl.authToken;
		const contentLength = this.photoUpload.file.size.toString();
		const filePath = this.photo.imageUrl;
		const checksum = this.photo.checksum.toString();

		// 1
		this.photoService.addImageBySignedUploadUrl(file, uploadUrl, authToken, contentLength, filePath, checksum).subscribe(
			async response => {
				if (response.type === HttpEventType.UploadProgress) {
					// 2
					// If the loaded response is less than the last size, it means B2 is retrying the file
					// because of an error, typically a checksum problem. In this case we want to stop
					// updating the progress indicators until the second request is complete.
					if (response.loaded < this.uploadLastFileSizeLoaded) {
						// An error has occurred, make progress indicator orange.
						this.photoUpload.file.finishing = true;
					} else {
						// No errors, update the progress indicators.
						this.uploadLastFileSizeLoaded = response.loaded;

						// Update progress bar
						if (this.uploadProgress != 100) {
							this.uploadProgress = Math.round(100 * (response.loaded / response.total));
						}
					}
				}
				if (response.type === HttpEventType.Response) {
					this.photoUpload.errorMessage = null;

					// 3
					this.photo.externalId = response.body.fileId;

					// 4
					this.photoUpload.status = 'finishing';

					// 5
					const options = {
						maxSizeMB: .2,
						maxWidthOrHeight: 800,
						useWebWorker: true,
					};
					const medFile = await imageCompression(this.photoUpload.file, options);

					// 6
					let _this = this;
					const medReader = new FileReader();
					medReader.onload = function (e) {
						_this.photoChecksumMed = CryptoJS.SHA1(CryptoJS.enc.Latin1.parse(medReader.result)) + '';
						_this.photo.imageSizeOnDisk = (_this.photo.imageSize + medFile.size);

						// 7
						_this.uploadPhotoStep4(medFile, 1);
					};
					medReader.readAsBinaryString(medFile);
				}
			},
			error => {
				// ERROR HANDLING THOUGHTS: 
				//    ERROR DURING ACTION: 
				//       Uploading the original file to the B2 bucket using a signed upload URL.
				//    THOUGHTS: 
				//       This is the second place an error would typically occur during the upload process. 
				//		 This happens when B2 rejects the file, typically due to a checksum issue.
				//    PLAN: 
				//       Since the "preupload" photo entry has been created, that entry must be deleted.
				//    TO TEST:
				//       Modify the original file checksum above to be an incorrect value before sending to
				//       the server.

				// Try again.
				if (retryNumber < 10) {
					retryNumber++;
					this.photoUpload.errorMessage = 'Retrying original ' + retryNumber;
					this.uploadPhotoStep3(retryNumber);
				} else {
					this.photoUpload.status = 'error';
					this.photoUpload.errorMessage = 'Error uploading photo';
					this.uploadProgress = 100;

					// Delete the "preupload" photo entry
					this.photoService.addPhotoAbort(this.photo.id, null, null).subscribe(
						response => {
							this.finish();
						},
						err => {
							// TODO: Need to retry this if it fails.
							this.finish();
						}
					);
				}
			}
		);
	}

	/**
	 * UPLOAD PHOTO STEP 5 - Upload the compressed image, retrying up to 2 times.
	 * 
	 * 1. Upload the compressed file.
	 * 2. Compressed image is uploaded, set global vars.
	 * 3. Go to next step.
	 * 
	 */
	uploadPhotoStep4(medFile, retryNumber: number) {
		const uploadUrl = this.signedUploadUrl.uploadUrl;
		const authToken = this.signedUploadUrl.authToken;
		const contentLength = medFile.size.toString();
		const filePath = this.photo.imageUrlMed;
		const checksum = this.photoChecksumMed.toString();

		// 1. Upload the compressed file.
		this.photoService.addImageBySignedUploadUrl(medFile, uploadUrl, authToken, contentLength, filePath, checksum).subscribe(
			response => {
				if (response.type === HttpEventType.UploadProgress) {
					// Compressed file is uploading, do nothing.
				}
				if (response.type === HttpEventType.Response) {
					// Eventually uncomment this
					//this.photoUpload.errorMessage = null;

					// 2
					this.photo.externalIdMed = response.body.fileId;

					// 3
					this.uploadPhotoStep5();
				}
			},
			error => {
				// ERROR HANDLING THOUGHTS: 
				//    ERROR DURING ACTION: 
				//       Uploading the compressed file to the B2 bucket using a signed 
				//       upload URL.
				//    THOUGHTS: 
				//       This is the third place an error would typically occur during 
				//       the upload process. This happens when B2 rejects the file, typically
				//		 due to a checksum issue.
				//    PLAN: 
				//       At this point the "preupload" photo entry has been created and
				//       the original file has been uploaded. Both of those must be deleted.
				//    TO TEST:
				//       Modify the compressed file checksum above to be an incorrect value 
				//       before sending to the server.

				// TODO: Re-compress the file to a slightly different size and retry the upload.

				// Try again.
				if (retryNumber < 10) {
					retryNumber++;
					this.photoUpload.errorMessage = 'Retrying compressed ' + retryNumber;
					this.uploadPhotoStep4(medFile, retryNumber);
				} else {
					this.photoUpload.status = 'error';
					this.photoUpload.errorMessage = 'Error compressing photo';
					this.uploadProgress = 100;

					// Delete the "preupload" photo entry
					this.photoService.addPhotoAbort(this.photo.id, filePath, null).subscribe(
						response => {
							this.finish();
						},
						err => {
							// TODO: Need to retry this if it fails.
							this.finish();
						}
					);
				}
			}
		);
	}

	/**
	 * UPLOAD PHOTO STEP 5 - Gather remaining metadata and update the photo, which finishes the process.
	 * 
	 * 1. Update photo with any user-selected metadata values.
	 * 2. Pull EXIF data from image.
	 * 3. Update the photo in the database with all the remaining metadata, which finishes the process.
	 * 
	 */
	uploadPhotoStep5() {
		// 1
		if (this.photoUpload.form?.value.capturedDate && this.photoUpload.form?.value.capturedDate.length > 0) {
			this.photo.capturedType = "date";
			this.photo.capturedDate = this.photoUpload.form.value.capturedDate;
		}
		if (this.photoUpload.form?.value.keywords && this.photoUpload.form?.value.keywords.length > 0) {
			this.photo.keywords = JSON.stringify(this.photoUpload.form.value.keywords);
		}
		if (this.photoUpload.formLocation && this.photoUpload.formLocation !== undefined && this.photoUpload.formLocation.length > 0) {
			this.photo.location = this.photoUpload.formLocation;
		}
		if (this.photoUpload.form?.value.people && this.photoUpload.form?.value.people.length > 0) {
			this.photo.people = JSON.stringify(this.photoUpload.form.value.people);
		}
		if (this.photoUpload.formPublicTags && this.photoUpload.formPublicTags.length > 0) {
			this.photo.publicTags = JSON.stringify(this.photoUpload.formPublicTags);
		}
		if (this.photoUpload.form?.value.connectionsCanView) {
			this.photo.connectionsCanView = this.photoUpload.form.value.connectionsCanView;
		} else {
			this.photo.connectionsCanView = false;
		}

		// TODO: Instead of setting these to true, set it based on the users settings.
		if (this.photo.connectionsCanView) {
			if (this.photoUpload.form?.value.connectionsCanReact) {
				this.photo.connectionsCanReact = this.photoUpload.form.value.connectionsCanReact;
			} else {
				// HERE
				this.photo.connectionsCanReact = false;
			}

			if (this.photoUpload.form?.value.connectionsCanSuggest) {
				this.photo.connectionsCanSuggest = this.photoUpload.form.value.connectionsCanSuggest;
			} else {
				// HERE
				this.photo.connectionsCanSuggest = false;
			}

			if (this.photoUpload.form?.value.connectionsCanDiscuss) {
				this.photo.connectionsCanDiscuss = this.photoUpload.form.value.connectionsCanDiscuss;
			} else {
				// HERE
				this.photo.connectionsCanDiscuss = false;
			}

			if (this.photoUpload.form?.value.connectionsCanSeeExif) {
				this.photo.connectionsCanSeeExif = this.photoUpload.form.value.connectionsCanSeeExif;
			} else {
				// AND HERE
				this.photo.connectionsCanSeeExif = false;
			}
		} else {
			this.photo.connectionsCanReact = false;
			this.photo.connectionsCanSuggest = false;
			this.photo.connectionsCanDiscuss = false;
			this.photo.connectionsCanSeeExif = false;
		}

		// 2
		let _this = this;
		let _myCallback;
		EXIF.getData(this.photoUpload.file, _myCallback = function () {
			// console.log('GET EXIF DATA')

			// setCapturedDate
			let dateTimeOriginal = EXIF.getTag(this, 'DateTimeOriginal');

			if (dateTimeOriginal) {
				dateTimeOriginal = _this.parseExifDate(dateTimeOriginal);

				_this.photo.capturedDate = new Date(dateTimeOriginal);
				_this.photo.capturedDateAuto = true;
			} else {
				_this.photo.capturedDateAuto = false;
			}
			// console.log('  dateTimeOriginal: ' + dateTimeOriginal);
			// console.log('  photo.capturedDate: ' + _this.photo.capturedDate);
			// console.log('  capturedDateAuto: ' + _this.photo.capturedDateAuto);

			// TODO: setCapturedDateAuto = true/false

			// setXResolution
			const xresolution = EXIF.getTag(this, 'XResolution');
			_this.photo.xresolution = xresolution;
			// console.log('  xresolution: ' + xresolution);

			// setYResolution
			const yresolution = EXIF.getTag(this, 'YResolution');
			_this.photo.yresolution = yresolution;
			// console.log('   yresolution: ' + yresolution);

			// setOrientation
			const orientation = EXIF.getTag(this, 'Orientation');
			// console.log('  orientation: ' + orientation);
			if (orientation > 0 && orientation < 5) {
				// height
				const imageHeight = EXIF.getTag(this, 'PixelYDimension');
				_this.photo.height = imageHeight;
				// console.log('  imageHeight: ' + imageHeight);

				// width
				const imageWidth = EXIF.getTag(this, 'PixelXDimension');
				_this.photo.width = imageWidth;
				// console.log('  imageWidth: ' + imageWidth);
			} else if (orientation > 4 && orientation < 9) {
				// height
				const imageHeight = EXIF.getTag(this, 'PixelXDimension');
				_this.photo.height = imageHeight;
				// console.log('  imageHeight: ' + imageHeight);

				// width
				const imageWidth = EXIF.getTag(this, 'PixelYDimension');
				_this.photo.width = imageWidth;
				// console.log('imageWidth: ' + imageWidth);
			}

			// setCameraMake
			const cameraMake = EXIF.getTag(this, 'Make');
			_this.photo.cameraMake = cameraMake;
			// console.log('  cameraMake: ' + cameraMake);

			// setCameraModel
			const cameraModel = EXIF.getTag(this, 'Model');
			_this.photo.cameraModel = cameraModel;
			// console.log('  cameraModel: ' + cameraModel);

			//setCameraLensMake


			//setCameraLensModel


			// setCameraIso
			const isoSpeedRatings = EXIF.getTag(this, 'ISOSpeedRatings');
			_this.photo.cameraIso = isoSpeedRatings;
			// console.log('isoSpeedRatings: ' + isoSpeedRatings);

			// setCameraShutterSpeed
			const shutterSpeedValue = EXIF.getTag(this, 'ShutterSpeedValue');
			_this.photo.cameraShutterSpeed = shutterSpeedValue;
			// console.log('shutterSpeedValue: ' + shutterSpeedValue);

			// setCameraAperature
			const apertureValue = EXIF.getTag(this, 'ApertureValue');
			_this.photo.cameraAperature = apertureValue;
			// console.log('apertureValue: ' + apertureValue);

			// setCameraBrightness (DID NOT WORK)
			const brightnessValue = EXIF.getTag(this, 'BrightnessValue');
			_this.photo.cameraBrightness = brightnessValue;
			// console.log('brightnessValue: ' + brightnessValue);

			// setCameraZoomRatio (DID NOT WORK)
			const digitalZoomRatio = EXIF.getTag(this, 'DigitalZoomRatio');
			_this.photo.cameraZoomRatio = digitalZoomRatio;
			// console.log('digitalZoomRatio: ' + digitalZoomRatio);

			// setCameraFlash (This is an issue because i need to store Int and not String)
			const cameraFlash = EXIF.getTag(this, 'Flash');
			//photo.cameraFlash = cameraFlash;
			// console.log('cameraFlash: ' + cameraFlash);

			const gpsLatitude = EXIF.getTag(this, 'GPSLatitude');
			//photo.latitude = gpsLatitude;
			// console.log('gpsLatitude: ' + gpsLatitude);

			const gpsLongitude = EXIF.getTag(this, 'GPSLongitude');
			//photo.longitude = gpsLongitude;
			// console.log('gpsLongitude: ' + gpsLongitude);

			// 3. Update the photo in the database with all the remaining metadata, which finishes the process.
			console.log(_this.photo);
			_this.photoService.addPhotoFinish(_this.photo).subscribe(
				response => {
					_this.photoUpload.status = 'success';

					_this.finish();
				},
				error => {
					// ERROR HANDLING THOUGHTS: 
					//    ERROR DURING ACTION: 
					//       Updating the photo with EXIF and image url data.
					//    THOUGHTS: 
					//       This is the last place an error would typically occur during the upload process. 
					//		 This would most likely be a server timeout or some malformed data.
					//    PLAN: 
					//       The "prephoto" upload entry as well as both photos have been uploaded.  
					//       All of that must be backed out.
					//    TO TEST:
					//       Modify the original file checksum above to be an incorrect value before sending to
					//       the server.
					_this.photoUpload.status = 'error';
					_this.photoUpload.errorMessage = 'Error finalizing photo';
					_this.uploadProgress = 100;

					_this.photoService.addPhotoAbort(_this.photo.id, _this.photo.imageUrl, _this.photo.imageUrlMed).subscribe(
						response => {
							_this.finish();
						},
						err => {
							// TODO: Need to retry this if it fails.
							_this.finish();
						}
					);
				}
			);
		});
	}

	finish() {
		this.photoUpload.photo = this.photo;

		this.finished.emit(this.photoUpload);

		this.loading = false;
	}

	/** 
	 * HELPER FUNCTIONS 
	 **/

	parseExifDate(s) {
		var b = s.split(/\D/);
		return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
	}

	numDaysBetween(d1, d2) {
		var diff = Math.abs(d1.getTime() - d2.getTime());
		return diff / (1000 * 60 * 60 * 24);
	}
}
