<!--<div class="card" [ngClass]="event?.status ? event?.status : 'created'" #card>-->
<div class="card" #card>

	<div class="face front" [ngClass]="{'loading': loadingEvent}">
		<div class="event-banner" [ngStyle]="{'background-image': event?.bannerImageSafeUrl ? 'url(' + event.bannerImageSafeUrl + ')' : 'none'}">
			<div class="middle">
				<div class="name">{{ event?.name }}</div>
				<div class="sub">{{ event?.startDateTime | date:'EEEE, MMMM d, y' }}</div>
			</div>
			<!-- Pending 
			<div class="sub" *ngIf="vault.status == 'pending'">Pending since {{ vault?.date | date:'short' }}</div>-->
			<!-- Requested 
			<div class="sub" *ngIf="vault.status == 'requested'">Requested on {{ vault?.date | date:'short' }}</div>-->
			<!-- Created 
			<div class="sub" *ngIf="vault.status !== 'pending' && vault.status !== 'requested' && vault.owner == userService.getLocalUserId(0)">Created on {{ vault.date | date:'short' }}</div>
			-->
			<!-- Joined 
			<div class="sub" *ngIf="vault.status !== 'pending' && vault.status !== 'requested' && vault.owner != userService.getLocalUserId(0)">Joined on {{ vault.date | date:'short' }}</div>
			-->
		</div>

		<div class="details-container flex">
			<!-- <div class="row">
				<div class="col-md-6"><label>Type</label></div>
				<div class="col-md-6 align-right">Private / Shared</div>
			</div> -->
			<!-- <div class="row">
				<div class="col-md-6"><label>Members</label></div>
				<div class="col-md-6 align-right">{{ event?.activeUsersCount || 0 }}</div>-->
			<!-- </div> -->
			<div class="row">
				<div class="col-md-6">Total Photos</div>
				<div class="col-md-6 align-right">
					<div class="count">{{ event?.photosCount || 0 }}</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6"><label>Timeline</label></div>
				<div class="col-md-6 align-right">
					<i *ngIf="event?.addToOwnerTimeline" class="fas fa-check status active" title="Active"></i>
					<i *ngIf="!event?.addToOwnerTimeline" class="fas fa-times status disabled" title="Disabled"></i>
				</div>
			</div>
		</div>

		<div *ngIf="!loadingInvite && (!event.status || event.status == 'active')" class="button-container">
			<div class="button share first" (click)="viewEvent()" title="View Event"><i class="fas fa-calendar-alt"></i></div>
			<div class="button share middle" (click)="explorePhotos()" title="Explore Event Photos"><i class="fas fa-search"></i></div>
			<div class="button share last" (click)="copyShareLinkToClipboard()" title="Copy Event Link to Clipboard"><i class="fas fa-share-alt"></i></div>
		</div>
		<div *ngIf="!loadingInvite && !event.status" class="button-container">
			<div class="small">Owner</div>
		</div>

		<div *ngIf="!loadingInvite && event.status == 'pending'" class="button-container">
			<div class="button share first-md" (click)="viewEvent()" title="View Event"><i class="fas fa-calendar-alt"></i></div>
			<div class="button share middle confirm" (click)="acceptEventInvite()" title="Accept Invite"><i class="fas fa-check"></i></div>
			<div class="button share last delete" (click)="declineEventInvite()" title="Decline Invite"><i class="fas fa-times"></i></div>
		</div>
		<div *ngIf="!loadingInvite && event.status == 'pending'" class="button-container">
			<div class="small">Pending invitation</div>
		</div>
		<div *ngIf="!loadingInvite && event.status == 'active'" class="button-container">
			<div class="small">Member</div>
		</div>

		<div *ngIf="loadingInvite" class="button-container">
			<i class="fas fa-circle-notch fa-spin loading"></i>
		</div>


		<!--
		<div class="button-container">
			
			 Pending
			<div *ngIf="vault.status == 'pending'" class="button" (click)="acceptVaultInvite()">Accept</div>
			<div *ngIf="vault.status == 'pending'" class="button" (click)="declineVaultInvite()">Decline</div>-->

		<!-- Requested 
			<div *ngIf="vault.status == 'requested'" class="button" (click)="cancelJoinRequest()">Cancel</div>-->

		<!-- Created
			<div *ngIf="vault.status !== 'pending' && vault.status !== 'requested' && vault.owner == userService.getLocalUserId(0)" class="button" (click)="viewVault()" title="View Vault">View</div>
			<div *ngIf="vault.status !== 'pending' && vault.status !== 'requested' && vault.owner == userService.getLocalUserId(0)" class="button" (click)="uploadPhotos()" title="Upload Photos">Upload</div>-->
		<!--
			<div *ngIf="vault.status !== 'pending' && vault.status !== 'requested' && vault.owner == userService.getLocalUserId(0)" class="button" (click)="deleteVault()">Delete</div>
			-->

		<!-- Joined
			<div *ngIf="vault.status !== 'pending' && vault.status !== 'requested' && vault.owner != userService.getLocalUserId(0)" class="button" (click)="viewVault()">View</div>-->
		<!--
			<div *ngIf="vault.status !== 'pending' && vault.status !== 'requested' && vault.owner != userService.getLocalUserId(0)" class="button" (click)="leaveVault()">Leave</div>
			-->
		<!--
			<div *ngIf="vault.status !== 'pending' && vault.status !== 'requested' && vault.owner != userService.getLocalUserId(0)" class="button" (click)="uploadPhotos()" title="Upload Photos">Upload</div>
				
		</div>-->
	</div>
</div>